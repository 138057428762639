import * as React from 'react';

const Svg = props => (
  <svg width={47} height={38} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.5 47.42">
    <path fill="none" stroke="#9b9fa7" strokeMiterlimit="10" strokeWidth="2.5px" d="M36,17.828C36,8.518,28.183,1,18.525,1S1,8.518,1,17.828a16.507,16.507,0,0,0,2.24,8.215l.1.149,1.145,1.643L15.04,44.962c.846,1.245,5.178,1.294,6.074.05l11.9-17.824h0A16.234,16.234,0,0,0,36,17.828ZM18.525,26.192a7.855,7.855,0,0,1-8.016-7.717,7.855,7.855,0,0,1,8.016-7.717,7.855,7.855,0,0,1,8.016,7.717A7.855,7.855,0,0,1,18.525,26.192Z" transform="translate(0.25 0.25)" />
  </svg>
);

export default Svg;

