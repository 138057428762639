import * as React from 'react';

const Svg = props => (
  <svg width={90} height={90} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.677 89.677">
    <ellipse fill="#e3f0fc" cx="44.838" cy="44.838" rx="44.838" ry="44.838" />
    <g transform="translate(5.006 19.961)">
      <path fill="#929292" d="M35.43,2.058,5.672,12.394A9.288,9.288,0,0,0,2.183,25.034L12.849,44.785a9.271,9.271,0,0,0,12.509,3.95l36.934-16.13c2.831-2.436,5.6-8.493,5.069-10.6L49.585,5.744C43.989,1.531,39.841-.312,35.43,2.058Z" transform="translate(0 0)" />
      <path fill="#9fa4aa" d="M72.984,30.555a3.344,3.344,0,0,0-2.37-.4c-2.37.4-6.452,3.292-11.653,5.333-2.765,1.053-3.028,2.041-4.872,2.963-1.053.527-6.715,3.226-6.715,3.226l-4.082,1.778-7.242,2.7a17.923,17.923,0,0,0-8.427,6.518l-.066.066c-1.119,1.58-2.107,6.123,1.317,6.386,3.094.263,6.452-1.185,9.02-2.37,3.094-1.448,5.859-2.7,7.374-3.358,2.765-1.185,12.9-5.728,12.9-5.728.132-.066,11.521-4.74,12.97-5.728,1.514-.988,5.2-8.625,4.477-9.81C75.09,31.279,74.037,31.147,72.984,30.555Z" transform="translate(-8.717 -9.931)" />
      <path fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="2px" strokeLinecap="round" d="M33.6,9.5S53.483,25.367,55.919,37.612" transform="translate(-11.139 -2.9)" />
      <path fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="2px" strokeLinecap="round" d="M8.9,39.691s1.975-7.374,5.4-9.349c7.176-4.148,26.335-11.061,37.856-13.167,5.728-1.053,6.979-1.909,9.744.79" transform="translate(-2.701 -5.176)" />
      <path fill="#4eb588" d="M35.793,15.686a3.671,3.671,0,0,0-1.843-.658l-7.11-.329A1.732,1.732,0,0,0,25,16.41l-.2,4.214a1.561,1.561,0,0,0,1.514,1.646l7.505.329a3.308,3.308,0,0,0,1.58-.4l2.568-1.119.2-4.016Zm0,4.345a1.055,1.055,0,1,1,1.119-.988A1.138,1.138,0,0,1,35.793,20.031Z" transform="translate(-8.131 -4.674)" />
      <path fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="2px" strokeLinecap="round" d="M41.8,21.5s2.568,3.489,5.135,1.843" transform="translate(-13.941 -6.999)" />
      <ellipse fill="none" strokeMiterlimit="10" stroke="#4b5467" strokeWidth="1.5px" cx="2.831" cy="3.028" rx="2.831" ry="3.028" transform="matrix(0.548, -0.836, 0.836, 0.548, 19.135, 32.057)" />
      <path fill="none" strokeMiterlimit="10" strokeLinecap="round" stroke="#4b5467" strokeWidth="1.5px" strokeLinejoin="round" d="M38.7,41.273a2.845,2.845,0,0,1,2.436-1.251,4.612,4.612,0,0,0,2.633-.922" transform="translate(-12.882 -13.012)" />
      <path fill="none" strokeMiterlimit="10" strokeLinecap="round" stroke="#4b5467" strokeWidth="1.5px" strokeLinejoin="round" d="M41.2,44.373a2.845,2.845,0,0,1,2.436-1.251,4.613,4.613,0,0,0,2.633-.922" transform="translate(-13.736 -14.071)" />
      <path fill="none" strokeMiterlimit="10" strokeLinecap="round" stroke="#4b5467" strokeWidth="1.5px" strokeLinejoin="round" d="M34.6,39.673a2.845,2.845,0,0,1,2.436-1.251,4.613,4.613,0,0,0,2.633-.922" transform="translate(-11.481 -12.466)" />
      <path fill="#dce2ea" d="M93.718,56.357,58.43,49.312a1.863,1.863,0,0,1-1.448-2.173L61.064,26.73a1.863,1.863,0,0,1,2.173-1.448l35.288,7.044A1.863,1.863,0,0,1,99.973,34.5L95.891,54.908A1.863,1.863,0,0,1,93.718,56.357Z" transform="translate(-19.115 -8.279)" />
      <path fill="#dce2ea" d="M65.144,25.755,79.957,46.164a.435.435,0,0,0,.4.066l22.121-12.575a.379.379,0,0,0-.066-.724L65.473,25.1A.394.394,0,0,0,65.144,25.755Z" transform="translate(-21.879 -8.226)" />
      <path fill="#7791c1" d="M67.5,27.8l.79,1.053,2.041.461L69.607,28.2Z" transform="translate(-22.721 -9.152)" />
      <path fill="#7791c1" d="M69.9,31.1l.79,1.119,2.041.461-.724-1.119Z" transform="translate(-23.541 -10.279)" />
      <path fill="#7791c1" d="M72.6,34.7l.79,1.053,2.041.527-.79-1.185Z" transform="translate(-24.463 -11.509)" />
      <path fill="#7791c1" d="M74.9,37.9l.79,1.119,2.041.461-.724-1.119Z" transform="translate(-25.249 -12.602)" />
      <path fill="#7791c1" d="M77.4,41.5l.856,1.119,2.041.461-.79-1.185Z" transform="translate(-26.103 -13.832)" />
      <path fill="#7791c1" d="M80,45l.79,1.053,2.041.461L82.107,45.4Z" transform="translate(-26.991 -15.028)" />
      <path fill="#7791c1" d="M82.4,48.3l.79,1.119,2.041.461-.724-1.119Z" transform="translate(-27.811 -16.155)" />
      <path fill="#7791c1" d="M85.1,51.9l.79,1.053,2.041.527-.79-1.185Z" transform="translate(-28.734 -17.385)" />
      <path fill="#7791c1" d="M87.3,55.1l.4.593.2.2.856-.527Z" transform="translate(-29.485 -18.478)" />
      <path fill="#7791c1" d="M117.526,38.427l-1.251.658-1.975-.527,1.119-.658Z" transform="translate(-38.709 -12.602)" />
      <path fill="#7791c1" d="M113.826,40.527l-1.185.658-2.041-.461L111.785,40Z" transform="translate(-37.445 -13.32)" />
      <path fill="#7791c1" d="M110.026,42.627l-1.251.658-1.975-.461,1.185-.724Z" transform="translate(-36.147 -14.037)" />
      <path fill="#7791c1" d="M106.592,44.561l-1.317.79L103.3,44.89l1.185-.79Z" transform="translate(-34.951 -14.72)" />
      <path fill="#7791c1" d="M102.728,46.9l-1.185.658L99.7,47.224l1.119-.724Z" transform="translate(-33.721 -15.54)" />
      <path fill="#7791c1" d="M98.994,49l-1.251.724-1.843-.4,1.185-.724Z" transform="translate(-32.423 -16.258)" />
      <path fill="#7791c1" d="M95.363,51.1l-1.185.724-1.778-.4,1.119-.724Z" transform="translate(-31.228 -16.975)" />
      <path fill="#7791c1" d="M91.36,53.261l-1.251.724-1.909-.4,1.119-.79Z" transform="translate(-29.793 -17.693)" />
    </g>
  </svg>
);

export default Svg;

