import * as React from 'react';

const DeliveredWireboxSvg = () => (
  <svg style={{ widht: '50px', height: '20px' }} viewBox="0 0 49.081 19.602">
    <g transform="translate(-302 -800.699)">
      <path fill="#45547a" stroke="#45547a" strokeWidth="0.6px" d="M5.594,29h19a.593.593,0,0,0,.594-.594V16.531a.619.619,0,0,0-.084-.305l-3.563-5.937A.594.594,0,0,0,21.031,10H9.156a.594.594,0,0,0-.509.289L5.084,16.226A.619.619,0,0,0,5,16.531V28.406A.593.593,0,0,0,5.594,29ZM15.688,11.188H20.7l2.85,4.75H15.688Zm-6.2,0H14.5v4.75H6.642Zm-3.3,5.937H24V27.812H6.188Z" transform="translate(325.594 791)" />
      <path fill="#45547a" d="M1597.541-4.4l-5.879-5.88a.749.749,0,0,1-.362-.642.748.748,0,0,1,.1-.378.744.744,0,0,1,.128-.171.751.751,0,0,1,.078-.068l5.932-5.932a.751.751,0,0,1,1.061,0,.751.751,0,0,1,0,1.061l-4.739,4.738h12.688a.75.75,0,0,1,.75.751.75.75,0,0,1-.75.75h-12.66l4.711,4.711a.751.751,0,0,1,0,1.061.749.749,0,0,1-.531.219A.749.749,0,0,1,1597.541-4.4Z" transform="translate(1909.3 800.052) rotate(180)" />
    </g>
  </svg>
);

export default DeliveredWireboxSvg;
