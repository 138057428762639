import * as React from 'react';
import { compose, withApollo } from 'react-apollo';

/* Styling */
import './aboutus.web.page.css';

/* Components */
import Header from '../../../components/web/header/header.component';
import Footer from '../../../components/web/footer/footer.component';
import Ellipse from '../../../components/web/ellipse/ellipse.component';

class AboutUsWebPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header clear={false} />
        <div className="web-body">
          <div className="header-image-container">
            <Ellipse>
              <div className="header-image" >
                <h1>About Us</h1>
              </div>
            </Ellipse>
          </div>
          <div className="aboutus-content">
            <h1 className="text-bold">We Empower Through Innovation</h1>
            <p className="text">Opportunity begins when connections are made. Our vision is to empower individuals and businesses by enhancing the process of sending and receiving goods. Increasing accessibility to delivery services and creating jobs to fulfil the demand.</p>
          </div>
          <div className="aboutus-img" />
          <Footer />
        </div>
      </div>
    );
  }
}


export default compose(withApollo)(AboutUsWebPage);

