import React from 'react';

class MovingDots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dots: 0,
    };
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState(state => (state.dots === 3 ? { dots: 0 } : { dots: state.dots + 1 }));
    }, 250);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const arr = Array(this.state.dots).fill(0);
    return arr.map(() => '.');
  }
}

export default MovingDots;
