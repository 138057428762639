/**
# * @file: src/graphql/queries/GetVehicleModels.query.js
# * @description GetVehicleModels Query File
# * @copyright: Verge Technologies 2018
# * @author Fabio Loreggian fabio@verge.co.za
# */
import gql from 'graphql-tag';

const GetVehicleModels = gql`
    query GetVehicleModels($params: VehicleFiltersInput){
      getVehicleModels(params: $params){
        make
        model
        model_year     
      }
    }
`;

export default GetVehicleModels;

