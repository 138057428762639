import * as React from 'react';
import { compose, withApollo } from 'react-apollo';

/* Components */
import Footer from '../../signup/footer/footer.component';

/* Styling */
import './footer.component.css';

/* Svg's */
import ArrowSvg from '../../../components/svg/arrowSvg.svg';


/* Utils */
import { validatePhone } from '../../../common/utils';
import SendInvite from '../../../graphql/queries/sendInvite.query';

/* Images */
const googlePlay = require('../../../assets/images/googlePlay.png');
const appleStore = require('../../../assets/images/appleStore.png');

class FooterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cellphone: '',
    };
  }

  componentDidMount() {
  }

  sendCellNumber = () => {
    if (validatePhone(this.state.cellphone)) {
      let phone = this.state.cellphone;
      if (phone[0] === '0') {
        phone = `27${phone.substring(1)}`;
      }

      console.log(phone);

      const options = {
        query: SendInvite,
        variables: {
          phone,
        },
      };

      this.props.client.query(options).then((res) => {
        console.log(res);
        this.setState({ cellphone: '' });
      }).catch((err) => {
        console.log(err);
        this.setState({ cellphone: '' });
      });
    }

    this.setState({ cellphone: '' });
  }

  render() {
    return (
      <div className="web-footer">
        <div className="footer-img">
          <p className="text-bold heading">Download Boxel client</p>
          <div className="stores">
            <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.boxel.client">
              <img src={googlePlay} alt="Google Play Store" />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/za/app/boxel-client/id1438710700?mt=8">
              <img src={appleStore} alt="Apple Store" />
            </a>
          </div>
          <div className="footer-contact">
            <p className="text">OR</p>
            <p className="text">Enter your cellphone number and we'll</p>
            <p className="text">send you a download link for Boxel.</p>
            <div className="footer-input">
              <input
                value={this.state.cellphone}
                onChange={event => this.setState({ cellphone: event.target.value })}
                placeholder="Cellphone number"
                className="text"
                maxLength={12}
              />
              <button onClick={this.sendCellNumber} disabled={!validatePhone(this.state.cellphone)}>
                <ArrowSvg />
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}


export default compose(withApollo)(FooterComponent);
