import * as React from 'react';

const SecureSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <g transform="translate(-718 -570)">
      <g transform="translate(718 570)">
        <circle fill="#e3f0fc" cx="50" cy="50" r="50" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="1.716" height="1.716" transform="translate(30.888 81.538)" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="2.13" height="2.13" transform="translate(56.627 9.527)" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="0.888" height="0.888" transform="translate(79.112 22.604)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="0.888" height="0.888" transform="translate(11.065 52.189)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#7791c1" strokeWidth="0.75px" width="0.769" height="0.769" transform="translate(53.373 5.562)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="0.888" height="0.888" transform="translate(40.651 81.183)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="2.367" height="2.367" transform="translate(14.72 59.428) rotate(-90)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="1.183" height="1.183" transform="translate(60.873 90.198) rotate(-90)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="0.769" height="0.769" transform="translate(75.858 18.58)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="0.888" height="0.888" transform="translate(83.373 63.314)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="2.367" height="2.367" transform="translate(79.318 50.418) rotate(-90)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="0.769" height="0.769" transform="translate(35.03 77.751)" />
        <ellipse strokeMiterlimit="10" strokeWidth="2px" fill="#828282" stroke="#4b5467" cx="23.432" cy="22.959" rx="23.432" ry="22.959" transform="translate(37.278 30.888)" />
        <circle strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#dce2ea" cx="22.663" cy="22.663" r="22.663" transform="translate(38.935 32.012)" />
        <circle strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#dce2ea" cx="19.586" cy="19.586" r="19.586" transform="translate(41.893 34.97)" />
        <path strokeMiterlimit="10" strokeWidth="2px" fill="#828282" stroke="#4b5467" opacity="0.73" d="M96.751,75.405l4.32,2.663,3.432-2.13L96.751,71.5,86.1,77.3V90.08l10.414,5.976L107.343,89.9V85.879L96.751,91.914l-6.686-3.55v-9.29Z" transform="translate(-35.153 -29.192)" />
        <path strokeMiterlimit="10" strokeWidth="2px" fill="#828282" stroke="#4b5467" opacity="0.73" d="M97.4,86.705v3.905l3.965,2.249,10.592-6.154V82.8l-10.592,6.213Z" transform="translate(-39.767 -33.806)" />
        <ellipse strokeMiterlimit="10" strokeWidth="2px" fill="#828282" stroke="#4b5467" cx="24.793" cy="24.32" rx="24.793" ry="24.32" transform="translate(19.408 24.32)" />
        <path fill="#dce2ea" d="M92.63,100.64l-.178-3.373a22.577,22.577,0,0,0,10.828-4.615,18.805,18.805,0,0,0,6.568-9.586c.947-3.728,2.012-7.337-.355-14.32-.592-1.716-2.663-4.083-3.254-6.095l4.083-1.3s-3.314-3.55-7.751-3.55a10.257,10.257,0,0,0-6.864,2.367L80.5,96.5l6.45,2.84Z" transform="translate(-32.867 -23.599)" />
        <circle strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#dce2ea" cx="23.964" cy="23.964" r="23.964" transform="translate(21.775 26.154)" />
        <circle strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#dce2ea" cx="20.71" cy="20.71" r="20.71" transform="translate(24.911 29.29)" />
        <path fill="#dce2ea" d="M55.1,88.367a31.536,31.536,0,0,0,17.4,1.657c10.71-2.13,15.562-14.97,6.982-34.024,0,0,14.26,10.355,6.568,25.858,0,0-2.6,6.627-9.408,9.112,0,0-2.959,3.373-13.491,1.3A19.909,19.909,0,0,1,55.1,88.367Z" transform="translate(-22.496 -22.864)" />
        <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#fafbfc" d="M122.688,44.72h-11.6V33.3H106.3V44.72H94.7v4.793h11.6V61.288h4.793V49.513h11.6Z" transform="translate(-38.664 -13.596)" />
        <path strokeMiterlimit="10" strokeWidth="2px" fill="#828282" stroke="#4b5467" opacity="0.73" d="M69.543,66.7l4.615,2.781,3.609-2.249L69.543,62.5,58.3,68.654V82.145l11.006,6.391,11.479-6.509v-4.26L69.543,84.157,62.5,80.37V70.547Z" transform="translate(-23.803 -25.518)" />
        <path strokeMiterlimit="10" strokeWidth="2px" fill="#828282" stroke="#4b5467" opacity="0.73" d="M70.2,78.642v4.083l4.2,2.426,11.183-6.509V74.5L74.4,81.068Z" transform="translate(-28.662 -30.417)" />
      </g>
    </g>
  </svg>


);

export default SecureSvg;
