import * as React from 'react';
import { Link } from 'react-router-dom';
import { compose, withApollo } from 'react-apollo';

/* Styling */
import './contact.web.page.css';

/* Components */
import Header from '../../../components/web/header/header.component';
import Input from '../../../components/web/input/input.component';
import Button from '../../../components/web/button/button.component';
import Footer from '../../../components/web/footer/footer.component';
import Ellipse from '../../../components/web/ellipse/ellipse.component';

class ContactWebPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      category: '',
      message: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header clear={false} />
        <div className="web-body">
          <div className="header-image-container">
            <Ellipse>
              <div className="header-image" >
                <h1>Contact Us</h1>
              </div>
            </Ellipse>
          </div>
          <div className="contact-container">
            <div className="contact-content">
              <p className="text">Have you checked our <Link to="/faq">FAQ</Link> page?</p>
              <div className="contact-input-container">
                <div className="input-50">
                  <Input
                    card
                    heading="Name"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={name => this.setState({ name })}

                  />
                  <Input
                    card
                    heading="Email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={email => this.setState({ email })}

                  />
                </div>
                <br />
                <Input
                  card
                  dropdown={[
                    {
                      value: '1',
                      name: 'Option 1',
                    },
                    {
                      value: '2',
                      name: 'Option 2',
                    },
                  ]}
                  value={this.state.category.name}
                  onChange={category => this.setState({ category })}
                  heading="Reason for Inquiry"
                  placeholder="Select category"
                />
                <br />
                <Input
                  card
                  textarea
                  value={this.state.message}
                  onChange={message => this.setState({ message })}
                  heading="Message"
                  placeholder="Type here..."
                />
                <br />
                <Button text="SEND" onClick={() => {}} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}


export default compose(withApollo)(ContactWebPage);

