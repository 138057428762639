import * as React from 'react';

const IdSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.858 88.006">
    <g transform="translate(-994.031 -363.539)">
      <rect fill="none" stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" width="56.892" height="77.14" rx="2.83" transform="translate(996.497 365.039)" />
      <line stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" fill="#4eb588" strokeLinecap="round" x2="24.752" y2="0.202" transform="translate(1021.31 407.281)" />
      <line stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" fill="#4eb588" strokeLinecap="round" x2="24.752" y2="0.217" transform="translate(1021.31 415.61)" />
      <path fill="none" stroke="#ccd1db" strokeWidth="3px" d="M53.154,31.124c-1-1.113-8.227-3.947-8.227-3.947V23.9c2.892-1.547,4.468-5.7,4.54-9.86.087-5.306-2.357-8.675-6.824-8.675s-7.027,3.181-7.113,8.487c-.072,4.048,1.316,8.125,3.976,9.86v3.267A74.967,74.967,0,0,0,31.93,30.6" transform="translate(996.118 365.01)" />
      <line stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" fill="#4eb588" strokeLinecap="round" x2="24.752" y2="0.202" transform="translate(1021.31 423.113)" />
      <path stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" fill="#fff" d="M53.348,86.605,13.381,78.755a4.179,4.179,0,0,1-3.7-4.713L9.44,4.124c.4-2.226,1.852-2.66,4.29-2.3L54.536,8.533a4.168,4.168,0,0,1,3.72,4.7l.237,69.976a4.48,4.48,0,0,1-5.144,3.4Z" transform="translate(986.092 363.384)" />
      <line stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" fill="#4eb588" strokeLinecap="round" x2="24.454" y2="3.86" transform="translate(1006.633 379.913)" />
      <path fill="none" stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" strokeLinecap="round" d="M13.045,0C5.8-.021-.025,6.5,0,14.552S5.894,29.174,13.135,29.2s13.07-6.5,13.045-14.567S20.272.021,13.045,0Zm.073,23.744c-4.593-.013-8.234-4.116-8.25-9.177s3.646-9.143,8.193-9.129,8.219,4.131,8.235,9.177S17.65,23.757,13.119,23.744Z" transform="translate(1004.251 392.673) rotate(-3)" />
    </g>
  </svg>


);

export default IdSvg;
