import * as React from 'react';

const PoliceSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.784 76.771">
    <g transform="translate(-3.5 -4.879)">
      <path fill="none" stroke="#ccd1db" strokeMiterlimit="10" strokeWidth="3px" d="M80.133,47.752a32.706,32.706,0,0,1-23.321-8.87h0L56.4,38.5,56,38.882h0a32.74,32.74,0,0,1-23.3,8.87S32.6,83.639,56,95.16l.535.255h0l.357-.178C80.337,83.716,80.133,47.752,80.133,47.752Z" transform="translate(-20.64 -23.471)" />
      <path fill="none" stroke="#ccd1db" strokeMiterlimit="10" strokeWidth="3px" d="M66.783,18.877A43.1,43.1,0,0,1,36.4,7.51h0L35.891,7l-.51.51h0A43.041,43.041,0,0,1,5,18.877S4.847,64.883,35.382,79.666L36.07,80h0l.459-.229C67.063,64.96,66.783,18.877,66.783,18.877Z" />
      <path fill="none" stroke="#ccd1db" strokeMiterlimit="10" strokeWidth="3px" strokeLinecap="round" d="M77.079,123.851l9.516,8.543L102.743,112.6" transform="translate(-51.711 -78.685)" />
    </g>
  </svg>
);

export default PoliceSvg;
