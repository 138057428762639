/**
# * @file: src/graphql/mutations/uploadFile.mutation.js
# * @description Confirm OTP Mutation File
# * @copyright: Verge Technologies 2018
# * @author Fabio Loreggian fabio@verge.co.za
# */
import gql from 'graphql-tag';

const UpdateDriverProfile = gql`
    mutation UpdateDriverProfile($params: UpdateDriverInput!){
      updateDriverProfile(params: $params)
    }
`;

export default UpdateDriverProfile;
