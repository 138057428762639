import * as React from 'react';

const LegalSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169 169">
    <circle fill="#e3f0fc" cx="84.5" cy="84.5" r="84.5" />
    <rect fill="none" stroke="#55b790" width="2.9" height="2.9" transform="translate(56.9 141.8)" />
    <rect fill="none" stroke="#55b790" width="3.6" height="3.6" transform="translate(12.4 66.1)" />
    <rect fill="none" stroke="#55b790" width="1.5" height="1.5" transform="translate(80.4 13.2)" />
    <rect fill="none" stroke="#7791c1" width="1.5" height="1.5" transform="translate(19.4 55.2)" />
    <rect fill="none" stroke="#7791c1" width="1.3" height="1.3" transform="translate(88.9 10.4)" />
    <rect fill="none" stroke="#7791c1" width="1.5" height="1.5" transform="translate(106.4 145.2)" />
    <rect fill="none" stroke="#fafbfc" width="4" height="4" transform="translate(155.616 105.474) rotate(-90)" />
    <rect fill="none" stroke="#fafbfc" width="4.7" height="4.7" transform="translate(42.098 138.938) rotate(-7.695)" />
    <rect fill="none" stroke="#fff" width="1.3" height="1.3" transform="translate(17.9 77.4)" />
    <rect fill="none" stroke="#7791c1" width="1.5" height="1.5" transform="translate(145.6 51)" />
    <rect fill="none" stroke="#fafbfc" width="4" height="4" transform="translate(149.787 64.247) rotate(-90)" />
    <rect fill="none" stroke="#fff" width="1.3" height="1.3" transform="translate(65.9 155.4)" />
    <rect fill="none" stroke="#7791c1" width="3.1" height="3.1" transform="translate(152.4 114.6)" />
    <path fill="#fafbfc" stroke="#4b5467" strokeWidth="2px" d="M82.507,70.63c-.13.391,9.654-48.139,16.7-48.53L169,30.971s-11.872,22.308-16.177,49.052c-3.522,22.439-2.087,45.529-2.087,45.529l-70.447-8.871A153.58,153.58,0,0,1,82.507,70.63Z" transform="translate(-9 -6)" />
    <line stroke="#4b5467" strokeWidth="2px" fill="#4eb588" strokeLinecap="round" strokeMiterlimit="10" x2="42.1" y2="5.1" transform="translate(94.5 33.5)" />
    <line stroke="#4b5467" strokeWidth="2px" fill="#4eb588" strokeLinecap="round" strokeMiterlimit="10" x2="42.1" y2="5.1" transform="translate(92.6 38.6)" />
    <line stroke="#4b5467" strokeWidth="2px" fill="#4eb588" strokeLinecap="round" strokeMiterlimit="10" x2="42.1" y2="5.1" transform="translate(90.9 44.6)" />
    <line stroke="#4b5467" strokeWidth="2px" fill="#4eb588" strokeLinecap="round" strokeMiterlimit="10" x2="16.2" y2="1.9" transform="translate(89.6 49.7)" />
    <ellipse fill="none" stroke="#4b5467" strokeMiterlimit="10" cx="12.155" cy="12.155" rx="12.155" ry="12.155" transform="translate(118.788 78.322) rotate(42)" />
    <ellipse fill="none" stroke="#4b5467" strokeMiterlimit="10" cx="10.541" cy="10.541" rx="10.541" ry="10.541" transform="translate(118.883 80.771) rotate(42)" />
    <line fill="#fafbfc" stroke="#4b5467" strokeLinecap="round" strokeMiterlimit="10" y1="3.988" x2="14.767" transform="translate(115.079 89.575) rotate(42)" />
    <line fill="#fafbfc" stroke="#4b5467" strokeLinecap="round" strokeMiterlimit="10" y1="3.751" x2="13.864" transform="translate(114.122 93.121) rotate(42)" />
    <line fill="#fafbfc" stroke="#4b5467" strokeLinecap="round" strokeMiterlimit="10" y1="3.751" x2="13.864" transform="translate(118.122 87.121) rotate(42)" />
    <g transform="translate(19.271 71.341)">
      <path stroke="#4b5467" strokeMiterlimit="10" fill="#69717b" d="M37.858,3.75V5.478s30.869,59.486-1.946,75.444l-1.234-.081C1.835,64.883,2,15.226,2,15.226A46.149,46.149,0,0,0,34.678,2.94h0l.548-.54,2.632,1.672" transform="translate(0 -1)" />
      <path stroke="#4b5467" strokeWidth="2px" strokeMiterlimit="10" fill="#dce2ea" d="M45.512,84.864C78.355,69.23,78.053,20.383,78.053,20.383A46.721,46.721,0,0,1,45.375,8.34h0l-.548-.54-.548.54h0A46.66,46.66,0,0,1,11.6,20.383s-.164,48.739,32.678,64.4Z" transform="translate(-6.968 -3.742)" />
      <path stroke="#4b5467" strokeWidth="2px" strokeMiterlimit="10" fill="#dce2ea" d="M65.416,99.407C90.61,87.2,90.391,49.1,90.391,49.1a35.486,35.486,0,0,1-25.057-9.4h0L64.9,39.3l-.439.405h0A35.486,35.486,0,0,1,39.4,49.1s-.11,38.019,25.057,50.224Z" transform="translate(-27.147 -26.737)" />
      <path fill="#aaacaf" d="M45.467,51.86c-2.522.135-4.167.081-4.167.081.685,28.487,14.749,45.454,24.865,48.451C55.117,92.048,45,70.977,49.963,56.126c.493-1.512,7.566-5.346,16.778-5.184,10.39.189,17.849,3.141,23.442,1.872l-8.446-1.98-8.965-3.78c-5.4-2.619-6.086-5.022-6.607-4.59a43.331,43.331,0,0,1-13.3,7.642A70.22,70.22,0,0,1,45.467,51.86Z" transform="translate(-28.526 -29.009)" />
      <path stroke="#4b5467" strokeWidth="2px" strokeMiterlimit="10" fill="#828282" opacity="0.73" d="M101.055,130.326l-9.129-8.236a1.591,1.591,0,0,1-.11-2.241,1.65,1.65,0,0,1,2.275-.108l6.689,6.048,16.97-19.253a1.651,1.651,0,0,1,2.275-.162,1.592,1.592,0,0,1,.164,2.241Z" transform="translate(-65.897 -76.423)" />
    </g>
  </svg>
);

export default LegalSvg;
