import * as React from 'react';

const VehicleDetailsSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 172">
    <g transform="translate(-595 -290.543)">
      <g transform="translate(683 -275)">
        <circle fill="#e3f0fc" cx="86" cy="86" r="86" transform="translate(-88 565.543)" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="2.972" height="2.972" transform="translate(49.503 611.145)" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="3.684" height="3.684" transform="translate(-75.39 632.775)" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="1.486" height="1.486" transform="translate(-6.183 578.998)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.486" height="1.486" transform="translate(-68.266 621.743)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#7791c1" strokeWidth="0.75px" width="1.303" height="1.303" transform="translate(2.509 576.127)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.486" height="1.486" transform="translate(-40.786 612.631)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.071" height="4.071" transform="translate(-39.638 602.885) rotate(-90)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.763" height="4.763" transform="translate(-25.494 716.436) rotate(-7.7)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.303" height="1.303" transform="translate(-67.716 638.21)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.486" height="1.486" transform="translate(60.195 617.418)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.071" height="4.071" transform="translate(64.48 630.913) rotate(-90)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.303" height="1.303" transform="translate(-11.787 712.636)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="3.114" height="3.114" transform="translate(-12.905 722.198)" />
      </g>
      <g transform="translate(618.021 325.016)">
        <circle strokeMiterlimit="10" strokeWidth="2px" fill="#b2dee9" stroke="#4b5467" cx="17.8" cy="17.8" r="17.8" transform="translate(11.2 67.2)" />
        <circle strokeMiterlimit="10" strokeWidth="2px" fill="#b2dee9" stroke="#4b5467" cx="17.8" cy="17.8" r="17.8" transform="translate(87.4 67.2)" />
        <circle strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#777" cx="10.9" cy="10.9" r="10.9" transform="translate(94.3 74.1)" />
        <circle strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#777" cx="10.9" cy="10.9" r="10.9" transform="translate(18.1 74.1)" />
        <circle strokeMiterlimit="10" strokeWidth="2px" fill="#4b5467" stroke="#4c5467" cx="3.2" cy="3.2" r="3.2" transform="translate(102 81.8)" />
        <circle strokeMiterlimit="10" strokeWidth="2px" fill="#4b5467" stroke="#4c5467" cx="3.2" cy="3.2" r="3.2" transform="translate(25.8 81.8)" />
        <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#fafbfc" d="M86.2,85H50.7a1.421,1.421,0,0,1-.6-.2L30.5,69.3C28.8,79.3,12.9,66,13,65.7L25.5,50.4a.891.891,0,0,1,.8-.5H62.1a.844.844,0,0,1,.8,1.2c-1.5,3.4-6,13.3-6,19.5,0,15.8,22.7,9.9,29.4,7.3C87.3,77.5,87.2,85,86.2,85Z" />
        <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#828282" d="M46.2,48.6c-.4,3.8-1.4,2.8-9,2.5s-12.8.6-12.7-3.2,5.4-6.7,13-6.5S47,39.9,46.2,48.6Z" />
        <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#828282" d="M64.1,49.2c-.1,3.6-10,2.4-17.6,2.4-2.2,0-1.5-.7-1.5-1.5-.1-1.7-.1-4.4,0-6.9.1-2.2,2.9-1.4,10.5-1.2S64.3,45.4,64.1,49.2Z" />
        <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#7791c1" d="M3.3,85h47a2.166,2.166,0,0,0,2.1-1.4c2.5-5.9,5-28.9-9.1-29.8C19.3,52.2,6.4,73,1.3,81.5A2.333,2.333,0,0,0,3.3,85Z" />
        <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#fafbfc" d="M86.2,68.8c1.4-3.4,4.1-19,3.5-21.8-.3-1.2-5.9-16.6-5.9-18.1-.1-3.3-.8-10.6.4-11.3.6-.4,15.1,18.5,27.2,42a9.45,9.45,0,0,1-7.2,13.7l-6,.8L83.6,76C81.9,75.7,85.5,70.3,86.2,68.8Z" />
        <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#7791c1" d="M123.6,76.4l-24.2-.6c-.9,0-3.6,2.3-3.9,3.1l-3.8,4.7a2.332,2.332,0,0,1-2.1,1.3H79.8c.9-8.7,4.5-13.5,7.3-17.1a26.622,26.622,0,0,1,6.6-5.2c6-3.2,24.9-2,32,12.1C126.5,76.4,125.3,76.4,123.6,76.4Z" />
        <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#f8e5bf" d="M97.8,22.5,97,15.1c-.4-1-3.3-1.7-4-.8,0,0-.9.9-.8,1.3l2.2,11C95.5,26.7,97.8,23.8,97.8,22.5Z" />
        <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#fafbfc" d="M91.9,13.8H87.5c-3.1.3-7,7.9-.6,11h0s7.5,2.4,8,1.5c.5-.8-1-12.5-1-12.5h-2Z" />
        <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#959dad" strokeLinecap="round" x2="15.8" transform="translate(19.6 72.2)" />
        <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#959dad" strokeLinecap="round" x2="15.6" transform="translate(20.5 76.7)" />
        <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#e39caa" d="M16.7,53.7l-6.3,7.8c-.7,1.2-.2,3.8,1.2,3.8,0,0,1.6.2,1.9-.2L22,54.7C23,53.4,18.1,52.5,16.7,53.7Z" />
        <path fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" strokeLinecap="round" d="M89.2,31.2l9.1,14.5L102.6,56" />
        <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#fafbfc" d="M80.9,5.6V10a20.98,20.98,0,0,1-1.2,5.2c-.8,1.8,2.7-1.2,3.6-4.8.8-3.6-1.3-9-2-9.4C80.6.7,80.9,4.8,80.9,5.6Z" />
        <circle strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#777" cx="2.9" cy="2.9" r="2.9" transform="translate(79.6 16.8)" />
      </g>
    </g>
  </svg>
);

export default VehicleDetailsSvg;
