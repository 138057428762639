import * as React from 'react';

const Svg = props => (
  <svg width={35} height={64} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.284 34.98">
    <g transform="translate(-205.24 -2499.884)">
      <g transform="translate(211.115 2493.121) rotate(13)">
        <rect fill="#fff" strokeMiterlimit="10" stroke="#9b9fa7" strokeWidth="2px" width="41.126" height="21.393" rx="1" transform="translate(0 20.563) rotate(-30)" />
        <ellipse fill="#fff" strokeMiterlimit="10" stroke="#9b9fa7" strokeWidth="1.5px" cx="5.134" cy="5.134" rx="5.134" ry="5.134" transform="translate(16.121 17.679) rotate(-30)" />
        <line stroke="#9b9fa7" strokeWidth="1.5px" fill="none" strokeLinecap="round" x2="7.939" y2="13.751" transform="translate(33.984 4.085)" />
        <line stroke="#9b9fa7" strokeWidth="1.5px" fill="none" strokeLinecap="round" x1="4.045" y2="2.335" transform="translate(29.939 4.085)" />
        <line stroke="#9b9fa7" strokeWidth="1.5px" fill="none" strokeLinecap="round" x1="4.045" y2="2.335" transform="translate(4.054 19.03)" />
        <line stroke="#9b9fa7" strokeWidth="1.5px" fill="none" strokeLinecap="round" x1="4.045" y2="2.335" transform="translate(37.879 17.836)" />
        <line stroke="#9b9fa7" strokeWidth="1.5px" fill="none" strokeLinecap="round" x1="4.045" y2="2.335" transform="translate(11.994 32.781)" />
        <line stroke="#9b9fa7" strokeWidth="1.5px" fill="none" strokeLinecap="round" x2="8.114" y2="13.631" transform="translate(3.88 21.485)" />
        <text fill="#9b9fa7" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700" fontSize="6px" transform="translate(22.772 22.961) rotate(-30)"><tspan x="0" y="0">R</tspan></text>
      </g>
      <g transform="translate(232.86 2491.521) rotate(30)">
        <rect fill="#fff" strokeMiterlimit="10" stroke="#9b9fa7" strokeWidth="2px" width="41.126" height="21.393" rx="1" transform="translate(0 20.563) rotate(-30)" />
        <ellipse fill="#fff" strokeMiterlimit="10" stroke="#9b9fa7" strokeWidth="1.5px" cx="5.134" cy="5.134" rx="5.134" ry="5.134" transform="translate(16.121 17.679) rotate(-30)" />
        <line stroke="#9b9fa7" strokeWidth="1.5px" fill="none" strokeLinecap="round" x2="7.939" y2="13.751" transform="translate(33.984 4.085)" />
        <line stroke="#9b9fa7" strokeWidth="1.5px" fill="none" strokeLinecap="round" x1="4.045" y2="2.335" transform="translate(29.939 4.085)" />
        <line stroke="#9b9fa7" strokeWidth="1.5px" fill="none" strokeLinecap="round" x1="4.045" y2="2.335" transform="translate(4.054 19.03)" />
        <line stroke="#9b9fa7" strokeWidth="1.5px" fill="none" strokeLinecap="round" x1="4.045" y2="2.335" transform="translate(37.879 17.836)" />
        <line stroke="#9b9fa7" strokeWidth="1.5px" fill="none" strokeLinecap="round" x1="4.045" y2="2.335" transform="translate(11.994 32.781)" />
        <line stroke="#9b9fa7" strokeWidth="1.5px" fill="none" strokeLinecap="round" x2="8.114" y2="13.631" transform="translate(3.88 21.485)" />
        <text fill="#9b9fa7" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700" fontSize="7px" transform="translate(22.772 22.961) rotate(-30)"><tspan x="0" y="0">R</tspan></text>
      </g>
    </g>
  </svg>
);

export default Svg;

