/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ReactGA from 'react-ga';


import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { split } from 'apollo-link';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { setContext } from 'apollo-link-context';


import Routes from './routing/routes';
import registerServiceWorker from './registerServiceWorker';

/* Styling */
import './index.css';

/* Util */
import { getValue } from './common/storage';


const cache = new InMemoryCache();
const httpLink = new HttpLink({ uri: 'https://api.boxel.co.za/graphql' });

const authLink = setContext(async (req, { headers }) => ({
  ...headers,
  headers: {
    authorization: getValue('BOXEL_API_TOKEN'),
  },
}));

const websocket = new SubscriptionClient(
  'wss://api.boxel.co.za/subscriptions',
  {
    reconnect: true,
    connectionParams: {
      authorization: getValue('BOXEL_API_TOKEN'),
    },
  },
);

const wsLink = new WebSocketLink(websocket);

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);

const client = new ApolloClient({
  cache,
  link,
});

client.websocket = websocket;

ReactGA.initialize('UA-148901518-1');

ReactDOM.render(
  <ApolloProvider client={client}>
    <Routes />
  </ApolloProvider>,
  document.getElementById('root'),
);
registerServiceWorker();
