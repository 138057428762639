/**
 * @file: src/components/rating/rating.component.js
 * @description Rating Component
 * @copyright: Verge Technologies 2018
 * @author Fabio Loreggian fabio@verge.co.za
 */

/* Packages */
import React, { Component } from 'react';

/* Components */

/* Styling */
import './rating.style.css';

/* SVGs */
import StarSvg from '../../svg/star.svg';

class Rating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
    };
  }

  componentDidMount() {
    // console.log(this.props);
  }

  selectStar = (index) => {
    if (index === this.state.selected) {
      this.setState({ selected: index - 1 });
      this.props.onChange(index - 1);
    } else {
      this.setState({ selected: index });
      this.props.onChange(index);
    }
  }

  getStars = () => {
    const stars = Array.from(Array(this.props.number).keys());
    return stars.map((val, index) => (
      <button
        key={`star-index-${val + 1}`}
        onClick={() => {
            this.selectStar(val + 1);
        }}
        className="star"
      >
        <StarSvg color={this.state.selected >= val + 1 ? '#FFB900' : '#78849E'} />
      </button>
    ));
  }

  render() {
    return (
      <div className="rating-container">
        {this.getStars()}
      </div>
    );
  }
}

/* Default prop type values */
Rating.defaultProps = {
  number: 5,
  onChange: (number) => {
  },
};

export default Rating;
