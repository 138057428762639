import gql from 'graphql-tag';

const CreateDriverProfile = gql`
    mutation CreateDriverProfile($params: DriverInput!) {
    createDriverProfile(params: $params)
  }
`;


export default CreateDriverProfile;
