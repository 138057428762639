import * as React from 'react';

const BankStatementSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.063 92.017">
    <g transform="translate(-988.02 -401.531)">
      <g transform="translate(989.27 402.781)">
        <path fill="#fff" stroke="#ccd1db" strokeWidth="2.5px" strokeMiterlimit="10" d="M51.452,70.357H8.912A3.833,3.833,0,0,1,5,66.631V8.726A3.833,3.833,0,0,1,8.912,5h42.54a3.833,3.833,0,0,1,3.912,3.726V66.631A3.833,3.833,0,0,1,51.452,70.357Z" transform="translate(-5 -5)" />
        <rect stroke="#ccd1db" strokeWidth="2.5px" strokeMiterlimit="10" fill="rgba(0,0,0,0)" strokeLinejoin="round" width="20.399" height="2.739" transform="translate(6.4 6.395)" />
        <line stroke="#ccd1db" strokeWidth="2.5px" fill="none" strokeLinecap="round" x1="12.527" transform="translate(6.453 13.971)" />
        <line stroke="#ccd1db" strokeWidth="2.5px" fill="none" strokeLinecap="round" x1="12.527" transform="translate(6.453 19.067)" />
        <line stroke="#ccd1db" strokeWidth="2.5px" fill="none" strokeLinecap="round" x1="7.755" transform="translate(6.453 24.163)" />
        <line stroke="#ccd1db" strokeWidth="2.5px" fill="none" strokeLinecap="round" x1="7.755" transform="translate(35.981 29.26)" />
        <line stroke="#ccd1db" strokeWidth="2.5px" fill="none" strokeLinecap="round" x1="9.246" transform="translate(6.155 29.26)" />
        <line stroke="#ccd1db" strokeWidth="2.5px" fill="none" strokeLinecap="round" x1="9.246" transform="translate(34.489 39.452)" />
        <line stroke="#ccd1db" strokeWidth="2.5px" fill="none" strokeLinecap="round" x1="9.246" transform="translate(6.155 34.356)" />
        <line stroke="#ccd1db" strokeWidth="2.5px" fill="none" strokeLinecap="round" x1="9.246" transform="translate(34.489 44.549)" />
        <line stroke="#ccd1db" strokeWidth="2.5px" fill="none" strokeLinecap="round" x1="9.246" transform="translate(34.489 34.356)" />
        <line stroke="#ccd1db" strokeWidth="2.5px" fill="none" strokeLinecap="round" x1="9.246" transform="translate(34.489 23.951)" />
        <line stroke="#ccd1db" strokeWidth="2.5px" fill="none" strokeLinecap="round" x1="9.246" transform="translate(34.489 18.749)" />
        <line stroke="#ccd1db" strokeWidth="2.5px" fill="none" strokeLinecap="round" x1="5.662" transform="translate(38.073 13.547)" />
      </g>
      <g transform="matrix(0.996, -0.087, 0.087, 0.996, 997.383, 432.918)">
        <path fill="#fff" stroke="#ccd1db" strokeWidth="2.5px" strokeMiterlimit="10" d="M18.546,46.233,1.274,34.3a2.95,2.95,0,0,1-.733-4.135L20.535,1.274A2.95,2.95,0,0,1,24.67.542L41.941,12.475a2.95,2.95,0,0,1,.733,4.135L22.681,45.5A3.055,3.055,0,0,1,18.546,46.233Z" transform="translate(24.071 0) rotate(31)" />
        <path stroke="#ccd1db" strokeWidth="2.5px" fill="none" d="M23.448,0l5.234,3.611L5.339,36.742,0,32.712Z" transform="translate(25.672 3.976) rotate(31)" />
        <path stroke="#ccd1db" strokeWidth="2.5px" fill="none" d="M5.684.271l.837.576a1.7,1.7,0,0,1,.419,2.408L3.9,7.284a1.726,1.726,0,0,1-2.355.366L.712,7.075A1.694,1.694,0,0,1,.346,4.667L3.382.637A1.768,1.768,0,0,1,5.684.271Z" transform="translate(43.875 25.002) rotate(30)" />
        <path stroke="#ccd1db" strokeWidth="2.5px" fill="none" d="M7.872.327,8.709.9A1.7,1.7,0,0,1,9.076,3.31L3.842,10.324a1.724,1.724,0,0,1-2.355.314L.7,10.062A1.649,1.649,0,0,1,.388,7.655L5.621.641A1.591,1.591,0,0,1,7.872.327Z" transform="translate(29.988 28.943) rotate(29)" />
      </g>
    </g>
  </svg>
);

export default BankStatementSvg;
