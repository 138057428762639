import moment from 'moment';

/**
 * Validation function to validate emails, returns bool
 * @param {string} email
 */
export const validateEmail = (email) => {
  let valid = true;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  re.test(String(email).toLowerCase());
  if (!re.test(String(email).toLowerCase())) {
    valid = false;
    return valid;
  }
  return valid;
};

/**
 * Validation function to validate phone, returns bool
 * @param {string} phone
 */
export const validatePhone = (number) => {
  const validNumber = /^\d+$/;
  const numberLength = number.length;
  if (!validNumber.test(number)) {
    return false;
  } else if (numberLength < 10) {
    return false;
  } else if (numberLength > 11) {
    return false;
  }
  return true;
};

/**
 * Validation function to validate id, returns bool
 * @param {string} id
 */
export const validateID = (idNumber) => {
  const validNumber = /^\d+$/;
  const numberLength = idNumber.length;
  if (!validNumber.test(idNumber)) {
    return false;
  } else if (numberLength !== 13) {
    return false;
  }

  // get first 6 digits as a valid date
  const tempDate = new Date(idNumber.substring(0, 2), idNumber.substring(2, 4) - 1, idNumber.substring(4, 6));

  const day = tempDate.getDate() <= 9 ? `0${tempDate.getDate()}` : `${tempDate.getDate()}`;
  const month = tempDate.getMonth() < 9 ? `0${tempDate.getMonth() + 1}` : `${tempDate.getMonth() + 1}`;
  const year = `${tempDate.getYear()}`;


  const idDay = idNumber.substring(4, 6);
  const idMonth = idNumber.substring(2, 4);
  const idYear = idNumber.substring(0, 2);

  if (idDay !== day || idMonth !== month || idYear !== year) {
    return false;
  }

  // apply Luhn formula for check-digits
  let tempTotal = 0;
  let checkSum = 0;
  let multiplier = 1;
  for (let i = 0; i < 13; i += 1) {
    tempTotal = parseInt(idNumber.charAt(i), 10) * multiplier;
    if (tempTotal > 9) {
      tempTotal = parseInt(tempTotal.toString().charAt(0), 10) + parseInt(tempTotal.toString().charAt(1), 10);
    }
    checkSum += tempTotal;
    multiplier = (multiplier % 2 === 0) ? 1 : 2;
  }
  if ((checkSum % 10) !== 0) {
    return false;
  }


  return true;
};

/**
 * Validation function to validate id, returns bool
 * @param {string} id
 */
export const validateDOBonId = (dob, idNumber) => {
  // get first 6 digits as a valid date
  const tempDate = moment(new Date(idNumber.substring(0, 2), idNumber.substring(2, 4) - 1, idNumber.substring(4, 6)));
  console.log(dob, tempDate);

  return dob.isSame(tempDate);
};

/**
 * Validation function to validate passwords, returns bool
 * @param {string} password
 */
export const validatePassword = (password) => {
  /* let valid = true;
  const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
  re.test(password);
  if (!re.test(password)) {
    valid = false;
    return valid;
  }
  return valid; */
  return password.length >= 6;
};

/**
 * Convert seconds to readable time
 * @param {number} seconds
 */
export const convertSeconds = (time) => {
  if (time) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    if (seconds >= 10) {
      return `${minutes}:${seconds.toFixed(0)}`;
    }
    return `${minutes.toFixed(0)}:0${seconds.toFixed(0)}`;
  }
  return '0:00';
};

/**
 * Convert seconds to readable time
 * @param {file} file
 */
export const getBase64 = file => new Promise((resolve) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.onerror = (error) => {
    resolve('');
  };
});
