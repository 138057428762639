/**
 * @file: src/components/otp/otpInput.component.js
 * @description OTP Input Component
 * @copyright: Verge Technologies 2018
 * @author Fabio Loreggian fabio@verge.co.za
 */

/* Packages */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Styling */
import './otp.style.css';

class OtpInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
    };
  }


  render() {
    const {
      handleBackspace,
      updateText,
      value,
    } = this.props;


    return (
      <div className="box-container">
        <input
          className={this.state.focus ? 'box box-focused text' : 'box text'}
          value={value}
          maxLength={1}
          onChange={(event) => {
            updateText(event.target.value);
          }}
          onKeyDown={handleBackspace}
          ref={(input) => {
              this.input = input;
          }}
          onFocus={() => { this.setState({ focus: true }); }}
          onBlur={() => { this.setState({ focus: false }); }}
        />
      </div>
    );
  }
}

/* Prop Types */
OtpInput.propTypes = {
  handleBackspace: PropTypes.func.isRequired,
  updateText: PropTypes.func.isRequired,
  value: PropTypes.string,
};

/* Default prop type values */
OtpInput.defaultProps = {
  value: '',
};

export default OtpInput;
