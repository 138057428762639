/**
# * @file: src/graphql/queries/requestOtp.query.js
# * @description Request OTP Query File
# * @copyright: Verge Technologies 2018
# * @author Fabio Loreggian fabio@verge.co.za
# */

import gql from 'graphql-tag';
const RequestOtp = gql`
    query RequestOtp{
        requestOtp
    }
`;
export default RequestOtp;
