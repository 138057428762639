import * as React from 'react';

const Svg = props => (
  <svg width={91} height={93} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.482 90.469">
    <circle fill="#ddedf5" cx="45.234" cy="45.234" r="45.234" />
    <rect fill="none" stroke="#f9f9f9" strokeMiterlimit="10" strokeWidth="2px" width="1.659" height="1.659" transform="translate(38.535 30.982) rotate(180)" />
    <path fill="#d5dadd" d="M24.1,69.952V37.94c0-.054,0-.054.054-.054L36.841,34.3c.054,0,.107,0,.107.054V66.419c0,.054,0,.054-.054.054L24.207,70.006C24.154,70.006,24.1,70.006,24.1,69.952Z" transform="translate(-11.199 -15.939)" />
    <path fill="#c1c4c6" d="M48,66.4V34.334c0-.054.054-.107.107-.054l12.634,3.747a.052.052,0,0,1,.054.054v31.8c0,.054-.054.107-.107.054L48.054,66.4C48.054,66.453,48,66.4,48,66.4Z" transform="translate(-22.305 -15.919)" />
    <path fill="#d5dadd" d="M71.9,69.845V38.154a.115.115,0,0,1,.107-.107L84.534,34.3c.054,0,.161.054.161.107V66.366a.115.115,0,0,1-.107.107l-12.526,3.48C72.007,70.006,71.9,69.952,71.9,69.845Z" transform="translate(-33.411 -15.939)" />
    <path fill="#6b7aac" d="M37.936,25.2a4.823,4.823,0,0,0-9.636,0,4.083,4.083,0,0,0,.642,2.248l.054.054.321.428,2.891,4.711c.214.321,1.445.375,1.659,0l3.265-4.925h0A4.04,4.04,0,0,0,37.936,25.2Zm-4.818,2.3a2.16,2.16,0,0,1-2.195-2.141,2.194,2.194,0,0,1,2.195-2.141,2.16,2.16,0,0,1,2.195,2.141A2.127,2.127,0,0,1,33.118,27.506Z" transform="translate(-13.15 -9.572)" />
    <path fill="#6a6663" d="M131.2,88.08V60.458L107.329,46.7,83.4,60.458V88.08l23.929,13.811Z" transform="translate(-38.754 -21.701)" />
    <path fill="#6a6663" d="M83.4,72.4l22.269,12.848a3.239,3.239,0,0,0,3.265,0L131.2,72.4" transform="translate(-38.754 -33.643)" />
    <path fill="#585049" d="M10871.871-5070.3l23.9,13.19,23.878-13.09-23.835-13.743Z" transform="translate(-10827.169 5109)" />
    <line fill="#6a6663" y1="27.622" transform="translate(68.574 52.568)" />
    <line fill="#6a6663" x2="23.608" y2="13.972" transform="translate(57.226 31.53)" />
    <path fill="#645f5b" d="M10895.752-5028.9v-28.214l23.835-13.057.024,27.549Z" transform="translate(-10827.169 5109)" />
    <path fill="#a7ddd6" opacity="0.55" d="M99,60.2c5.862,3.292,24.246,13.538,24.246,13.538l.321,11.884,2.677-3.747,1.606,2.088,1.713-4.283,1.445,1.445-.054-11.831L107.232,55.449" transform="translate(-46.375 -25.929)" />
    <path fill="#dfded7" d="M112.545,121.37l-6.745-3.694V104.4l6.745,3.64Z" transform="translate(-49.163 -48.513)" />
    <g transform="translate(57.761 58.831)">
      <line fill="#6a6663" x2="4.068" y2="2.248" />
      <path fill="#34354b" d="M108,109.9a2.019,2.019,0,0,1,1.124.375c.161.107.268.268.428.375a1.6,1.6,0,0,0,.535.214,6.381,6.381,0,0,1,1.071.428,3.175,3.175,0,0,1,.91.8v.054a3.46,3.46,0,0,1-1.124-.321,3.629,3.629,0,0,1-.964-.7,2.514,2.514,0,0,0-.482-.321c-.161-.054-.375-.107-.589-.161a1.557,1.557,0,0,1-.91-.749Z" transform="translate(-107.946 -109.9)" />
    </g>
    <g transform="translate(57.975 61.026)">
      <line fill="#6a6663" x2="4.068" y2="2.195" transform="translate(0 0.054)" />
      <path fill="#34354b" d="M108.4,114a2.018,2.018,0,0,1,1.124.375c.161.107.268.268.428.375a1.6,1.6,0,0,0,.535.214,6.379,6.379,0,0,1,1.071.428,3.175,3.175,0,0,1,.91.8v.054a3.459,3.459,0,0,1-1.124-.321,3.629,3.629,0,0,1-.964-.7,2.515,2.515,0,0,0-.482-.321c-.161-.054-.375-.107-.589-.161a1.386,1.386,0,0,1-.91-.749Z" transform="translate(-108.346 -114)" />
    </g>
    <g transform="translate(57.761 63.221)">
      <line fill="#6a6663" x2="4.068" y2="2.195" transform="translate(0 0.054)" />
      <path fill="#34354b" d="M108,118.1a2.019,2.019,0,0,1,1.124.375c.161.107.268.268.428.375a1.6,1.6,0,0,0,.535.214,6.381,6.381,0,0,1,1.071.428,3.175,3.175,0,0,1,.91.8v.054a3.46,3.46,0,0,1-1.124-.321,3.629,3.629,0,0,1-.964-.7,2.514,2.514,0,0,0-.482-.321c-.161-.054-.375-.107-.589-.161a1.386,1.386,0,0,1-.91-.749Z" transform="translate(-107.946 -118.1)" />
    </g>
    <g transform="translate(59.313 67.429)">
      <line fill="#6a6663" x2="2.034" y2="1.071" transform="translate(0 0.075)" />
      <path fill="#34354b" d="M110.9,125.982a.7.7,0,0,1,.589.107c.054.054.107.214.214.268a.409.409,0,0,0,.268.107,1.079,1.079,0,0,1,.589.161.854.854,0,0,1,.375.482v.054a1.264,1.264,0,0,1-.642-.054,1.443,1.443,0,0,1-.428-.375.418.418,0,0,0-.214-.214c-.107-.054-.214,0-.321,0-.268-.054-.375-.214-.428-.535Z" transform="translate(-110.846 -125.961)" />
    </g>
    <path fill="#52b570" d="M93.93,89.735l2.088,4.657-3,4.122-5.086-.535L85.9,93.322l3-4.122Z" transform="translate(-39.916 -41.45)" />
  </svg>
);

export default Svg;

