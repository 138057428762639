import * as React from 'react';

/* Styling */
import './header.style.css';

/* Images */
const logo = require('../../../assets/images/boxel-logo.png');


class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  // eslint-disable-next-line class-methods-use-this
  openAppStore = () => { // eslint-disable-line consistent-return
    // eslint-disable-next-line no-undef
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // eslint-disable-next-line no-undef
    const a = document.createElement('a');
    a.setAttribute('target', '_blank');
    a.href = 'https://boxel.co.za';
    if (/android/i.test(userAgent)) {
      a.href = 'https://play.google.com/store/apps/details?id=com.boxel.client';
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      a.href = 'https://apps.apple.com/za/app/boxel-client/id1438710700';
    }
    a.click();
  }

  render() {
    return (
      <div className="delivery-header">
        <p style={{ color: '#506290', fontSize: 21 }} className="text">Fast. Flexible. Easy</p>
        <div style={{ color: '#20D89B', fontSize: 18 }} onClick={() => this.openAppStore()} className="text-bold">DOWNLOAD BOXEL</div>
        <p style={{ color: '#506290', fontSize: 14 }}>Receive instant sign-up rewards.</p>
        <img src={logo} alt="Map" className="boxel-logo" />
        <div className="header-linear-gradient" />
      </div>
    );
  }
}

export default HeaderComponent;
