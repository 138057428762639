import * as React from 'react';
import { compose, withApollo } from 'react-apollo';

/* Styling */
import './faq.page.css';

/* Components */
import Header from '../../../components/web/header/header.component';
import Footer from '../../../components/web/footer/footer.component';
import Ellipse from '../../../components/web/ellipse/ellipse.component';

/* SVG's */

/* GraphQL */

/* Asssets */

class FAQWebPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header clear={false} />
        <div className="web-body">
          <div className="header-image-container">
            <Ellipse>
              <div className="header-image" >
                <h1 className="text-bold">FAQ</h1>
              </div>
            </Ellipse>
          </div>
          <div className="faq-container">
            <h2 className="text-bold">What is Boxel?</h2>
            <p className="text">Boxel is a delivery platform that makes sending and receiving various items from door to door.</p>

            <h2 className="text-bold">How do I start using Boxel?</h2>
            <ol>
              <li className="text">Download Boxel and register an account.</li>
              <li className="text">Choose your recipient, delivery type and confirm delivery.</li>
              <li className="text">Driver picks up and delivers item.</li>
            </ol>

            <h2 className="text-bold">How much does Boxel deliveries cost?</h2>
            <p className="text">Boxel offers different delivery options at varying prices, depending on the type, distance and time of delivery.</p>

            <h2 className="text-bold">What if I have a problem with my delivery?</h2>
            <p className="text">We’re here to help. Once you’ve confirmed a delivery, you will have contact our support team and we’ll take care of your issue.</p>

            <h2 className="text-bold">Will the driver bring the item to my door or inside my office?</h2>
            <p className="text">The driver will bring your item right to your door. If it's better for you to walk outside—that's fine too.</p>

            <h2 className="text-bold">Do I need to tip my driver?</h2>
            <p className="text">Tips aren't included in the boxes price and they aren't expected or required. You are free to tip your driver and drivers are free to accept tips at any time. No tips can be given via the Boxel app, if you wish to tip the driver you may do so by giving cash directly to your driver.You can rate your Boxel experience each time your delivery is completed to award your Boxel driver with points.</p>
          
            <h2 className="text-bold">How do scheduled deliveries work?</h2>
            <p className="text">The Scheduled delivery option allows you to select a delivery for a date and time of your choice. A driver will be requested. Scheduling a delivery in advance does not guarantee you'll be connected with a driver. In the rare case a driver cannot be found, you'll be notified.</p>

            <h2 className="text-bold">How do coupons work?</h2>
            <p className="text">Boxel offers special promotions to provide discounts on deliveries. When completing the delivery request, you can add the coupon code to redeem your discount. A coupon code's value will only apply to one order.</p>

            <h2 className="text-bold">How do I cancel a delivery?</h2>
            <p className="text">You can cancel your delivery request at any point before a driver accepts the delivery without any charge. Once your delivery has been accepted, the total delivery fee applies. Unfortunately, the option to cancel will no longer be available once the driver has picked up the item to be delivered.</p>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}


export default compose(withApollo)(FAQWebPage);

