/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import PropTypes from 'prop-types';
import { GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import './input.component.css';

const key = 'AIzaSyBg8poosqDz6xhDaQhsm2CnyUN9aF7AJ-I';

class GoogleAutocompleteInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      addressLook: null,
      showGoogleAutoComplete: false,
    };
  }

  handleChange = (addressLook) => {
    this.setState({ addressLook });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then((latLng) => {
        console.log(latLng);
        const updatedAddress = {
          street: address,
          coordinates: {
            lat: latLng.lat,
            long: latLng.lng,
          },
          id: address,
        };
        this.props.onAddressChanged(updatedAddress);
        this.setState({ showGoogleAutoComplete: false });
        this.setState({ addressLook: address, value: address });
      })
      .catch((error) => {
        this.setState({ showGoogleAutoComplete: false });
        console.error('Error', error);
      });
  };

  render() {
    const searchOptions = {
      location: new this.props.google.maps.LatLng(this.state.currentLat, this.state.currentLong),
      radius: 2500,
      types: ['address'],
      componentRestrictions: { country: 'za' },
    };

    return (
      <div
        className="input-container"
        style={{ borderColor: this.state.showGoogleAutoComplete ? '#20D89B' : '#F5F5F5', width: '100%' }}
        onClick={() => this.setState({ showGoogleAutoComplete: true })}
      >
        <p className="heading text">{this.props.heading}</p>
        { !this.state.showGoogleAutoComplete && <input className="input text-bold" defaultValue={this.state.value} onFocus={() => this.setState({ showGoogleAutoComplete: true })} /> }
        { this.state.showGoogleAutoComplete && (
          <PlacesAutocomplete
            searchOptions={searchOptions}
            value={this.state.addressLook}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: 'Type to search for address ...',
                    className: 'input text-bold',
                  })}
                />
                { loading && <div> Loading... </div> }
                {
                  suggestions.map((suggestion) => {
                    const className = `suggestion-item${suggestion.active ? '--active' : ''}`;
                    const style = {
                      backgroundColor: suggestion.active ? '#fafafa' : '#ffffff',
                      cursor: 'pointer',
                    };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{ suggestion.description }</span>
                      </div>
                    );
                  })
                }
              </div>
              )}
          </PlacesAutocomplete>
        )}
      </div>
    );
  }
}

GoogleAutocompleteInput.propTypes = {
  onAddressChanged: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};

export default GoogleApiWrapper({
  apiKey: key,
})(GoogleAutocompleteInput);
