import * as React from 'react';

const BoxelSvg = props => (
  <svg className={props.className} viewBox="0 0 101.5 117.199">
    <g id="Group_377_1_" transform="translate(-3652 4281.4)">
      <path fill="#20d89b"d="M3702.7-4220.4l-18.1-10.2v18.7l18.1,10.8,50.8-29.4v-18.7Z" />
      <path fill="#20d89b" d="M3702.7-4220.4l-18.1-10.2v18.7l18.1,10.8Z" />
      <path fill="#45547a" d="M3668.3-4202.9l-.1-40.6,34.8-19.4,21.1,12.6,15.9-9.6-37.3-21.5-50.7,29.3v58.6l50.7,29.3,50.8-29.3v-18.7l-50.8,29.2Z" />
      <path fill="#45547a" d="M3668.3-4202.9l-.1-40.6,34.8-19.4,21.1,12.6,15.9-9.6-37.3-21.5-50.7,29.3v58.6l50.7,29.3V-4183Z" />
      <path fill="#45547a" d="M3668.2-4243.5l34.8-19.4,21.1,12.6,15.9-9.6-37.3-21.5-50.7,29.3Z" />
    </g>
  </svg>
);

export default BoxelSvg;
