import * as React from 'react';

const FrontCarSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.065 67">
    <g transform="translate(-50.487 -21.63)">
      <path fill="none" stroke="#ccd1db" strokeWidth="2px" d="M75.424,13.713H69.911a2.616,2.616,0,0,0-2.594,2.363l-3.66-9.359A8.712,8.712,0,0,0,56.8,1.344C50.5.417,41.511.046,39.843,0h-.649c-1.668.046-10.7.417-16.957,1.344a9.042,9.042,0,0,0-6.857,5.374l-4.633,10.47v-.834a2.67,2.67,0,0,0-2.641-2.641H2.641A2.67,2.67,0,0,0,0,16.354v2.085A2.67,2.67,0,0,0,2.641,21.08H8.154a3.614,3.614,0,0,0,1.019-.185L4.911,30.531c-.695,1.668-.927,6.764-.6,8.571.788,4.355,0,11.907,1.9,14.594V63.7a1.3,1.3,0,0,0,1.3,1.3h7.227a1.3,1.3,0,0,0,1.3-1.3V57.448c.88.093,8.607.276,23.165-.093.463.046,22.7.185,23.535,0V63.7a1.3,1.3,0,0,0,1.3,1.3h7.32a1.3,1.3,0,0,0,1.3-1.3V54.066c2.131-1.761,1.3-10.517,2.085-14.964a9.511,9.511,0,0,0-.6-5.328L69.17,20.987a3.6,3.6,0,0,0,.741.093h5.513a2.67,2.67,0,0,0,2.641-2.641V16.354A2.67,2.67,0,0,0,75.424,13.713ZM21.172,41.974a27.688,27.688,0,0,1-4.772-.046,8.444,8.444,0,0,1-6.115-3.567C9.5,37.249,8.617,36,7.922,34.932A1.367,1.367,0,0,1,9.034,32.8c2.316-.046,5.791,0,7.413.649a12.6,12.6,0,0,1,4.077,2.826l.139.139a15.138,15.138,0,0,1,1.761,3.706A1.428,1.428,0,0,1,21.172,41.974Zm29.651-1.251a1.3,1.3,0,0,1-1.3,1.3H27.566a1.3,1.3,0,0,1-1.3-1.3v-4.54a1.3,1.3,0,0,1,1.3-1.3H49.48a1.3,1.3,0,0,1,1.3,1.3v4.54ZM39.89,21.033q-1.737-.069-3.475,0l-19.6-.093c-2.5,0-2.984-1.112-3.355-2.641a2.215,2.215,0,0,1,.139-1.344l4.633-9.868C18.79,5.05,21.033,3.521,24,3.521l15.335-.788H39.7l15.335.788c2.965,0,5.282.973,6.3,3.567l3.679,10.239a2.1,2.1,0,0,1,.139,1.3c-.371,1.483-.853,2.641-3.4,2.641Zm30.763,13.9C69.957,36,69.077,37.3,68.289,38.361a8.6,8.6,0,0,1-6.115,3.567,33.359,33.359,0,0,1-4.772.046,1.341,1.341,0,0,1-1.2-1.807,15.138,15.138,0,0,1,1.761-3.706l.139-.139A13.391,13.391,0,0,1,62.174,33.5c1.622-.695,5.1-.741,7.413-.649A1.323,1.323,0,0,1,70.652,34.932Z" transform="translate(51.487 22.63)" />
      <path fill="none" stroke="#ccd1db" strokeWidth="2px" strokeLinecap="round" d="M83.754,36.456l15.668,7.7" transform="translate(22.731 -23.901) rotate(14)" />
      <path fill="none" stroke="#ccd1db" strokeWidth="2px" strokeLinecap="round" d="M83.754,36.456l15.668,7.7" transform="translate(-0.367 -18.677) rotate(11)" />
    </g>
  </svg>
);

export default FrontCarSvg;
