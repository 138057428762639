import * as React from 'react';

/* Styling */
import './footer.style.css';
import PhoneSvg from '../../svg/phone.svg';
import MessageSvg from '../../svg/message.svg';
import DeliveredWireboxSvg from '../../svg/deliveredWirebox.svg';

/* Images */
const avatar = require('../../../assets/images/avatar.png');


class FooterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="delivery-footer">
        <div className="line">
          <p className="text-bold duration">{this.props.time} away</p>
          <div className="routeImage">
            <DeliveredWireboxSvg />
          </div>
        </div>
        <div className="line" >
          <div className="profile">
            <img src={this.props.image} alt="Profile" className="profilePicture" />
            <div className="profileName">
              <p className="text-bold" style={{ color: '#45547A', fontSize: 16 }}>{this.props.name}</p>
              <p className="text" style={{ color: '#45547A', fontSize: 16 }}>{this.props.rating} stars</p>
            </div>
          </div>
          <div className="profileActions">
            <button
              className="profileButton"
              onClick={this.props.onPhonePress}
            >
              <PhoneSvg />
            </button>
            <button
              className="profileButton"
              onClick={this.props.onMessagePress}
            >
              <MessageSvg />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

FooterComponent.defaultProps = {
  time: 0,
  name: 'John Doe',
  rating: '5',
  cellphone: '',
  email: '',
  image: avatar,
  onPhonePress: () => {},
  onMessagePress: () => {},
};


export default FooterComponent;
