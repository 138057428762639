import * as React from 'react';
import { Parallax } from 'react-parallax';
import { compose, withApollo } from 'react-apollo';
import _ from 'lodash';
/* Styling */
import './home.page.css';

/* Components */
import Header from '../../components/signup/header/header.component';
import Footer from '../../components/signup/footer/footer.component';
import Input from '../../components/signup/input/input.component';
import Button from '../../components/signup/button/button.component';
import Modal from '../../components/delivery/modal/modal.component';

/* SVG's */
import ConvenientSvg from '../../components/svg/convenient.svg';
import FlexibleSvg from '../../components/svg/flexible.svg';
import SecureSvg from '../../components/svg/secure.svg';
import { validateEmail, validatePhone, validatePassword } from '../../common/utils';
import { setValue } from '../../common/storage';

/* GraphQL */
import CreateDriverProfile from '../../graphql/mutations/creatDriverProfile.mutation';
import LoginDriverProfile from '../../graphql/mutations/loginDriverProfile.mutation';

/* Asssets */
const background = require('../../assets/images/boxelBg[2].png');

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      email: '',
      password: '',
      modal: null,
      loading: false,
    };
  }

  componentDidMount() {
  }

  getError = message => (
    <div className="error-card">
      <h1 className="text-bold">Sorry,</h1>
      <p className="text">{message}</p>
      <Button
        onClick={() => {
          this.setState({ modal: null });
        }}
        text="Okay"
      />
    </div>
  )

  signUp = () => {
    const options = {
      mutation: CreateDriverProfile,
      variables: {
        params: {
          email: this.state.email,
          phone: `27${this.state.phone.substring(1)}`,
          password: this.state.password,
        },
      },
    };
    this.setState({ loading: true });
    this.props.client.mutate(options).then((res) => {
      setValue('BOXEL_API_TOKEN', res.data.createDriverProfile);
      this.setState({ loading: false });
      this.props.history.push('signup');
    }).catch((err) => {
      this.setState({ modal: this.getError(_.startCase(err.message.replace('GraphQL error: ', ''))), loading: false });
    });
  }

  signIn = () => {
    const options = {
      mutation: LoginDriverProfile,
      variables: {
        email: this.state.email,
        password: this.state.password,
      },
    };
    this.setState({ loading: true });
    this.props.client.mutate(options).then((res) => {
      const { jwt } = res.data.loginDriverProfile;
      setValue('BOXEL_API_TOKEN', jwt);
      this.setState({ loading: false });
      this.props.history.push('signup');
    }).catch((err) => {
      this.setState({ modal: this.getError(_.startCase(err.message.replace('GraphQL error: ', ''))), loading: false });
    });
  }

  signInValid = () => (
    this.state.email !== '' &&
    this.state.password !== '' &&
    validateEmail(this.state.email)
  )

  signUpValid = () => (
    this.state.email !== '' &&
    this.state.password !== '' &&
    this.state.phone !== '' &&
    validateEmail(this.state.email) &&
    validatePassword(this.state.password) &&
    validatePhone(this.state.phone)
  )

  getCard = () => {
    if (this.props.signin) {
      return (
        <div className="signup-card">
          <p className="heading text">Sign In</p>
          <Input
            heading="Email"
            placeholder="Email"
            type="email"
            value={this.state.email}
            onChange={email => this.setState({ email })}
            valid={validateEmail}
            error="Email is invalid"
            card
          />
          <Input
            heading="Password"
            placeholder="Password"
            value={this.state.password}
            onChange={password => this.setState({ password })}
            type="password"
            card
          />
          <div className="terms">
            <p className="text-bold">By signing in you agree to our</p>
            <span className="text-bold"><a href="https://www.boxel.co.za/terms">Terms of Service</a> and <a href="https://www.boxel.co.za/privacy">Privacy Policy</a></span>
          </div>

          <Button text="SIGNIN" onClick={this.signIn} loading={this.state.loading} disabled={!this.signInValid()} />
        </div>
      );
    }
    return (
      <div className="signup-card">
        <p className="heading text">BECOME A DRIVER</p>
        <Input
          heading="Phone Number"
          placeholder="Phone Number"
          value={this.state.phone}
          onChange={phone => this.setState({ phone })}
          valid={validatePhone}
          error="Phone number is invalid"
          card
        />
        <Input
          heading="Email"
          placeholder="Email"
          type="email"
          value={this.state.email}
          onChange={email => this.setState({ email })}
          valid={validateEmail}
          error="Email is invalid"
          card
        />
        <Input
          heading="Password"
          placeholder="Password"
          value={this.state.password}
          onChange={password => this.setState({ password })}
          valid={validatePassword}
          error="Password is not valid. It must be longer than 6 characters."
          type="password"
          card
        />
        <div className="terms">
          <p className="text-bold">By creating an account you agree to our</p>
          <span className="text-bold"><a href="https://www.boxel.co.za/terms">Terms of Service</a> and <a href="https://www.boxel.co.za/privacy">Privacy Policy</a></span>
        </div>

        <Button text="NEXT" onClick={this.signUp} loading={this.state.loading} disabled={!this.signUpValid()} />
      </div>
    );
  }

  render() {
    return (
      <div className="home">
        <Header signin={this.props.signin} />
        <div className="body">
          <Parallax
            blur={2}
            bgImage={background}
            bgImageAlt="Background"
            strength={500}
          >
            <div className="background-content">
              <p className="text-bold">Earn Bucks With Boxel</p>
              <div>
                <p className="text">Set your own schedule and be your own boss.</p>
                <p className="text">Get paid for doing deliveries as a contractor</p>
                <p className="text">through Boxel.</p>
              </div>
            </div>
          </Parallax>
          {this.getCard()}
          <div className="home-content">
            <div className="home-item">
              <div className="svg-container">
                <ConvenientSvg />
              </div>
              <p className="heading text-bold">CONVENIENT</p>
              <p className="text">All it takes is a smartphone</p>
              <p className="text">and a working vehicle.</p>
            </div>

            <div className="home-item">
              <div className="svg-container">
                <FlexibleSvg />
              </div>
              <p className="heading text-bold">FLEXIBLE</p>
              <p className="text">Work wherever you are</p>
              <p className="text">in whatever hour suits you.</p>
            </div>

            <div className="home-item">
              <div className="svg-container">
                <SecureSvg />
              </div>
              <p className="heading text-bold">SECURE</p>
              <p className="text">Our card payments ensure that money</p>
              <p className="text">is exchanged efficiently and reliably.</p>
            </div>
          </div>
        </div>
        <Footer />
        {this.state.modal &&
        <Modal>{this.state.modal}</Modal>}
      </div>
    );
  }
}


export default compose(withApollo)(HomePage);

