/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-case-declarations */
import * as React from 'react';
import { Parallax } from 'react-parallax';
import { compose, withApollo, graphql } from 'react-apollo';
import { Squares } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
/* Styling */
import './signup.page.css';

/* Components */
import Header from '../../components/signup/header/header.component';
import Footer from '../../components/signup/footer/footer.component';
import Wizard from '../../components/signup/wizard/wizard.component';
import Input from '../../components/signup/input/input.component';
import Button from '../../components/signup/button/button.component';
import Dropzone from '../../components/signup/dropzone/dropzone.component';
import OTP from '../../components/signup/otp/otp.component';

/* Utils */
import { getValue } from '../../common/storage';
import { convertSeconds, getBase64 } from '../../common/utils';

/* SVG's */
import CourierInfoSvg from '../../components/svg/courierInfo.svg';
import PictureUploadSvg from '../../components/svg/pictureUpload.svg';
import ContactSvg from '../../components/svg/contact.svg';
import BankingSvg from '../../components/svg/banking.svg';
import ScooterSvg from '../../components/svg/scooter.svg';
import LightVehicleSvg from '../../components/svg/lightVehicle.svg';
import VanSvg from '../../components/svg/van.svg';
import BakkieSvg from '../../components/svg/bakkie.svg';
import TruckSvg from '../../components/svg/truck.svg';
import VehicleDetailsSvg from '../../components/svg/vehicleDetails.svg';
import PersonalDocumentsSvg from '../../components/svg/personalDocuments.svg';
import IdSvg from '../../components/svg/id.svg';
import DriversSvg from '../../components/svg/drivers.svg';
import VehicleDocumentsSvg from '../../components/svg/vehicleDocuments.svg';
import VehicleLicenceSvg from '../../components/svg/vehicleLicence.svg';
import VehicleRegistrationSvg from '../../components/svg/vehicleRegistration.svg';
import VehiclePhotosSvg from '../../components/svg/vehiclePhotos.svg';
import FrontCarSvg from '../../components/svg/frontCar.svg';
import BackCarSvg from '../../components/svg/backCar.svg';
import LeftCarSvg from '../../components/svg/leftCar.svg';
import RightCarSvg from '../../components/svg/rightCar.svg';
import LegalSvg from '../../components/svg/legal.svg';
import BankStatementSvg from '../../components/svg/bankStatement.svg';
import PoliceSVG from '../../components/svg/police.svg';
import AllDoneSvg from '../../components/svg/allDone.svg';
import CompletedSvg from '../../components/svg/medal.svg';

/* GraphQL */
import GetProfile from '../../graphql/queries/getProfile.query';
import GetVehicleModels from '../../graphql/queries/getVehicleModels.query';
import RequestOtp from '../../graphql/queries/requestOtp.query';
import ConfirmOtp from '../../graphql/mutations/confirmOtp.mutation';
import UploadFile from '../../graphql/mutations/uploadFile.mutation';
import UpdateDriverProfile from '../../graphql/mutations/updateDriverProfile.mutation';
import GoogleAutocompleteInput from '../../components/signup/input/GoogleAutocompleteInput';

/* Asssets */
const background = require('../../assets/images/boxelBg[2].png');

/* Images */
const googlePlay = require('../../assets/images/googlePlay.png');
const appleStore = require('../../assets/images/appleStore.png');

class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Data
      profile: {
        firstname: '',
        lastname: '',
        id: '',
        dob: '',
        picture: null,
        cellphone: '',
        email: '',
        fullTime: false,
        addressLook: '',
        address: {
          coordinates: null,
          street: '',
        },
        bankDetails: {
          bankName: '',
          branchCode: '',
          accountNumber: '',
          accountType: '',
        },
        vehicle: {
          make: '',
          model: '',
          year: '',
          licence: '',
          type: null,
          pictures: {
            front: null,
            back: null,
            right: null,
            left: null,
          },
        },
        documents: {
          idDocument: null,
          driversLicence: null,
          vehicleLicence: null,
          registration: null,
          bankStatement: null,
          policeClearance: null,
        },
      },
      rawDocuments: {
        idDocument: null,
        driversLicence: null,
        vehicleLicence: null,
        registration: null,
        bankStatement: null,
        policeClearance: null,
        front: null,
        back: null,
        right: null,
        left: null,
      },
      // Validation
      phoneConfirmed: false,
      idValid: false,
      dobValid: false,
      // OTP
      otp: '',
      otpTimer: 60,
      otpSent: false,
      // Wizard
      headings: [
        {
          value: 'Courier Details',
          expanded: true,
          complete: false,
          id: 1,
        },
        {
          value: 'Vehicle Details',
          expanded: false,
          complete: false,
          id: 2,
        },
        {
          value: 'Documents',
          expanded: false,
          complete: false,
          id: 3,
        },
        {
          value: 'Review',
          expanded: false,
          complete: false,
          id: 4,
        },
      ],
      steps: [
        // Courier Details
        {
          active: true,
          complete: false,
          value: 'Personal Details',
          heading: 1,
        },
        {
          active: false,
          complete: false,
          value: 'Contact Information',
          heading: 1,
        },
        {
          active: false,
          complete: false,
          value: 'Banking Details',
          heading: 1,
        },
        // Vehicle Details
        {
          active: false,
          complete: false,
          value: 'Vehicle Type',
          heading: 2,
        },
        {
          active: false,
          complete: false,
          value: 'Vehicle Information',
          heading: 2,
        },
        {
          active: false,
          complete: false,
          value: 'Legal',
          heading: 2,
        },
        // Documents
        {
          active: false,
          complete: false,
          value: 'Personal Documents',
          heading: 3,
        },
        {
          active: false,
          complete: false,
          value: 'Vehicle Documents',
          heading: 3,
        },
        {
          active: false,
          complete: false,
          value: 'Photos of Vehicle',
          heading: 3,
        },
        {
          active: false,
          complete: false,
          value: 'Legal Documents',
          heading: 3,
        },
        // Review
        {
          value: '',
          active: false,
          complete: false,
          heading: 4,
        },
        {
          value: '',
          active: false,
          complete: false,
          heading: 4,
        },
      ],
      vehicleTypes: [
        {
          name: 'Scooter',
          value: 'SCOOTER',
          icon: <ScooterSvg />,
        },
        {
          name: 'Light Vehicle',
          value: 'LIGHT_VEHICLE',
          icon: <LightVehicleSvg />,
        },
        {
          name: 'Van',
          value: 'VAN',
          icon: <VanSvg />,
        },
        {
          name: 'Bakkie',
          value: 'BAKKIE',
          icon: <BakkieSvg />,
        },
        {
          name: 'Truck',
          value: 'SMALL_TRUCK',
          icon: <TruckSvg />,
        },
      ],
      vehicleType: '',
      vehicleModels: [],
    };
  }

  async componentDidMount() {
    const token = await getValue('BOXEL_API_TOKEN');
    if (!token) {
      this.props.history.push('/home');
    }
  }

  componentWillReceiveProps(props) {
    if (!props.loading && !props.error && props.profiles.length > 0) {
      const [driverProfile] = props.profiles;
      const { profile } = this.state;

      const {
        name,
        surname,
        phone,
        email,
        number_plate: numberPlate,
        vehicle_make: vehicleMake,
        vehicle_model: vehicleModel,
        vehicle_year: vehicleYear,
        address,
        address_coordinate: addressCoordinates,
        date_of_birth: dob,
        id_document_number: idNumber,
        bank_details: bankDetails,
        picture_url: profilePicture,
      } = driverProfile;

      profile.cellphone = phone;
      profile.email = email;
      profile.firstname = name || '';
      profile.lastname = surname || '';
      profile.address.street = address || '';
      profile.address.coordinates = addressCoordinates || null;
      profile.vehicle.make = vehicleMake || '';
      profile.vehicle.year = vehicleYear || '';
      profile.vehicle.model = vehicleModel || '';
      profile.vehicle.licence = numberPlate || '';
      profile.picture = profilePicture || null;

      profile.id = idNumber || '';
      profile.dob = dob ? moment(dob) : '';
      if (bankDetails) {
        profile.bankDetails.bankName = bankDetails.name || '';
        profile.bankDetails.branchCode = bankDetails.branch_code || '';
        profile.bankDetails.accountNumber = bankDetails.account_number || '';
        profile.bankDetails.accountType = bankDetails.account_type || '';
      }


      if (!driverProfile.is_phone_confirmed && !this.state.otpSent) {
        this.sendOTP();
        this.setState({ otpSent: true });
      }

      if (driverProfile.type !== 'APPLICATION') {
        this.setCompleted();
      }
      // Validation for old data
      let isValid = false;
      if (profile.id !== '') {
        isValid = true;
      }
      this.setState({
        profile,
        idValid: isValid,
        dobValid: isValid,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.otpTimer);
  }

  uploadResponse =(data) => {
    console.log(data);
    if (!data === 'LIMIT_REACHED' || !data === 'FAILURE' || data === 'ACCEPTED') {
      NotificationManager.success('Uploading was successful', 'Complete');
    } else {
      NotificationManager.warning(`Uploading was unsuccessful, please contact Boxel administration at hello@boxel.co.za [${data}]`, 'Warning');
    }
  }

  next = async () => {
    await this.updateDriverProfileStep();
    const { steps, headings } = this.state;
    const activeIndex = steps.findIndex(step => step.active);
    if (activeIndex >= 0 && activeIndex < steps.length - 1) {
      if (activeIndex + 1 === 4) {
        await this.getVehicleModels();
      }
      const activeItem = steps[activeIndex];
      const nextItem = steps[activeIndex + 1];
      steps[activeIndex].active = false;
      steps[activeIndex].complete = true;

      steps[activeIndex + 1].active = true;
      this.setState({ steps });

      // Heading
      if (activeItem.heading !== nextItem.heading) {
        headings[activeItem.heading - 1].expanded = false;
        headings[activeItem.heading - 1].complete = true;

        headings[activeItem.heading].expanded = true;

        this.setState({ headings });
      }
    } else if (activeIndex === steps.length - 1) {
      // Last Step
      const activeItem = steps[activeIndex];
      steps[activeIndex].active = true;
      steps[activeIndex].complete = true;
      headings[activeItem.heading - 1].expanded = false;
      headings[activeItem.heading - 1].complete = true;
      this.setState({ steps });
      this.setState({ headings });
    }
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }

  back = () => {
    const { steps, headings } = this.state;
    const activeIndex = steps.findIndex(step => step.active);

    if (activeIndex > 0) {
      const activeItem = steps[activeIndex];
      const previousItem = steps[activeIndex - 1];

      steps[activeIndex].active = false;
      steps[activeIndex - 1].active = true;
      steps[activeIndex - 1].complete = false;
      this.setState({ steps });

      if (activeItem.heading !== previousItem.heading) {
        headings[activeItem.heading - 1].expanded = false;
        headings[activeItem.heading - 1].complete = false;

        headings[previousItem.heading - 1].expanded = true;
        headings[previousItem.heading - 1].complete = false;

        this.setState({ headings });
      }
    } else if (activeIndex === 0) {
      steps[activeIndex].active = true;
      steps[activeIndex].complete = false;
      this.setState({ steps });
    }
  }

  setCompleted = () => {
    const { steps, headings } = this.state;
    const updatedSteps = steps.slice();
    const updatedHeadings = headings.slice();
    for (let i = 0; i < updatedSteps.length; i += 1) {
      updatedSteps[i].active = false;
      updatedSteps[i].complete = true;
    }

    updatedSteps[updatedSteps.length - 1].active = true;

    for (let i = 0; i < updatedHeadings.length; i += 1) {
      updatedHeadings[i].complete = true;
      updatedHeadings[i].expanded = false;
    }

    this.setState({ steps: updatedSteps, headings: updatedHeadings });
  }

  getVehicleModels = async () => new Promise((resolve) => {
    const { profile, vehicleType } = this.state;
    profile.vehicle.make = '';
    profile.vehicle.model = 'Select vehicle';
    profile.vehicle.year = '';
    this.setState({ profile, loading: true });

    const options = {
      query: GetVehicleModels,
      variables: {
        params: {
          type: vehicleType,
        },
      },
    };

    this.props.client.query(options).then((res) => {
      const vehicleModels = res.data.getVehicleModels.map(item => ({ vehicle: item, name: `${item.make} ${item.model} ${item.model_year}`, value: `${item.make} ${item.model} ${item.model_year}` }));
      this.setState({ vehicleModels, loading: false });
      resolve(true);
    }).catch((err) => {
      console.log(err);
      resolve(true);
    });
  })

  updateDriverProfileStep = () => new Promise((resolve) => {
    const { profile } = this.state;
    const { documents } = profile;
    let options = {};
    switch (true) {
      // First step of form
      case this.state.steps[0].active:
        this.setState({ loading: true });
        let params = {};
        if (profile.picture && !profile.picture.includes('http')) {
          params = {
            name: profile.firstname,
            surname: profile.lastname,
            dateOfBirth: profile.dob,
            idNumber: profile.id,
            picture: profile.picture,
            isFullTimeDriver: profile.fullTime,
          };
        } else {
          params = {
            name: profile.firstname,
            surname: profile.lastname,
            dateOfBirth: profile.dob,
            idNumber: profile.id,
            isFullTimeDriver: profile.fullTime,
          };
        }
        options = {
          mutation: UpdateDriverProfile,
          variables: {
            params,
          },
        };
        this.props.client.mutate(options)
          .then(() => {
            this.setState({ loading: false });
            NotificationManager.success('Section was completed successfully', 'Complete');
            resolve(true);
          })
          .catch((err) => {
            this.setState({ loading: false });
            resolve(true);
          });
        break;
      // Second step of form
      case this.state.steps[1].active:
        this.setState({ loading: true });
        options = {
          mutation: UpdateDriverProfile,
          variables: {
            params: {
              address: {
                street: profile.address.street,
                coordinate: {
                  lat: `${profile.address.coordinates.lat}`,
                  long: `${profile.address.coordinates.long}`,
                },
              },
            },
          },
        };
        this.props.client.mutate(options)
          .then(() => {
            this.setState({ loading: false });
            NotificationManager.success('Section was completed successfully', 'Complete');
            resolve(true);
          })
          .catch((err) => {
            this.setState({ loading: false });
            resolve(true);
          });
        break;
      // Third step of form
      // The backend doesnt store this ?? If I am not mistaken
      case this.state.steps[2].active:
        this.setState({ loading: true });
        options = {
          mutation: UpdateDriverProfile,
          variables: {
            params: {
              bankDetails: {
                bankName: profile.bankDetails.bankName,
                branchCode: profile.bankDetails.branchCode,
                accountNumber: profile.bankDetails.accountNumber,
                accountType: profile.bankDetails.accountType,
              },
            },
          },
        };
        this.props.client.mutate(options)
          .then(() => {
            this.setState({ loading: false });
            NotificationManager.success('Section was completed successfully', 'Complete');
            resolve(true);
          })
          .catch((err) => {
            this.setState({ loading: false });
            resolve(true);
          });
        break;
      // Omitting step 3 since you only set the Vehicle type that is used to determine the list of vehicles
      // returned from the backend
      // six step of form
      case this.state.steps[4].active:
        this.setState({ loading: true });
        options = {
          mutation: UpdateDriverProfile,
          variables: {
            params: {
              make: profile.vehicle.make,
              model: profile.vehicle.model,
              modelYear: parseInt(profile.vehicle.year, 10),
              vehicleDescription: `${profile.vehicle.make} ${profile.vehicle.model} ${profile.vehicle.year}`,
              numberPlate: profile.vehicle.licence,
            },
          },
        };
        this.props.client.mutate(options)
          .then(() => {
            this.setState({ loading: false });
            NotificationManager.success('Section was completed successfully', 'Complete');
            resolve(true);
          })
          .catch((err) => {
            this.setState({ loading: false });
            resolve(true);
          });
        break;
      // seven step of form
      case this.state.steps[6].active:
        const idOptions = {
          mutation: UploadFile,
          variables: {
            content: documents.idDocument,
            type: 'PERSONAL_DOC',
          },
        };
        const driversLicenceOptions = {
          mutation: UploadFile,
          variables: {
            content: documents.driversLicence,
            type: 'PERSONAL_DOC',
          },
        };

        this.props.client.mutate(driversLicenceOptions).then(res => this.uploadResponse(res.data.uploadFile)).catch(err => NotificationManager.warning('Warning message', err.message, 3000));
        this.props.client.mutate(idOptions).then(res => this.uploadResponse(res.data.uploadFile)).catch(err => NotificationManager.warning('Warning message', err.message, 3000));
        resolve(true);
        break;
      // eight step of form
      case this.state.steps[7].active:
        // Vehicle Licence
        const vehicleLicenceOptions = {
          mutation: UploadFile,
          variables: {
            content: documents.vehicleLicence,
            type: 'VEHICLE_DOC',
          },
        };

        // Vehicle Registration
        const registrationOptions = {
          mutation: UploadFile,
          variables: {
            content: documents.registration,
            type: 'VEHICLE_DOC',
          },
        };

        this.props.client.mutate(registrationOptions).then(res => this.uploadResponse(res.data.uploadFile)).catch(err => NotificationManager.warning('Warning message', err.message, 3000));
        this.props.client.mutate(vehicleLicenceOptions).then(res => this.uploadResponse(res.data.uploadFile)).catch(err => NotificationManager.warning('Warning message', err.message, 3000));
        resolve(true);
        break;
      case this.state.steps[8].active:
        // Vehicle Licence
        const leftImg = {
          mutation: UploadFile,
          variables: {
            content: profile.vehicle.pictures.left,
            type: 'VEHICLE_IMG',
          },
        };

        const rightImg = {
          mutation: UploadFile,
          variables: {
            content: profile.vehicle.pictures.right,
            type: 'VEHICLE_IMG',
          },
        };

        const frontImg = {
          mutation: UploadFile,
          variables: {
            content: profile.vehicle.pictures.front,
            type: 'VEHICLE_IMG',
          },
        };

        const backImg = {
          mutation: UploadFile,
          variables: {
            content: profile.vehicle.pictures.back,
            type: 'VEHICLE_IMG',
          },
        };

        this.props.client.mutate(leftImg).then(res => this.uploadResponse(res.data.uploadFile)).catch(err => NotificationManager.warning('Warning message', err.message, 3000));
        this.props.client.mutate(rightImg).then(res => this.uploadResponse(res.data.uploadFile)).catch(err => NotificationManager.warning('Warning message', err.message, 3000));
        this.props.client.mutate(backImg).then(res => this.uploadResponse(res.data.uploadFile)).catch(err => NotificationManager.warning('Warning message', err.message, 3000));
        this.props.client.mutate(frontImg).then(res => this.uploadResponse(res.data.uploadFile)).catch(err => NotificationManager.warning('Warning message', err.message, 3000));
        resolve(true);
        break;
      case this.state.steps[9].active:
        // Bank statement
        const bankOptions = {
          mutation: UploadFile,
          variables: {
            content: documents.bankStatement,
            type: 'LEGAL_DOC',
          },
        };

        // Police Clearance
        const policeOptions = {
          mutation: UploadFile,
          variables: {
            content: documents.policeClearance,
            type: 'LEGAL_DOC',
          },
        };
        this.props.client.mutate(bankOptions).then(res => this.uploadResponse(res.data.uploadFile)).catch(err => NotificationManager.warning('Warning message', err.message, 3000));
        this.props.client.mutate(policeOptions).then(res => this.uploadResponse(res.data.uploadFile)).catch(err => NotificationManager.warning('Warning message', err.message, 3000));
        resolve(true);
        break;
      default:
        resolve(true);
        break;
    }
  })

  startOTPTimer = () => {
    this.setState({ otpTimer: 60 }, () => {
      this.otpTimer = setInterval(() => {
        if (this.state.otpTimer === 0) {
          clearInterval(this.otpTimer);
        } else {
          this.setState({ otpTimer: this.state.otpTimer - 1 });
        }
      }, 1000);
    });
  }

  sendOTP = () => {
    // SEND OTP HERE
    const options = {
      query: RequestOtp,
      fetchPolicy: 'network-only',
    };

    this.props.client.query(options)
      .then((response) => {
        console.log(response);
        if (response.data.requestOtp === 'ACCEPTED') {
          this.startOTPTimer();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  submitOtp = () => {
    this.setState({ otpLoading: true });
    const options = {
      mutation: ConfirmOtp,
      fetchPolicy: 'no-cache',
      variables: {
        otp: this.state.otp,
      },
    };
    this.props.client.mutate(options)
      .then((response) => {
        const state = response.data.confirmOtp;
        if (state === 'SUCCESS') {
          this.setState({ phoneConfirmed: true });
        } else {
          this.setState({
            otpLoading: false,
            otp: '',
          });
          console.log('Invalid OTP');
          this.otp.clearOtp();
        }
      })
      .catch((err) => {
        this.setState({
          otpLoading: false,
          otp: '',
        });
        console.log(err);
        this.otp.clearOtp();
      });
  }

  render() {
    if (this.props.error || this.props.loading || !this.props.profiles || (this.props.profiles && this.props.profiles.length === 0)) {
      return (
        <div className="signup">
          <Header />
          <div className="body" style={{ textAlign: 'center' }}>
            <Parallax
              blur={5}
              bgImage={background}
              bgImageAlt="Background"
              strength={500}
            >
              <div className="background-content">
                <p className="text-bold">Driver Application Process</p>
              </div>
            </Parallax>
            <Squares color="#506290" />
          </div>
          <Footer />
        </div>
      );
    }

    const [driverProfile] = this.props.profiles;
    if (!driverProfile.is_phone_confirmed && !this.state.phoneConfirmed) {
      return (
        <div className="signup">
          <Header />
          <div className="body">
            <Parallax
              blur={5}
              bgImage={background}
              bgImageAlt="Background"
              strength={500}
            >
              <div className="background-content">
                <p className="text-bold" >Driver Application Process</p>
              </div>
            </Parallax>
            <div className="otp-container">
              <div className="card">
                <div className="card-content">
                  <p className="heading text-bold">Verify phone number</p>
                  <p className="text-content text">Please type the verification code sent to: </p>
                  <p className="text-content text">{this.state.profile.cellphone}</p>

                  <div className="otp-container-cont">
                    <OTP
                      ref={(ref) => { this.otp = ref; }}
                      onChange={(otp) => {
                       this.setState({ otp });
                      }}
                    />
                    <p className="otp-text text" >Please wait {convertSeconds(this.state.otpTimer)}</p>
                    <Button
                      text="NEXT"
                      onClick={this.submitOtp}
                      loading={this.state.otpLoading}
                      disabled={(() => this.state.otp === '' || this.state.otp.length < 4)()}
                    />
                  </div>
                  <Button
                    primary
                    text="RESEND"
                    onClick={this.sendOTP}
                    disabled={(() => this.state.otpTimer > 0)()}
                  />
                </div>
              </div>

            </div>
          </div>
          <Footer />
        </div>
      );
    }

    const {
      profile,
      rawDocuments,
      vehicleModels,
      vehicleType,
      vehicleTypes,
    } = this.state;
    return (
      <div className="signup">
        <Header />
        <div className="body">
          <Parallax
            blur={5}
            bgImage={background}
            bgImageAlt="Background"
            strength={500}
          >
            <div className="background-content">
              <p className="text-bold" >Driver Application Process</p>
            </div>
          </Parallax>
          <Wizard
            headings={this.state.headings}
            steps={this.state.steps}
          >
            {/* Personal Details */}
            {this.state.steps[0].active &&
            <div className="card half-card">
              <div className="card-content">
                <p className="heading text-bold">Personal Details</p>
                <p className="text-content text">Hey stranger, let’s get to</p>
                <p className="text-content text">know you a bit better.</p>
                <div className="svg-container">
                  <CourierInfoSvg />
                </div>
              </div>
              <div className="card-content">

                <div className="card-input">
                  <Input
                    heading="FIRST NAME"
                    placeholder="First Name"
                    onChange={(value) => {
                      profile.firstname = value;
                      this.setState({ profile });
                    }}
                    value={profile.firstname}
                  />
                </div>
                <div className="card-input">
                  <Input
                    heading="LAST NAME"
                    placeholder="Last Name"
                    onChange={(value) => {
                      profile.lastname = value;
                      this.setState({ profile });
                    }}
                    value={profile.lastname}
                  />
                </div>
                <div className="card-input">
                  <Input
                    heading="ID/PASSPORT NUMBER"
                    placeholder="ID Number"
                    onChange={(value, invalid) => {
                      profile.id = value;
                      if (value !== '' && value.length > 4) {
                        const DOBvalue = value.substr(0, 6);
                        const pDOBvalue = moment(DOBvalue, 'YYMMDD').format('YYYY-MM-DD');
                        profile.dob = pDOBvalue;
                      }

                      this.setState({ profile, idValid: !invalid, dobValid: !invalid });
                    }}
                    maxLength="13"
                    error="Enter a valid ID number"
                    value={profile.id}
                  />
                </div>
                <div
                  className="card-input"
                  style={{
                    backgroundColor: '#F5F5F5', padding: '20px', borderRadius: '10px', width: '100%',
                  }}
                >
                  <label htmlFor="fullTime" style={{ fontSize: '14px', color: '#757C89' }}>
                    FULL TIME DRIVER:&nbsp;
                    <input
                      name="fullTime"
                      type="checkbox"
                      checked={profile.fullTime}
                      onChange={(event) => {
                        profile.fullTime = event.target.checked;
                        this.setState({ profile });
                      }}
                    />
                  </label>
                </div>

                <div className="card-input">
                  { profile.picture === null ? <Dropzone
                    onChange={async (file) => {
                      profile.picture = await getBase64(file);
                      this.setState({ profile });
                    }}
                    heading="PROFILE PICTURE"
                    icon={<PictureUploadSvg />}
                  />
                :
                  <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  position: 'relative',
                  backgroundColor: '#f5f5f5',
                  borderRadius: 10,
                  }}
                  >
                    <p style={{ fontSize: 14, color: 'rgb(117, 124, 137)' }}>Profile Picture</p>
                    <div
                      onClick={() => {
                      profile.picture = null;
                      this.setState({ profile });
                    }}
                      style={{ position: 'absolute', right: 0, cursor: 'pointer' }}
                    >
                      <svg width="25px" viewBox="0 0 44 44">
                        <path fill="#506290" d="m22,0c-12.2,0-22,9.8-22,22s9.8,22 22,22 22-9.8 22-22-9.8-22-22-22zm3.2,22.4l7.5,7.5c0.2,0.2 0.3,0.5 0.3,0.7s-0.1,0.5-0.3,0.7l-1.4,1.4c-0.2,0.2-0.5,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.5-7.5c-0.2-0.2-0.5-0.2-0.7,0l-7.5,7.5c-0.2,0.2-0.5,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-1.4-1.4c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l7.5-7.5c0.2-0.2 0.2-0.5 0-0.7l-7.5-7.5c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.2-0.2 0.5-0.3 0.7-0.3s0.5,0.1 0.7,0.3l7.5,7.5c0.2,0.2 0.5,0.2 0.7,0l7.5-7.5c0.2-0.2 0.5-0.3 0.7-0.3 0.3,0 0.5,0.1 0.7,0.3l1.4,1.4c0.2,0.2 0.3,0.5 0.3,0.7s-0.1,0.5-0.3,0.7l-7.5,7.5c-0.2,0.1-0.2,0.5 3.55271e-15,0.7z" />
                      </svg>
                    </div>
                    <img
                      style={{
                        maxHeight: 150, maxWidth: '100%', width: 'auto', height: 'auto', alignSelf: 'center',
                      }}
                      alt="Driver"
                      src={profile.picture}
                    />
                  </div>
                }
                </div>

                <div className="button-container">
                  <Button
                    text="BACK"
                    onClick={this.back}
                    primary
                  />
                  <Button
                    text="NEXT"
                    loading={this.state.loading}
                    onClick={this.next}
                    disabled={(() => {
                      const {
                        firstname, lastname, id, picture,
                      } = profile;
                      return firstname === '' || lastname === '' || id === '' || picture === null || !this.state.idValid || !this.state.dobValid;
                    })()}
                  />
                </div>

              </div>
            </div>
          }
            {/* Contact Information */}
            {this.state.steps[1] &&
            <div className="card half-card">
              <div className="card-content">
                <p className="heading text-bold">Contact Information</p>
                <p className="text-content text">Don’t be shy! As part of the Boxel</p>
                <p className="text-content text">team, we’ll need to keep in contact.</p>
                <div className="svg-container">
                  <ContactSvg />
                </div>
              </div>
              <div className="card-content">
                <div className="card-input">
                  <Input
                    heading="CELLPHONE NUMBER"
                    placeholder="Cellphone Number"
                    value={profile.cellphone}
                    disabled
                  />
                </div>
                <div className="card-input">
                  <Input
                    heading="EMAIL"
                    placeholder="Email"
                    value={profile.email}
                    disabled
                  />
                </div>
                <div className="card-input custom-div-w100">
                  <GoogleAutocompleteInput
                    heading="Address"
                    value={profile.address.street}
                    onAddressChanged={(updatedAddress) => {
                      console.log('Address is being changed', updatedAddress);
                      profile.address = updatedAddress;
                      this.setState({ profile });
                    }}
                  />
                </div>

                <div className="button-container">
                  <Button
                    text="BACK"
                    onClick={this.back}
                    primary
                  />
                  <Button
                    text="NEXT"
                    onClick={this.next}
                    loading={this.state.loading}
                    disabled={!profile.address.street || !profile.address.coordinates}
                  />
                </div>
              </div>
            </div>
            }
            {/* Banking Details */}
            {this.state.steps[2].active &&
            <div className="card half-card">
              <div className="card-content">
                <p className="heading text-bold">Banking Details</p>
                <p className="text-content text">We’ll need your account details so we can</p>
                <p className="text-content text">pay you for the deliveries you complete.</p>
                <div className="svg-container">
                  <BankingSvg />
                </div>
              </div>
              <div className="card-content">
                <div className="card-input">
                  <Input
                    heading="BANK"
                    placeholder="Bank Name"
                    onChange={(value) => {
                      profile.bankDetails.bankName = value.name;
                      this.setState({ profile });
                    }}
                    value={profile.bankDetails.bankName}
                    dropdown={[
                      {
                        name: 'FNB',
                        value: 'FNB',
                      },
                      {
                        name: 'ABSA',
                        value: 'ABSA',
                      },
                      {
                        name: 'Nedbank',
                        value: 'Nedbank',
                      },
                      {
                        name: 'Capitec',
                        value: 'Capitec',
                      },
                      {
                        name: 'Standard Bank',
                        value: 'Standard Bank',
                      },
                      {
                        name: 'Other',
                        value: 'Other',
                      },
                    ]}
                  />
                </div>
                <div className="card-input">
                  <Input
                    heading="BRANCH CODE"
                    placeholder="Branch Code"
                    onChange={(value) => {
                      profile.bankDetails.branchCode = value;
                      this.setState({ profile });
                    }}
                    value={profile.bankDetails.branchCode}
                  />
                </div>
                <div className="card-input">
                  <Input
                    heading="ACCOUNT NO."
                    placeholder="Account Number"
                    onChange={(value) => {
                      profile.bankDetails.accountNumber = value;
                      this.setState({ profile });
                    }}
                    value={profile.bankDetails.accountNumber}
                  />
                </div>
                <div className="card-input">
                  <Input
                    heading="ACCOUNT TYPE"
                    placeholder="Account Type"
                    onChange={(value) => {
                      profile.bankDetails.accountType = value.name;
                      this.setState({ profile });
                    }}
                    value={profile.bankDetails.accountType}
                    dropdown={[
                      {
                        name: 'SAVINGS',
                        value: 'Savings',
                      },
                      {
                        name: 'CHEQUE',
                        value: 'Cheque',
                      },
                    ]}
                  />
                </div>

                <div className="button-container">
                  <Button
                    text="BACK"
                    onClick={this.back}
                    primary
                  />
                  <Button
                    text="NEXT"
                    onClick={this.next}
                    loading={this.state.loading}
                    disabled={(() => {
                      const {
                        bankDetails,
                      } = profile;
                      return bankDetails.bankName === '' || bankDetails.branchCode === '' || bankDetails.accountNumber === '' || bankDetails.accountType === '';
                    })()}
                  />
                </div>
              </div>
            </div>
            }
            {/* Vehicle Type */}
            {this.state.steps[3].active &&
            <div className="card">
              <div className="card-content">
                <p className="heading text-bold">What vehicle type will you be using?</p>
                <p className="text-content text">This will impact the maximum amount you can</p>
                <p className="text-content text">carry as well as the type of deliveries you can do.</p>
                <div className="vehicle-type-container">
                  { vehicleTypes.map((vType) => {
                    const isSelected = vehicleType === vType.value;
                    return (
                      <button
                        key={vType.value}
                        className={isSelected ? 'vehicle-type selected text' : 'vehicle-type text'}
                        onClick={() => this.setState({ vehicleType: vType.value })}
                      >
                        <div className="icon-container">
                          {vType.icon}
                        </div>
                        {vType.name}
                      </button>
                    );
                  })}
                  <div style={{ width: '155px', height: '165px', margin: '5px' }} />
                </div>

                <div className="button-container full">
                  <Button
                    text="BACK"
                    onClick={this.back}
                    primary
                  />
                  <Button
                    text="NEXT"
                    onClick={() => {
                      this.next();
                    }}
                    loading={this.state.loading}
                    disabled={!vehicleType}
                  />
                </div>
              </div>
            </div>
            }
            { /* Vehicle Details */ }
            {this.state.steps[4].active &&
            <div className="card half-card">
              <div className="card-content">
                <p className="heading text-bold">Vehicle Details</p>
                <p className="text-content text">Please provide the following</p>
                <p className="text-content text">details for your vehicle.</p>
                <div className="svg-container">
                  <VehicleDetailsSvg />
                </div>
              </div>
              <div className="card-content">
                <div className="card-input">
                  <Input
                    heading="VEHICLE MAKE & MODEL"
                    placeholder="Make & Model"
                    onChange={(value) => {
                      profile.vehicle.make = value.vehicle.make;
                      profile.vehicle.model = value.vehicle.model;
                      profile.vehicle.year = value.vehicle.model_year;
                      this.setState({ profile });
                    }}
                    value={`${profile.vehicle.make} ${profile.vehicle.model} ${profile.vehicle.year}`}
                    dropdown={vehicleModels} // Name, Value
                  />
                </div>
                <div className="card-input">
                  <Input
                    heading="LICENCE PLATE NUMBER"
                    placeholder="Licence Plate Number"
                    onChange={(value) => {
                      profile.vehicle.licence = value;
                      this.setState({ profile });
                    }}
                    value={profile.vehicle.licence}
                  />
                </div>

                <div className="button-container">
                  <Button
                    text="BACK"
                    onClick={this.back}
                    primary
                  />
                  <Button
                    text="NEXT"
                    onClick={this.next}
                    loading={this.state.loading}
                    disabled={!profile.vehicle.licence || !profile.vehicle.make || !profile.vehicle.year || !profile.vehicle.model}
                  />
                </div>
              </div>
            </div>
            }
            {/* Legal */}
            {this.state.steps[5].active &&
            <div className="card">
              <div className="card-content">
                <p className="heading text-bold">Legal</p>
                <p className="text-content text">By continuing you agree to the following terms and conditions</p>
                <p className="text-content text">of using the Boxel app and being associated with its brand.</p>

                <div className="legal">
                  <p className="legal-text text">Boxel (Pty) Ltd is the owner of the intellectual Property called Boxel.  By downloading or using the Boxel platform (which includes the website, application, and computer software), these terms will automatically apply to you. Please ensure that you read them before using the Application. You are not allowed to copy, or modify the Application, any part of the Application, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the Application, and you also shouldn’t try to translate the Application into other languages, or make derivative versions thereof.
                  All persons who register as a Driver on the agree to the following terms and conditions:
                  </p>

                  <p className="legal-heading text-bold">1. THIRD PARTY SERVICE PROVIDERS</p>
                  <ul className="legal-list">
                    <p className="list-item text"><b>1.1  </b>Every person who register as a Driver on the Boxel platform accepts and agree that he/she is an independent third party service provider (herein after referred to as “Driver”) and no Driver shall become an employee, contractor, sub contractor, agent or partner of Boxel. </p>
                    <p className="list-item text"><b>1.2  </b>You may use the Services only if you agree to form a binding contract with us and are not a person barred from receiving services under the laws of the applicable jurisdiction.</p>
                    <p className="list-item text"><b>1.3  </b>The Boxel platform only arranges and schedule deliveries and administer price and payments between the Drivers and clients.</p>
                  </ul>

                  <p className="legal-heading text-bold">2. PAYMENTS AND PRICING</p>
                  <ul className="legal-list">
                    <p className="list-item text"><b>2.1  </b>The Driver shall receive payment for his deliveries on a weekly basis.</p>
                    <p className="list-item text"><b>2.2  </b>Boxel has the exclusive right and may at its own discretion and without prior notice change the pricing structure for any delivery as it deems fit.</p>
                    <p className="list-item text"><b>2.3  </b>Boxel has the right to deduct any percentage and/or amount which it deems fit at its own discretion from any amount due to a Driver.</p>
                  </ul>

                  <p className="legal-heading text-bold">3. CANCELLATION</p>
                  <ul className="legal-list">
                    <p className="list-item text">Boxel may at any time summarily cancel this agreement without providing any reason or any notice. The agreement may also be cancelled by  mutual consent. </p>
                  </ul>

                  <p className="legal-heading text-bold">4. INDEMNITY AND WARRANTIES</p>
                  <ul className="legal-list">
                    <p className="list-item text"><b>4.1  </b>The Driver exempt, indemnifies and hold Boxel harmless:</p>
                    <p className="list-item text"><b>4.1.1  </b>against any claim whatsoever for any loss or destruction of any goods transported by the Driver;</p>
                    <p className="list-item text"><b>4.1.2  </b>against any other claim which may arise out of the use of the Boxel platform.</p>
                    <p className="list-item text"><b>4.2.1  </b>Boxel may at its own discretion refund any client any amount which it deems fit for any cancellation, loss, damage or any delivery by a Driver.</p>
                    <p className="list-item text"><b>4.2.2  </b>Boxel may at its own discretion deduct such an amount equal to the amount refunded to the client from the amount due to the Driver.</p>
                  </ul>

                  <p className="legal-heading text-bold">5. INSURANCE </p>
                  <ul className="legal-list">
                    <p className="list-item text"><b>5.1  </b>Each Driver is responsible for his own insurance including but not limited to personal, vehicle and goods in transit insurance where applicable.</p>
                    <p className="list-item text"><b>5.2  </b>Should Boxel provide an insurance option for Clients, the Driver shall familiarize him/herself with all the terms and conditions  of the Boxel’s insurance platform and agree and undertake to fully adhere to all the stipulated terms and conditions.</p>
                  </ul>

                  <p className="legal-heading text-bold">6. MAINTENANCE</p>
                  <ul className="legal-list">
                    <p className="list-item text"><b>6.1  </b>The Driver shall keep the vehicle (bicycle, scooter, motor vehicle) in good working order and maintain it to respectable standard.</p>
                    <p className="list-item text"><b>6.2  </b>The Driver shall be responsible for the maintenance and operating of the vehicle including but not limited to:</p>
                    <p className="list-item text"><b>a) </b>fuel</p>
                    <p className="list-item text"><b>b)  </b>oils</p>
                    <p className="list-item text"><b>c) </b>lubricants</p>
                    <p className="list-item text"><b>d) </b>repairs</p>
                    <p className="list-item text"><b>e) </b>tires exc. </p>
                    <p className="list-item text"><b>f) </b>Mechanical</p>
                    <p className="list-item text"><b>6.3  </b>Boxel may at any time request an inspection of the vehicle. </p>
                  </ul>

                  <p className="legal-heading text-bold">7. 	CARE</p>
                  <ul className="legal-list">
                    <p className="list-item text"><b>7.1  </b>The Driver shall exercise all reasonable care and keep safe the goods/parcel in its possession when doing deliveries. </p>
                    <p className="list-item text"><b>7.2  </b>The Driver shall report any loss and/or damage of any goods/parcels which belongs to the client, immediately to Boxel and the client. </p>
                  </ul>

                  <p className="legal-heading text-bold">8.	NON DISCLOSURE</p>
                  <ul className="legal-list">
                    <p className="list-item text"><b>8.1  </b>The Driver may not use or disclose to anyone any confidential information regarding Boxel. Such confidential information shall include all non-public information the Driver may acquired as a result of his or her using the Boxel platform which might be of any value to a competitor of Boxel, or which might cause any economic loss or substantial embarrassment to Boxel or its customers, if used or disclosed. Examples of such confidential information include, without limitation, non-public information about Boxel customers, suppliers, distributors and potential acquisition targets; its business operations and structure; its product lines, formulas and pricing; its processes; its research and know-how; its financial data; and its plans and strategies, its platform, website, application, computer software and/or any other information concerning Boxel.</p>
                    <p className="list-item text"><b>8.2  </b>All information which a Driver acquires from clients as a result of the use of the Boxel platform shall be regarded as confidential and  may not for whatever reason be disclosed to anyone, the information shall include but is not limited to any contact details, addresses, telephone numbers of such clients and/or their contacts. </p>
                  </ul>

                  <p className="legal-heading text-bold">9.	SCREENING & BACKGROUND CHECKS</p>
                  <ul className="legal-list">
                    <p className="list-item text">The Driver herewith consents and agree that Boxel at any time may do a background check on him or her which shall include, but is not limited to ITC, criminal and financial checks, social media and other background checks. The Driver herewith irrevocably consents to such screening and checks.  The Driver authorize and consents and give Boxel authority to access any information, be it personal and/or financial and from whom Boxel deems fit. The Driver shall immediately avail himself on request from Boxel to do a polygraph test.</p>
                  </ul>


                  <p className="legal-heading text-bold">10. 	VARIATION</p>
                  <ul className="legal-list">
                    <p className="list-item text">Boxel may, in its sole discretion, change the terms and conditions of this agreement or any part thereof at any time without notice to the Driver and same shall be binding on the Driver whether or not he/she accepted same.</p>
                  </ul>

                  <p className="legal-heading text-bold">11.	GENERAL RULES & GUIDLINES</p>
                  <ul className="legal-list">
                    <p className="list-item text">To make all the deliveries memorable, safe and efficient for the clients, the following rules shall apply to all Drivers:</p>
                    <p className="list-item text" style={{ textDecorationLine: 'underline' }}>Respect</p>
                    <p className="list-item text">Treat all clients (incl. senders, recipients) and fellow Drivers with respect. Always be friendly and courteous. Remember the client is entrusting you with his property.  Always look after the clients property.</p>
                    <p className="list-item text">Always be professional. </p>
                    <p className="list-item text" style={{ textDecorationLine: 'underline' }}>Quality</p>
                    <p className="list-item text">Give the clients the quality, efficient, professional service, which they deserve. This will be measured through the ratings of the Driver and the cancellation rate.</p>
                    <p className="list-item text" style={{ textDecorationLine: 'underline' }}>Ratings</p>
                    <p className="list-item text">After every delivery the client shall rate the Driver and give feedback of the delivery which will reflect on the Driver application.  The Driver rating is based on an average of all ratings given to the Driver for all deliveries made by him/her (rated from 1 to 5 stars).  Drivers might receive certain incentives depending on their ratings. Should a Driver’s rating go down, the Driver might lose access to the Application.</p>
                    <p className="list-item text" style={{ textDecorationLine: 'underline' }}>Cancellations</p>
                    <p className="list-item text">All cancellations from Drivers reflect negatively on the service rendered by Boxel and should be avoided at all costs.  Drivers cancellation rate is calculated as a percentage of deliveries accepted by the Driver which is then subsequently cancelled by the Driver.  The cancellation rate shall be monitored by Boxel.</p>
                    <p className="list-item text">Should the Driver’s cancellation rate be more than the acceptable cancellation rate (as set by Boxel) the Driver might lose access to this application.</p>
                    <p className="list-item text">Drivers whom consistently decline deliveries may also be denied access to the application. </p>
                    <p className="list-item text" style={{ textDecorationLine: 'underline' }}>Safety</p>
                    <p className="list-item text">Safety first.  All deliveries are tracked and all actions by Drivers may be investigated.  A Driver breaches any safety or other rules, same may lead to a Driver’s access to the application be denied.  </p>
                    <p className="list-item text">No Driver may carry a firearm while using the application. Only an allocated Driver may do a delivery for a client, nobody may do a delivery on behalf of a Driver. </p>
                    <p className="list-item text" style={{ textDecorationLine: 'underline' }}>Compliance with Rules & Regulations</p>
                    <p className="list-item text">A Driver must comply with all the laws, rules and regulations applicable, especially all road safety rules and regulations. </p>
                    <p className="list-item text">All Drivers must ensure that where required, they have a valid Driver’s license and maintain a valid vehicle registration. No illegal activity will be tolerated. </p>
                    <p className="list-item text" style={{ textDecorationLine: 'underline' }}>Fraud</p>
                    <p className="list-item text">No Driver may accept a delivery without the intention of completing it; </p>
                    <p className="list-item text">No Driver may create a fake/dummy delivery/client or Driver accounts with the intention to defrauding Boxel; </p>
                    <p className="list-item text">No Driver may deliberately cancel a delivery without a good reason; </p>
                    <p className="list-item text">Claim fraudulent fees and charges. </p>
                    <p className="list-item text" style={{ textDecorationLine: 'underline' }}>Personal info</p>
                    <p className="list-item text">All Drivers must ensure that all their personal information is accurate, true and up to date, this includes but is not limited to the following: </p>
                    <ul>
                      <li className="list-item text">ID</li>
                      <li className="list-item text">Photo</li>
                      <li className="list-item text">Vehicle model/colour</li>
                      <li className="list-item text">License plate</li>
                      <li className="list-item text">Vehicle Registration</li>
                    </ul>
                  </ul>

                </div>

                <div className="button-container full">
                  <Button
                    text="BACK"
                    onClick={this.back}
                    primary
                  />
                  <Button
                    text="ACCEPT"
                    onClick={this.next}
                    loading={this.state.loading}
                  />
                </div>
              </div>
            </div>
            }
            {/* Personal Documents */}
            {this.state.steps[6].active &&
            <div className="card half-card">
              <div className="card-content">
                <p className="heading text-bold">Personal Documents</p>
                <p className="text-content text">Please upload certified copies</p>
                <p className="text-content text">of  the following documents.</p>
                <br />
                <p className="text-content text">Documents should not exceed 2mb</p>
                <p className="text-content text">in size and must be in one of the</p>
                <p className="text-content text">following formats: .pdf, .jpg or .png</p>

                <div className="svg-container">
                  <PersonalDocumentsSvg />
                </div>
              </div>
              <div className="card-content">
                <div className="card-input">
                  <Dropzone
                    file={rawDocuments.idDocument}
                    onChange={async (file) => {
                      profile.documents.idDocument = await getBase64(file);
                      rawDocuments.idDocument = file;
                      this.setState({ profile, rawDocuments });
                    }}
                    heading="ID DOCUMENT"
                    icon={<IdSvg />}
                  />
                </div>

                <div className="card-input">
                  <Dropzone
                    file={rawDocuments.driversLicence}
                    onChange={async (file) => {
                      profile.documents.driversLicence = await getBase64(file);
                      rawDocuments.driversLicence = file;
                      this.setState({ profile, rawDocuments });
                    }}
                    heading="DRIVER'S LICENCE"
                    icon={<DriversSvg />}
                  />
                </div>

                <div className="button-container">
                  <Button
                    text="BACK"
                    onClick={this.back}
                    primary
                  />
                  <Button
                    text="NEXT"
                    onClick={this.next}
                    loading={this.state.loading}
                    disabled={(() => {
                      const {
                        documents,
                      } = profile;
                      return documents.idDocument === null || documents.driversLicence === null;
                    })()}
                  />
                </div>
              </div>
            </div>
            }
            {/* Vehicle Document */}
            {this.state.steps[7].active &&
            <div className="card half-card">
              <div className="card-content">
                <p className="heading text-bold">Vehicle Documents</p>
                <p className="text-content text">Please upload certified copies</p>
                <p className="text-content text">of  the following documents.</p>
                <br />
                <p className="text-content text">Documents should not exceed 2mb</p>
                <p className="text-content text">in size and must be in one of the</p>
                <p className="text-content text">following formats: .pdf, .jpg or .png</p>
                <div className="svg-container">
                  <VehicleDocumentsSvg />
                </div>
              </div>
              <div className="card-content">
                <div className="card-input">
                  <Dropzone
                    file={rawDocuments.vehicleLicence}
                    onChange={async (file) => {
                      profile.documents.vehicleLicence = await getBase64(file);
                      rawDocuments.vehicleLicence = file;
                      this.setState({ profile, rawDocuments });
                    }}
                    heading="VEHICLE LICENCE"
                    icon={<VehicleLicenceSvg />}
                  />
                </div>

                <div className="card-input">
                  <Dropzone
                    file={rawDocuments.registration}
                    onChange={async (file) => {
                      profile.documents.registration = await getBase64(file);
                      rawDocuments.registration = file;
                      this.setState({ profile, rawDocuments });
                    }}
                    heading="MOTOR VEHICLE CERTIFICATE OF REGISTRATION"
                    icon={<VehicleRegistrationSvg />}
                  />
                </div>

                <div className="button-container">
                  <Button
                    text="BACK"
                    onClick={this.back}
                    primary
                  />
                  <Button
                    text="NEXT"
                    onClick={this.next}
                    loading={this.state.loading}
                    disabled={(() => {
                      const {
                        documents,
                      } = profile;
                      return documents.vehicleLicence === null || documents.registration === null;
                    })()}
                  />
                </div>
              </div>
            </div>
            }
            {/* Photos of Vehicle */}
            {this.state.steps[8].active &&
            <div className="card half-card">
              <div className="card-content">
                <p className="heading text-bold">Photos of Vehicle</p>
                <p className="text-content text">Please upload certified copies</p>
                <p className="text-content text">of the following photos.</p>
                <br />
                <p className="text-content text">Photos should not exceed 2mb</p>
                <p className="text-content text">in size and must be in one of the</p>
                <p className="text-content text">following formats: .pdf, .jpg or .png</p>
                <div className="svg-container">
                  <VehiclePhotosSvg />
                </div>
              </div>
              <div className="card-content">
                <div className="card-input">
                  <Dropzone
                    file={rawDocuments.front}
                    onChange={async (file) => {
                      profile.vehicle.pictures.front = await getBase64(file);
                      rawDocuments.front = file;
                      this.setState({ profile });
                    }}
                    heading="FRONT OF VEHICLE"
                    style={{ width: 'calc(50% - 10px)' }}
                    icon={<FrontCarSvg />}
                  />
                  <Dropzone
                    file={rawDocuments.back}
                    onChange={async (file) => {
                      profile.vehicle.pictures.back = await getBase64(file);
                      rawDocuments.back = file;
                      this.setState({ profile, rawDocuments });
                    }}
                    heading="BACK OF VEHICLE"
                    style={{ width: 'calc(50% - 10px)' }}
                    icon={<BackCarSvg />}
                  />
                </div>
                <div className="card-input">
                  <Dropzone
                    file={rawDocuments.left}
                    onChange={async (file) => {
                      profile.vehicle.pictures.left = await getBase64(file);
                      rawDocuments.left = file;
                      this.setState({ profile });
                    }}
                    heading="LEFT SIDE OF VEHICLE"
                    style={{ width: 'calc(50% - 10px)' }}
                    icon={<LeftCarSvg />}
                  />
                  <Dropzone
                    file={rawDocuments.right}
                    onChange={async (file) => {
                      profile.vehicle.pictures.right = await getBase64(file);
                      rawDocuments.right = file;
                      this.setState({ profile });
                    }}
                    heading="RIGHT SIDE OF VEHICLE"
                    style={{ width: 'calc(50% - 10px)' }}
                    icon={<RightCarSvg />}
                  />
                </div>

                <div className="button-container">
                  <Button
                    text="BACK"
                    onClick={this.back}
                    primary
                  />
                  <Button
                    text="NEXT"
                    loading={this.state.loading}
                    onClick={this.next}
                    disabled={!profile.vehicle.pictures.left || !profile.vehicle.pictures.right || !profile.vehicle.pictures.front || !profile.vehicle.pictures.back}
                  />
                </div>
              </div>
            </div>
            }
            {/* Legal Documents */}
            {this.state.steps[9].active &&
            <div className="card half-card">
              <div className="card-content">
                <p className="heading text-bold">Legal Documents</p>
                <p className="text-content text">If available please upload</p>
                <p className="text-content text">of the following documents.</p>
                <br />
                <br />
                <p className="text-content text">These documents are optional </p>
                <p className="text-content text">and speeds up your application.</p>
                <br />
                <br />
                <p className="text-content text">Documents should not exceed 2mb</p>
                <p className="text-content text">in size and must be in one of the</p>
                <p className="text-content text">following formats: .pdf, .jpg or .png</p>
                <div className="svg-container">
                  <LegalSvg />
                </div>
              </div>
              <div className="card-content">
                <div className="card-input">
                  <Dropzone
                    file={rawDocuments.bankStatement}
                    onChange={async (file) => {
                      profile.documents.bankStatement = await getBase64(file);
                      rawDocuments.bankStatement = file;
                      this.setState({ profile, rawDocuments });
                    }}
                    heading="BANK STATEMENT"
                    icon={<BankStatementSvg />}
                  />
                </div>

                <div className="card-input">
                  <Dropzone
                    file={rawDocuments.policeClearance}
                    onChange={async (file) => {
                      profile.documents.policeClearance = await getBase64(file);
                      rawDocuments.policeClearance = file;
                      this.setState({ profile, rawDocuments });
                    }}
                    heading="POLICE CLEARANCE"
                    icon={<PoliceSVG />}
                  />
                </div>

                <div className="button-container">
                  <Button
                    text="BACK"
                    onClick={this.back}
                    primary
                  />
                  <Button
                    text="SUBMIT"
                    loading={this.state.loading}
                    onClick={this.next}
                    disabled={!profile.documents.policeClearance || !profile.documents.bankStatement}
                  />
                </div>
              </div>
            </div>
            }
            {/* Review */}
            <div className="card">
              <div className="card-content">
                <p className="heading text-bold">All Done</p>
                <p className="text-content text">Your application will be reviewed by the</p>
                <p className="text-content text">Boxel team shortly. You will receive</p>
                <p className="text-content text">updates as the process progresses.</p>
                <p className="text-content text">Please watch your email closely.</p>
                <div style={{ width: '290px', marginTop: '35px' }}>
                  <AllDoneSvg />
                </div>
              </div>
            </div>

            {/* Completed */}
            <div className="card">
              <div className="card-content">
                <p className="heading text-bold">You're ready to use Boxel!</p>
                <p className="text-content text">You are now a fully registered Boxel Driver.</p>
                <p className="text-content text">Download and login to the app to get started.</p>
                <div style={{ width: '245px', marginTop: '35px', marginBottom: '35px' }}>
                  <CompletedSvg />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <a href="https://play.google.com/store/apps/details?id=com.boxeldriver" style={{ marginRight: '15px' }}>
                    <img src={googlePlay} alt="Google Play Store" />
                  </a>
                  <a href="https://itunes.apple.com/us/app/boxel-driver/id1438725813?ls=1&mt=8" style={{ marginLeft: '15px' }}>
                    <img src={appleStore} alt="Apple Store" />
                  </a>
                </div>
              </div>
            </div>
          </Wizard>
        </div>
        <Footer />
      </div>
    );
  }
}


export default compose(
  withApollo,
  graphql(GetProfile, {
    options: {
      fetchPolicy: 'network-only',
    },
    props: (response) => {
      const { loading, error, getProfile } = response.data;
      console.log(getProfile);
      return ({
        profiles: getProfile,
        error,
        loading,
      });
    },
  }),
)(SignupPage);

