import * as React from 'react';

const MedalSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 216.736">
    <g transform="translate(-838 -442)">
      <g transform="translate(838 442)">
        <circle fill="#e3f0fc" cx="100" cy="100" r="100" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="3.432" height="3.432" transform="translate(78.965 28.276)" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="4.26" height="4.26" transform="translate(14.675 78.225)" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="1.775" height="1.775" transform="translate(173.055 156.319)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.775" height="1.775" transform="translate(22.959 65.325)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#7791c1" strokeWidth="0.75px" width="1.538" height="1.538" transform="translate(105.207 12.308)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.775" height="1.775" transform="translate(165.452 139.276)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.734" height="4.734" transform="translate(184.161 124.821) rotate(-90)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="5.562" height="5.562" transform="translate(61.448 24.889) rotate(-7.695)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.538" height="1.538" transform="translate(21.183 91.598)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.775" height="1.775" transform="translate(172.308 60.355)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.734" height="4.734" transform="translate(177.263 76.032) rotate(-90)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.734" height="4.734" transform="translate(16.798 120.218) rotate(-90)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.538" height="1.538" transform="translate(89.616 44.37)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="3.669" height="3.669" transform="translate(180.355 135.621)" />
      </g>
      <g transform="matrix(0.999, 0.035, -0.035, 0.999, 878.072, 485.742)">
        <path strokeMiterlimit="10" strokeWidth="2px" fill="#4eb588" stroke="#4b5467" d="M11.528,79.785,35.338,0,80.41,15.14,55.281,97.251l-8.866,30.083L28.856,99.543,0,116.246Z" transform="translate(0 40.512)" />
        <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#7791c1" d="M5.209,82.1,31.483,0,64.564,10.591,40.13,90.191l-5.567,18.288L21.332,86.313,0,99.358Z" transform="translate(10.093 42.734)" />
        <path strokeMiterlimit="10" strokeWidth="2px" fill="#4eb588" stroke="#4b5467" d="M46.507,97.4,0,25.928,40.477,0,86.985,72.585l16.8,26.888L71.891,94.821l-4.9,32.467Z" transform="translate(26.136 36.183)" />
        <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#7791c1" d="M46.507,91.325,0,18.74,29.238,0,74.993,69.576,86.348,86.765,59.784,83.6l-2.732,23.589Z" transform="translate(31.842 39.979)" />
        <ellipse strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#dce2ea" cx="51.658" cy="51.658" rx="51.658" ry="51.658" transform="translate(7.408 0)" />
        <ellipse strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#dce2ea" cx="44.783" cy="44.783" rx="44.783" ry="44.783" transform="translate(14.376 6.725)" />
        <path fill="none" strokeMiterlimit="10" strokeWidth="3px" stroke="#fff" strokeLinecap="round" d="M27.109,0C20.453,1.736-4.988,13.751.868,46.45" transform="translate(21.807 15.348)" />
        <g transform="translate(28.666 22.615)">
          <g opacity="0.5" transform="translate(0 0)">
            <path strokeMiterlimit="10" strokeWidth="2px" fill="#ccd1db" stroke="#111112" d="M30.574,0l9.452,19.136,21.122,3.076L45.865,37.11l3.6,21.041-18.9-9.935-18.9,9.935,3.6-21.041L0,22.213l21.122-3.076Z" />
            <path fill="#969ba5" d="M3.407,6.3,18.037,0,0,26.344Z" transform="translate(12.537 30.681)" />
            <path fill="#969ba5" d="M0,16.892V0L18.108,26.5Z" transform="translate(30.574 30.681)" />
            <path fill="#969ba5" d="M14.63,14.3,0,8.084,29.259,0Z" transform="translate(30.556 22.597)" />
            <path fill="#969ba5" d="M9,18.2,0,29.223V0Z" transform="translate(30.574 1.458)" />
            <path fill="#969ba5" d="M20.2,0l9.157,11.1L0,3.04Z" transform="translate(1.216 19.584)" />
          </g>
        </g>
      </g>
    </g>
  </svg>


);

export default MedalSvg;
