import * as React from 'react';

const TruckSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.657 53.199">
    <g transform="translate(0 0)">
      <g transform="translate(11.737 36.599)">
        <path fill="#ccd1db" d="M55.683,149.7a7.7,7.7,0,0,0-.831,15.353c.293.024.587.049.88.049a7.7,7.7,0,0,0,.831-15.353,5.314,5.314,0,0,0-.88-.049Zm.2,11.491a3.618,3.618,0,1,1,3.618-3.618,3.6,3.6,0,0,1-3.618,3.618Z" transform="translate(-48.008 -149.7)" />
      </g>
      <g transform="translate(63.882 37.283)">
        <path fill="#ccd1db" d="M269.241,152.5a7.958,7.958,0,0,0-.88,15.867c.293.024.611.049.9.049a7.958,7.958,0,0,0,.88-15.867c-.293-.024-.611-.049-.9-.049Zm.22,11.906a3.741,3.741,0,1,1,3.741-3.741,3.741,3.741,0,0,1-3.741,3.741Z" transform="translate(-261.294 -152.5)" />
      </g>
      <g transform="translate(0 0)">
        <path fill="#ccd1db" d="M180.958,34.056h-58.04a1.419,1.419,0,0,1-1.418-1.418V6.8l.049-1.369-.049-2.3C121.5,1.394,123.114,0,125.387,0h52.881a4.1,4.1,0,0,1,4.107,4.107V32.638A1.419,1.419,0,0,1,180.958,34.056Z" transform="translate(-91.796 0)" />
        <path fill="#ccd1db" d="M71.34,60.053m-.513.049a7.524,7.524,0,0,1,2.029-.024H72.9a8.938,8.938,0,0,1,7.995,8.9v.391h8.068c1.76-.66,2.689.122,2.689-1.711a50.707,50.707,0,0,0-.2-5.745L90.6,59.344v-.367H29.827a1.419,1.419,0,0,1-1.418-1.418V31.4a16.646,16.646,0,0,0-6.063.88c-.562.2-7.677,1.736-10.9,3.2A2.648,2.648,0,0,0,9.9,37.512L8.312,47s-5.134.856-6.161,1.516C1.149,49.2,0,55.066,0,57.853,0,59.026.367,68.9,3.056,69.075a67.331,67.331,0,0,0,7.677.293,8.1,8.1,0,0,1,.073-1.711c0-4.792,4.156-8.019,8.777-8.166a8.844,8.844,0,0,1,8.484,9.877H63v-.391a8.985,8.985,0,0,1,1.565-5.061h0A8.978,8.978,0,0,1,70.826,60.1m1.125-.073M9.828,46.46,10.9,39.884a3.244,3.244,0,0,1,2.909-2.714c2.86-.269,5.819-.562,7.506-.562L22.566,37c.9,0,.733,2.2.733,3.08v6.088c0,.758.171,1.711-1.394,1.614H12.42C10.879,47.854,9.8,47.951,9.828,46.46ZM1.467,53.477a12.833,12.833,0,0,1,.807-3.961.477.477,0,0,1,.831-.171,3.043,3.043,0,0,1,.538,2.4c-.171,1.614-.929,2.176-1.516,2.371A.564.564,0,0,1,1.467,53.477Z" transform="translate(0 -23.723)" />
      </g>
    </g>
  </svg>
);

export default TruckSvg;
