import * as React from 'react';

const FlexibleSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 97.857">
    <ellipse fill="#e3f0fc" cx="48.929" cy="48.929" rx="48.929" ry="48.929" />
    <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="1.679" height="1.679" transform="translate(47.771 85.061)" />
    <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="2.085" height="2.085" transform="translate(37.001 15.171)" />
    <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="0.869" height="0.869" transform="translate(77.533 21.598)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="0.869" height="0.869" transform="translate(8.628 45.918)" />
    <rect fill="none" strokeMiterlimit="10" stroke="#7791c1" strokeWidth="0.75px" width="0.753" height="0.753" transform="translate(33.874 11.291)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="0.869" height="0.869" transform="translate(57.267 84.713)" />
    <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="2.316" height="2.316" transform="translate(7.042 56.532) rotate(-90)" />
    <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="1.158" height="1.158" transform="translate(38.889 78.535) rotate(-90)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#fafbfc" width="1.795" height="1.795" transform="translate(41.682 33.512) rotate(180)" />
    <path strokeMiterlimit="10" strokeWidth="2px" fill="#dce2ea" stroke="#4b5467" d="M24.1,72.864V38.237c0-.058,0-.058.058-.058L37.881,34.3c.058,0,.116,0,.116.058V69.042c0,.058,0,.058-.058.058L24.216,72.922C24.158,72.922,24.1,72.922,24.1,72.864Z" transform="translate(-10.145 -14.439)" />
    <path strokeMiterlimit="10" strokeWidth="2px" fill="#dce2ea" stroke="#4b5467" d="M48,69.025V34.34c0-.058.058-.116.116-.058l13.665,4.053a.057.057,0,0,1,.058.058V72.789c0,.058-.058.116-.116.058L48.058,69.025C48.058,69.083,48,69.025,48,69.025Z" transform="translate(-20.206 -14.422)" />
    <path strokeMiterlimit="10" strokeWidth="2px" fill="#dce2ea" stroke="#4b5467" d="M71.9,72.748V38.469a.125.125,0,0,1,.116-.116L85.565,34.3c.058,0,.174.058.174.116V68.984a.125.125,0,0,1-.116.116l-13.55,3.764C72.016,72.922,71.9,72.864,71.9,72.748Z" transform="translate(-30.267 -14.439)" />
    <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#7791c1" d="M38.723,25.58a5.217,5.217,0,0,0-10.423,0,4.416,4.416,0,0,0,.695,2.432l.058.058.347.463,3.127,5.1c.232.347,1.563.405,1.8,0L37.854,28.3h0A4.37,4.37,0,0,0,38.723,25.58Zm-5.211,2.49a2.336,2.336,0,0,1-2.374-2.316,2.373,2.373,0,0,1,2.374-2.316,2.336,2.336,0,0,1,2.374,2.316A2.3,2.3,0,0,1,33.511,28.07Z" transform="translate(-11.913 -8.672)" />
    <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#828282" d="M135.108,91.46V61.581L109.283,46.7,83.4,61.581V91.46L109.283,106.4Z" transform="translate(-35.108 -19.659)" />
    <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#828282" d="M83.4,72.4l24.088,13.9a3.5,3.5,0,0,0,3.532,0l24.088-13.9" transform="translate(-35.108 -30.478)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#828282" y1="29.878" transform="translate(74.175 56.862)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#828282" x2="25.536" y2="15.113" transform="translate(61.899 34.105)" />
    <path strokeMiterlimit="10" stroke="#4b5467" fill="#b2dee9" opacity="0.55" d="M99.8,60.953,125.162,75.2l.347,12.855L128.4,84l1.737,2.258,1.853-4.632,1.563,1.563-.058-12.8L108.659,55.8" transform="translate(-42.012 -23.49)" />
    <path strokeMiterlimit="10" stroke="#4b5467" fill="#e5e4e4" d="M113.1,122.756l-7.3-4V104.4l7.3,3.937Z" transform="translate(-44.538 -43.948)" />
    <g transform="translate(62.478 63.636)">
      <line fill="#828282" x2="4.401" y2="2.432" />
      <path fill="#4b5467" d="M108,109.9a2.183,2.183,0,0,1,1.216.405c.174.116.29.29.463.405a1.734,1.734,0,0,0,.579.232,6.9,6.9,0,0,1,1.158.463,3.434,3.434,0,0,1,.984.869v.058a3.742,3.742,0,0,1-1.216-.347,3.925,3.925,0,0,1-1.042-.753,2.719,2.719,0,0,0-.521-.347c-.174-.058-.405-.116-.637-.174A1.685,1.685,0,0,1,108,109.9Z" transform="translate(-107.942 -109.9)" />
    </g>
    <g transform="translate(62.71 66.01)">
      <line fill="#828282" x2="4.401" y2="2.374" transform="translate(0 0.058)" />
      <path fill="#4b5467" d="M108.4,114a2.183,2.183,0,0,1,1.216.405c.174.116.29.29.463.405a1.734,1.734,0,0,0,.579.232,6.9,6.9,0,0,1,1.158.463,3.434,3.434,0,0,1,.984.869v.058a3.742,3.742,0,0,1-1.216-.347,3.926,3.926,0,0,1-1.042-.753,2.72,2.72,0,0,0-.521-.347c-.174-.058-.405-.116-.637-.174A1.5,1.5,0,0,1,108.4,114Z" transform="translate(-108.342 -114)" />
    </g>
    <g transform="translate(62.478 68.384)">
      <line fill="#828282" x2="4.401" y2="2.374" transform="translate(0 0.058)" />
      <path fill="#4b5467" d="M108,118.1a2.183,2.183,0,0,1,1.216.405c.174.116.29.29.463.405a1.734,1.734,0,0,0,.579.232,6.9,6.9,0,0,1,1.158.463,3.434,3.434,0,0,1,.984.869v.058a3.742,3.742,0,0,1-1.216-.347,3.926,3.926,0,0,1-1.042-.753,2.719,2.719,0,0,0-.521-.347c-.174-.058-.405-.116-.637-.174A1.5,1.5,0,0,1,108,118.1Z" transform="translate(-107.942 -118.1)" />
    </g>
    <g transform="translate(64.158 72.936)">
      <line fill="#828282" x2="2.2" y2="1.158" transform="translate(0 0.081)" />
      <path fill="#4b5467" d="M110.9,125.983a.759.759,0,0,1,.637.116c.058.058.116.232.232.29a.442.442,0,0,0,.29.116,1.167,1.167,0,0,1,.637.174.924.924,0,0,1,.405.521v.058a1.367,1.367,0,0,1-.695-.058,1.561,1.561,0,0,1-.463-.405.452.452,0,0,0-.232-.232c-.116-.058-.232,0-.347,0-.29-.058-.405-.232-.463-.579Z" transform="translate(-110.842 -125.961)" />
    </g>
    <path strokeMiterlimit="10" stroke="#4b5467" fill="#4eb588" d="M94.586,89.779l2.258,5.038L93.6,99.275,88.1,98.7l-2.2-5.038L89.143,89.2Z" transform="translate(-36.161 -37.55)" />
  </svg>


);

export default FlexibleSvg;
