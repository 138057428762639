import * as React from 'react';

const ContactSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 172">
    <g transform="translate(3 -1.461)">
      <g transform="translate(0 -1.226)">
        <g>
          <ellipse fill="#e3f0fc" cx="86" cy="86" rx="86" ry="86" transform="translate(-3 2.687)" />
          <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="2.954" height="2.954" transform="translate(52.795 142.043)" />
          <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="3.662" height="3.662" transform="translate(96.758 18.933)" />
          <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="1.477" height="1.477" transform="translate(135.198 41.35)" />
          <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.477" height="1.477" transform="translate(8.75 81.813)" />
          <rect fill="none" strokeMiterlimit="10" stroke="#7791c1" strokeWidth="0.75px" width="1.295" height="1.295" transform="translate(91.235 12.196)" />
          <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.477" height="1.477" transform="translate(69.445 141.497)" />
          <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.046" height="4.046" transform="translate(4.906 94.215) rotate(-90)" />
          <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="2.023" height="2.023" transform="translate(73.694 136.702) rotate(-90)" />
          <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.295" height="1.295" transform="translate(129.675 34.451)" />
        </g>
        <g transform="matrix(0.993, -0.122, 0.122, 0.993, 23.904, 37.66)">
          <path strokeMiterlimit="10" fill="#fafbfc" stroke="#4b5467" strokeWidth="1.5px" d="M51.764,36.256H4.608A3.648,3.648,0,0,1,1,32.648V4.608A3.648,3.648,0,0,1,4.608,1H51.764a3.648,3.648,0,0,1,3.608,3.608v28.1A3.635,3.635,0,0,1,51.764,36.256Z" transform="translate(0 0)" />
          <path strokeMiterlimit="10" fill="#fafbfc" stroke="#4b5467" strokeWidth="1.5px" d="M26.176,24.167,1.3,3.342A3.379,3.379,0,0,1,4.655,1H51.811s3.481.38,3.545,2.912L30.733,24.167A7.025,7.025,0,0,1,26.176,24.167Z" transform="translate(-0.11 0)" />
        </g>
        <g transform="translate(82.313 69.182) rotate(50)">
          <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" d="M1,5.769V16.462a4.811,4.811,0,0,0,4.769,4.769h4.408A4.764,4.764,0,0,1,14.945,26V60.971a4.764,4.764,0,0,1-4.769,4.769H5.769A4.764,4.764,0,0,0,1,70.509v10.4a4.764,4.764,0,0,0,4.769,4.769h.867A23.344,23.344,0,0,0,29.974,62.489l.217-36.922A24.377,24.377,0,0,0,5.769,1h0A4.811,4.811,0,0,0,1,5.769Z" />
          <path fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" d="M9.887,118.163s-7.343,1.727-.478,3.75c8.96,2.673,3.974,3.4.795,3.324" transform="translate(-1.545 -32.545)" />
          <path fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" d="M10.8,127.873s-8.281.894-1.489,2.918c8.96,2.673,3.5,3.434.316,3.362" transform="translate(-1.523 -35.209)" />
          <path fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" d="M10.21,136.5s-7.587.867-.795,2.89c8.96,2.673,3.974,3.4.795,3.324" transform="translate(-1.551 -37.595)" />
          <path fill="none" stroke="#4b5467" strokeWidth="1.5px" strokeLinecap="round" strokeLinejoin="round" d="M-.053.255l.075,3.56L.1,7.984l.065,4.478" transform="translate(5.386 5.472)" />
          <line fill="none" strokeMiterlimit="10" stroke="#4b5467" strokeWidth="1.5px" strokeLinecap="round" y2="13.006" transform="translate(4.945 69.707)" />
        </g>
        <g transform="translate(90 35)">
          <rect strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#7791c1" width="38.855" height="73.846" rx="5.67" transform="translate(17.258 29.949)" />
          <rect strokeMiterlimit="10" fill="#b7e1f5" stroke="#4c5467" width="37.317" height="61.99" rx="5.67" transform="translate(18.097 34.997)" />
          <ellipse fill="#4b5467" cx="1.922" cy="1.922" rx="1.922" ry="1.922" transform="translate(34.839 98.151)" />
          <line fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#fafbfc" strokeLinecap="round" y2="50.701" transform="translate(23.074 40.48)" />
        </g>
      </g>
    </g>
  </svg>
);

export default ContactSvg;
