import * as React from 'react';
import PropTypes from 'prop-types';

/* Styling */
import './ellipse.component.css';

/* Images */

class EllipseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  getContentClassName = () => {
    let classname = 'ellipse-content';

    // if (this.props.top) classname += ' top';
    if (this.props.bottom) classname += ' bottom';

    return classname;
  }

  render() {
    return (
      <div className="ellipse-container">
        <div className={this.getContentClassName()}>
          <div className="ellipse-children">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

EllipseComponent.propTypes = {
  top: PropTypes.bool,
  bottom: PropTypes.bool,
};

EllipseComponent.defaultProps = {
  top: true,
  bottom: true,
};


export default EllipseComponent;
