import * as React from 'react';

const LightVehicleSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.641 46.28">
    <g transform="translate(0.012 0)">
      <path fill="#ccd1db" d="M86.353,32.531c-.764-3.1-1.662-8.223-2.292-10.559a19.591,19.591,0,0,1-.629-4.269c-.225-9.346-2.741-13.03-4.269-15.861A3.415,3.415,0,0,0,76.153,0H39.758a15.508,15.508,0,0,0-4.134,1.618L18.281,13.839a3.762,3.762,0,0,1-1.662.629,2.63,2.63,0,0,0-.584.09l-4.269.989-3.55.944a6.68,6.68,0,0,0-3.325,1.977.044.044,0,0,1-.045.045,7.5,7.5,0,0,0-1.393,2.112L.038,34.1c-.359,2.112,1.258,5.482,3.37,5.482H6.554a10.072,10.072,0,0,1-.27-2.336,10.874,10.874,0,0,1,21.747,0,10.072,10.072,0,0,1-.27,2.336H61.685a10.4,10.4,0,0,1-.225-2.067,10.6,10.6,0,0,1,21.208,0,15.361,15.361,0,0,1-.18,2.022h.9C86.353,39.9,87.117,35.766,86.353,32.531ZM10.418,20.759c-3.28,4.4-6.2,5.437-6.2,5.437a24.711,24.711,0,0,1,1.168-4.673,8.12,8.12,0,0,1,2.067-2.471c1.258-.944,4.853-1.393,4.853-1.393A10.094,10.094,0,0,1,10.418,20.759Zm50.638-8.807a2.416,2.416,0,0,1-2.426,2.426H28.481a2.011,2.011,0,0,1-1.168-3.639L38.051,3.1a3.716,3.716,0,0,1,2.157-.674H58.136a2.915,2.915,0,0,1,2.921,2.921ZM78,14.019H66a2.211,2.211,0,0,1-2.112-2.292V4.808a1.924,1.924,0,0,1,1.842-2.022h9.346a2.429,2.429,0,0,1,2.247,1.707l2.336,6.875A1.919,1.919,0,0,1,78,14.019Z" transform="translate(0 0)" />
      <g transform="translate(63.248 28.487)">
        <path fill="#ccd1db" d="M149.685,63.4a8.9,8.9,0,0,0-.989,17.748,7.617,7.617,0,0,0,.989.045,8.9,8.9,0,0,0,.989-17.748c-.315,0-.629-.045-.989-.045Zm.225,13.3a4.179,4.179,0,1,1,4.179-4.179,4.2,4.2,0,0,1-4.179,4.179Z" transform="translate(-140.778 -63.4)" />
      </g>
      <g transform="translate(8.251 28.487)">
        <path fill="#ccd1db" d="M27.285,63.4A8.9,8.9,0,0,0,26.3,81.148a7.617,7.617,0,0,0,.989.045,8.9,8.9,0,0,0,.989-17.748c-.315,0-.674-.045-.989-.045Zm.225,13.3a4.179,4.179,0,1,1,4.179-4.179A4.2,4.2,0,0,1,27.509,76.7Z" transform="translate(-18.378 -63.4)" />
      </g>
    </g>
  </svg>


);

export default LightVehicleSvg;
