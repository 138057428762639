import * as React from 'react';

const StarSvg = props => (
  <svg style={{ width: '24px', height: '24px', cursor: 'pointer' }} viewBox="0 0 24 24">
    <path fill={props.color} d="M23.963,9.468A.7.7,0,0,0,23.4,9l-7.45-1.132L12.636.739a.731.731,0,0,0-1.273,0L8.046,7.863.6,9a.7.7,0,0,0-.4,1.187L5.623,15.7,4.352,23.55a.7.7,0,0,0,1.034.73L12,20.619l6.613,3.66a.7.7,0,0,0,1.034-.73L18.376,15.7,23.8,10.183A.7.7,0,0,0,23.963,9.468Z" transform="translate(0 -0.368)" />
  </svg>
);

StarSvg.defaultProps = {
  color: '#78849e',
};

export default StarSvg;
