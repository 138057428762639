import * as React from 'react';

const BackCarSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.744 67">
    <path fill="none" stroke="#ccd1db" strokeWidth="2px" d="M69.633,30.946,59.566,6.727A8.724,8.724,0,0,0,52.7,1.345C46.389.418,37.389.093,35.718,0h-.65C33.445.046,24.4.418,18.088,1.345a9.055,9.055,0,0,0-6.867,5.382L.736,30.482c-.7,1.67-.928,6.774-.6,8.583.789,4.361,0,11.924,1.9,14.615V63.7a1.3,1.3,0,0,0,1.3,1.3h7.238a1.3,1.3,0,0,0,1.3-1.3V57.438c.882.093,23.708.371,24.172.325,14.1-1.16,21.759-.232,22.595-.418V63.7a1.3,1.3,0,0,0,1.3,1.3h7.238a1.3,1.3,0,0,0,1.3-1.3v-9.65c2.134-1.763,1.3-10.532,2.088-14.986C71.025,37.256,70.376,32.616,69.633,30.946ZM17.3,34.472c-1.113.093-4.129,0-7.145-.093-.464,0-3.361-.418-4.382-1.67A6.8,6.8,0,0,1,4.8,29.739c-.139-.789-.186.165,0-2.155.046-.7.65-2.253,1.345-2.253a18.272,18.272,0,0,1,6.423.65,12.618,12.618,0,0,1,4.083,2.83l.139.139a15.159,15.159,0,0,1,1.763,3.712A1.391,1.391,0,0,1,17.3,34.472ZM46.76,49.6a1.3,1.3,0,0,1-1.3,1.3H23.47a1.3,1.3,0,0,1-1.3-1.3V45.05a1.3,1.3,0,0,1,1.3-1.3H45.415a1.3,1.3,0,0,1,1.3,1.3V49.6ZM35.811,21.064q-1.74-.07-3.48,0l-19.625-.093c-2.505,0-2.99-1.113-3.361-2.645a2.218,2.218,0,0,1,.139-1.345L14.124,7.1c.557-2.041,2.8-3.572,5.774-3.572l15.357-.7h.371l15.357.789c2.969,0,4.289.974,5.31,3.572l4.686,10.253a2.107,2.107,0,0,1,.139,1.3c-.371,1.485-.856,2.645-3.408,2.645Zm17.12,13.872a1.343,1.343,0,0,1-1.206-1.809,15.159,15.159,0,0,1,1.763-3.712l.139-.139a13.41,13.41,0,0,1,4.083-2.83c1.624-.7,4.1-.742,6.423-.65a1.362,1.362,0,0,1,1.345,1.253,12.349,12.349,0,0,1,0,3.155,6.8,6.8,0,0,1-.974,2.969c-1.021,1.253-3.918,1.67-4.382,1.67C57.06,34.936,54.045,35.029,52.931,34.936Z" transform="translate(1.02 1)" />
  </svg>


);

export default BackCarSvg;
