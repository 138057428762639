import * as React from 'react';

const BakkieSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.989 43.16">
    <g transform="translate(0 0)">
      <g transform="translate(63.389 23.787)">
        <path fill="#ccd1db" d="M208.845,74.9c-.35,0-.732.032-1.082.064a9.688,9.688,0,0,0,1.082,19.31c.35,0,.732-.032,1.082-.064A9.688,9.688,0,0,0,208.845,74.9Zm-.255,14.45a4.54,4.54,0,1,1,4.519-4.54,4.547,4.547,0,0,1-4.519,4.54Z" transform="translate(-199.202 -74.9)" />
      </g>
      <g transform="translate(7.372 23.691)">
        <path fill="#ccd1db" d="M32.845,74.6c-.35,0-.732.032-1.082.064a9.688,9.688,0,0,0,1.082,19.309c.35,0,.732-.032,1.082-.064A9.688,9.688,0,0,0,32.845,74.6Zm-.255,14.482a4.54,4.54,0,1,1,4.52-4.54,4.547,4.547,0,0,1-4.52,4.54Z" transform="translate(-23.202 -74.6)" />
      </g>
      <path fill="#ccd1db" d="M.122,25.757c.637,3.581.541,9.143,5.92,9.367a10.637,10.637,0,0,1,.1-2.238c0-6.266,5.252-10.486,11.076-10.678C22.624,22.017,26.825,26.3,27.652,31a11.457,11.457,0,0,1,.255,4.124H61.962a10.638,10.638,0,0,1,.1-2.238A10.964,10.964,0,0,1,74.089,22.208,11.084,11.084,0,0,1,83.891,31a11.457,11.457,0,0,1,.255,4.124h7.225l.859-2.685,5.411-2.11c2.228-.863,2.387-1.6,2.387-4,0-1.79-.032-3.389-.159-5.754l-.35-3.932A4.111,4.111,0,0,0,95.6,13.8H57.989L57.671,1.237A1.451,1.451,0,0,0,55.9.022s-7.273-.064-9.024,0a4.775,4.775,0,0,0-2.514.8L28.544,11.243s-23.329,2.781-24.6,3.676S-.515,22.208.122,25.757ZM33.413,11.179,44.762,2.963a7.012,7.012,0,0,1,2.6-1.022h5.283c1.146,0,1.591-.129,1.591,1.022L53.859,12.1a1.234,1.234,0,0,1-1.217.869H34.973C33.922,12.969,32.586,11.818,33.413,11.179ZM2.891,20.035a.3.3,0,0,1-.446-.352l1.082-2.749c.255-.7.859-.9,1.591-1.023a8.719,8.719,0,0,1,2.9-.48C9.67,15.559,4.832,18.788,2.891,20.035Z" transform="translate(-0.038 0.012)" />
    </g>
  </svg>


);

export default BakkieSvg;
