import * as React from 'react';

const AllDoneSvg = props => (
  <svg viewBox="0 0 292.108 200">
    <g transform="translate(-41.136 -368.086)">
      <g transform="translate(80.588 368.086)">
        <ellipse fill="#e3f0fc" cx="99.811" cy="100" rx="99.811" ry="100" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="3.449" height="3.456" transform="translate(67.246 166.58)" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="4.276" height="4.284" transform="translate(130.707 65.388)" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="1.725" height="1.728" transform="translate(94.969 14.462)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.725" height="1.728" transform="translate(127.369 52.56)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#7791c1" strokeWidth="0.75px" width="1.512" height="1.515" transform="translate(105.044 11.124)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.725" height="1.728" transform="translate(125.68 170.675)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.734" height="4.725" transform="translate(88.626 54.009) rotate(-90)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="5.527" height="5.537" transform="translate(49.762 163.273) rotate(-7.7)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.512" height="1.515" transform="translate(139.601 83.314)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.725" height="1.728" transform="translate(172.006 59.136)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.734" height="4.725" transform="translate(176.967 74.828) rotate(-90)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.512" height="1.515" transform="translate(77.877 182.722)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="3.614" height="3.621" transform="translate(75.561 53.22)" />
      </g>
      <g transform="translate(42.135 385.45)">
        <g transform="translate(0 5.569)">
          <path strokeMiterlimit="10" strokeWidth="2px" fill="#4eb588" stroke="#4b5467" d="M267.786,137.448c10.873,0,19.694-6.267,19.694-14.039a11.742,11.742,0,0,0-4.191-8.64c4.659-2.57,7.637-6.581,7.637-11.1,0-7.754-8.821-14.039-19.694-14.039a16.243,16.243,0,0,0-2.2.1v-.1c0-7.754-8.821-14.039-19.694-14.039s-19.694,6.267-19.694,14.039a10.206,10.206,0,0,0,.229,2.023,27.351,27.351,0,0,0-4.983-.475c-10.883,0-19.694,6.267-19.694,14.039a10.6,10.6,0,0,0,1.632,5.587c-6.549,2.283-11.064,7.064-11.064,12.606,0,7.754,8.821,14.039,19.694,14.039Z" transform="translate(-9.84 -14.376)" />
          <path strokeMiterlimit="10" strokeWidth="2px" fill="#4eb588" stroke="#4b5467" d="M65.141,138.09c9.689,0,17.546-6.267,17.546-14.03a12.327,12.327,0,0,0-3.733-8.64c4.143-2.57,6.825-6.581,6.825-11.1,0-7.754-7.856-14.039-17.546-14.039a14.249,14.249,0,0,0-1.957.09v-.09c0-7.754-7.856-14.039-17.546-14.039S31.185,82.507,31.185,90.279a10.938,10.938,0,0,0,.2,2.023,21.246,21.246,0,0,0-4.449-.475c-9.689,0-17.546,6.267-17.546,14.039a11.673,11.673,0,0,0,1.451,5.587C5.028,113.736,1,118.518,1,124.06c0,7.745,7.856,14.03,17.546,14.03Z" transform="translate(-1 -14.444)" />
          <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#dce2ea" d="M107.029,131.051H56.31V11.39l50.719,3.322Z" transform="translate(-3.51 -7.656)" />
          <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#dce2ea" d="M228.538,149.711h-6.864L220.72,18.59h7.818Z" transform="translate(-10.972 -8.41)" />
          <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#c1c7ce" d="M206.883,130.971c0,.161,11.455,13.761,11.532,13.922l2.52,3c-.143.358,0-1.379,0-1.791l-.955-134.47L206.73,9.77Z" transform="translate(-10.337 -7.487)" />
          <path strokeMiterlimit="10" strokeWidth="2px" fill="#4eb588" stroke="#4b5467" d="M46.441,18.662l176.012.054c.535,0,.83-.4.477-.663L208.611,7.363a.829.829,0,0,0-.477-.143L59.357,7.372a.767.767,0,0,0-.487.152L45.992,18.036C45.63,18.3,45.916,18.662,46.441,18.662Z" transform="translate(-3.035 -7.22)" />
          <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#2a7d57" d="M47.013,24.787H222.49a1.192,1.192,0,0,0,1.2-1.173V20.928a1.192,1.192,0,0,0-1.2-1.173L47.118,19.54a1.185,1.185,0,0,0-1.2,1.137l-.105,2.9a1.114,1.114,0,0,0,.33.842A1.269,1.269,0,0,0,47.013,24.787Z" transform="translate(-3.034 -8.509)" />
          <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#b9b9ba" d="M199.468,101.77H55.874L46.72,116.991a.085.085,0,0,0,0,.081.1.1,0,0,0,.072.045l164.261-.107a.846.846,0,0,0,.752-.45.748.748,0,0,0-.1-.831Z" transform="translate(-3.075 -17.116)" />
        </g>
        <g transform="translate(43.588 0)">
          <path fill="#353c47" d="M60.181,158.469,58.31,161.3V120.22h1.871Z" transform="translate(-47.189 -13.479)" />
          <rect fill="#4b5467" width="7.561" height="38.249" transform="translate(3.57 109.571)" />
          <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#b9b9ba" d="M213.088,122.241l-166.428.725V119.5a.65.65,0,0,1,.206-.481.74.74,0,0,1,.51-.2L213.088,117.8Z" transform="translate(-46.66 -13.225)" />
          <rect strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#dce2ea" width="6.043" height="110.726" transform="translate(201.252 34.023)" />
          <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#828282" d="M75.06,118.854V87.245L102.15,77.01l24.394,10.235v31.609L100.8,129.463Z" transform="translate(-6.472 -19.63)" />
          <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#828282" d="M100.7,127.263V96.919L75.41,85.72" transform="translate(-6.372 -17.851)" />
          <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#828282" y1="11.452" x2="25.742" transform="translate(94.33 67.616)" />
          <path strokeMiterlimit="10" stroke="#4b5467" fill="#b2dee9" opacity="0.55" d="M83.62,83.636l25.023,10.982.462,10.9,2.927-3.444,1.772,1.9L115.653,100l1.579,1.349-.205-10.9L94.4,79.59" transform="translate(-4.042 -19.103)" />
          <ellipse fill="#4b5467" cx="1.284" cy="2.481" rx="1.284" ry="2.481" transform="translate(100.66 87.195)" />
          <ellipse fill="#4b5467" cx="1.284" cy="2.481" rx="1.284" ry="2.481" transform="translate(113.499 83.583)" />
          <path strokeMiterlimit="10" strokeWidth="2px" fill="#4eb588" stroke="#4b5467" d="M241.822,161.878c-8.973,0-16.228-6.267-16.228-14.039a12.826,12.826,0,0,1,3.456-8.64,13.453,13.453,0,0,1-6.32-11.1c0-7.754,7.274-14.039,16.228-14.039a17.3,17.3,0,0,1,1.814.1v-.1c0-7.754,7.274-14.039,16.228-14.039s16.228,6.267,16.228,14.039a12.883,12.883,0,0,1-.181,2.023,18.771,18.771,0,0,1,4.114-.474c8.973,0,16.229,6.267,16.229,14.039a12.274,12.274,0,0,1-1.346,5.587,14.013,14.013,0,0,1,9.126,12.606c0,7.754-7.274,14.039-16.228,14.039Z" transform="translate(-54.651 -11.364)" />
          <rect strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#7791c1" width="26.357" height="38.696" transform="translate(191.095)" />
          <path fill="none" strokeMiterlimit="10" stroke="#4b5467" d="M105.15,106.908s3.531,6.286,5.508-1.758" transform="translate(2.071 -13.885)" />
        </g>
      </g>
      <path fill="#fff" d="M13.566,0H.9A.9.9,0,0,0,0,.9v.6a.9.9,0,0,0,.9.9C.9,5.84,2.827,8.733,5.458,9.647,2.827,10.561.9,13.454.9,16.882a.9.9,0,0,0-.9.9v.6a.9.9,0,0,0,.9.9H13.566a.9.9,0,0,0,.9-.9v-.6a.9.9,0,0,0-.9-.9c0-3.428-1.922-6.321-4.554-7.235,2.631-.914,4.554-3.807,4.554-7.235a.9.9,0,0,0,.9-.9V.9A.9.9,0,0,0,13.566,0ZM10.737,14.47h-7c.643-1.763,1.963-3.015,3.5-3.015S10.094,12.707,10.737,14.47Zm0-9.647h-7a7.07,7.07,0,0,1-.417-2.412h7.838A7.071,7.071,0,0,1,10.738,4.823Z" transform="translate(281.727 395.282)" />
    </g>
  </svg>
);

export default AllDoneSvg;
