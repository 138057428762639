import * as React from 'react';
import { Link } from 'react-router-dom';
import { compose, withApollo, graphql } from 'react-apollo';

/* Styling */
import './header.component.css';

/* SVG's */
import HorizotalLogoSvg from '../../../components/svg/horizontalLogo.svg';
import HamburgerSvg from '../../../components/svg/hamburger.svg';

/* Graphql */
import GetProfile from '../../../graphql/queries/getProfile.query';


class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      loggedIn: false,
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(props) {
    if (!props.loading && !props.error && props.profiles && props.profiles.length > 0) {
      this.setState({ loggedIn: true });
    }
  }


  toggle = () => {
    this.setState({ show: !this.state.show });
  }

  render() {
    return (
      <div>
        <div className="header">
          <Link className="header-logo" to="">
            <HorizotalLogoSvg />
          </Link>
          <div className="header-links">
            <Link to="/how-it-works" className="text">HOW IT WORKS</Link>
            <Link to="/help" className="text">HELP</Link>
            {this.state.loggedIn &&
            <Link to="/logout" className="text">LOGOUT</Link>
            }
            {!this.state.loggedIn && this.props.signin &&
            <Link to="/home" className="text">SIGN UP</Link>
            }
            {!this.state.loggedIn && !this.props.signin &&
            <Link to="/signin" className="text">SIGN IN</Link>
            }

          </div>
          <div className="header-burger">
            <button
              className="hamburger-button"
              onClick={this.toggle}
            >
              <HamburgerSvg />
            </button>
          </div>
        </div>
        {this.state.show &&
        <div className="header-dropdown">
          <Link to="how-it-works" className="text">HOW IT WORKS</Link>
          <Link to="help" className="text">HELP</Link>
          {this.state.loggedIn &&
          <Link to="/logout" className="text">LOGOUT</Link>
          }
          {!this.state.loggedIn && this.props.signin &&
          <Link to="/home" className="text">SIGN UP</Link>
          }
          {!this.state.loggedIn && !this.props.signin &&
          <Link to="/signin" className="text">SIGN IN</Link>
          }
        </div>}
      </div>

    );
  }
}

export default compose(
  withApollo,
  graphql(GetProfile, {
    options: {
      fetchPolicy: 'network-only',
    },
    props: (response) => {
      const { loading, error, getProfile } = response.data;
      return ({
        profiles: getProfile,
        error,
        loading,
      });
    },
  }),
)(HeaderComponent);
