import * as React from 'react';

const Svg = props => (
  <svg width={89} height={102} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.531 89.677">
    <g transform="translate(-651.015 -897.501)">
      <g transform="translate(659 897.501)">
        <ellipse fill="#e3f0fc" cx="44.838" cy="44.838" rx="44.838" ry="44.838" />
      </g>
      <g transform="translate(623.6 891.526)">
        <g transform="translate(-13.795 -0.754)">
          <path fill="#828282" d="M146.545,109.979H113.579A2.275,2.275,0,0,1,111.3,107.7V48.279A2.275,2.275,0,0,1,113.579,46h32.966a2.275,2.275,0,0,1,2.279,2.279V107.7A2.225,2.225,0,0,1,146.545,109.979Z" transform="translate(-5.088 -30.475)" />
          <path fill="#dce2ea" d="M146.375,110.723H115.2V52.279A2.275,2.275,0,0,1,117.479,50H144.1a2.275,2.275,0,0,1,2.279,2.279v56.083A18.924,18.924,0,0,1,146.375,110.723Z" transform="translate(-5.813 -31.219)" />
          <ellipse fill="#e39caa" cx="2.047" cy="2.047" rx="2.047" ry="2.047" transform="translate(111.642 47.189)" />
          <path fill="#fafbfc" d="M144.754,76.626h-6.675a2.275,2.275,0,0,1-2.279-2.279V57.579a2.275,2.275,0,0,1,2.279-2.279h6.675a2.275,2.275,0,0,1,2.279,2.279V74.266A2.245,2.245,0,0,1,144.754,76.626Z" transform="translate(-9.645 -32.205)" />
          <path fill="#fafbfc" d="M127.254,76.626h-6.675a2.275,2.275,0,0,1-2.279-2.279V57.579a2.275,2.275,0,0,1,2.279-2.279h6.675a2.275,2.275,0,0,1,2.279,2.279V74.266A2.347,2.347,0,0,1,127.254,76.626Z" transform="translate(-6.39 -32.205)" />
          <path fill="#fafbfc" d="M127.254,113.526h-6.675a2.275,2.275,0,0,1-2.279-2.279V94.479a2.275,2.275,0,0,1,2.279-2.279h6.675a2.275,2.275,0,0,1,2.279,2.279v16.687A2.347,2.347,0,0,1,127.254,113.526Z" transform="translate(-6.39 -39.069)" />
          <path fill="#fafbfc" d="M144.754,113.526h-6.675a2.275,2.275,0,0,1-2.279-2.279V94.479a2.275,2.275,0,0,1,2.279-2.279h6.675a2.275,2.275,0,0,1,2.279,2.279v16.687A2.245,2.245,0,0,1,144.754,113.526Z" transform="translate(-9.645 -39.069)" />
          <line fill="none" stroke="#ccd1db" strokeLinecap="round" strokeMiterlimit="10" x2="18.152" transform="translate(119.666 48.736)" />
        </g>
        <g transform="translate(13.109)">
          <path fill="#707070" d="M59.145,83.779H26.179A2.275,2.275,0,0,1,23.9,81.5V22.079A2.275,2.275,0,0,1,26.179,19.8H59.145a2.275,2.275,0,0,1,2.279,2.279V81.5A2.329,2.329,0,0,1,59.145,83.779Z" transform="translate(-9.594 -5.028)" />
          <path fill="#7691c1" d="M58.975,84.523H27.8V26.079A2.275,2.275,0,0,1,30.079,23.8H56.7a2.275,2.275,0,0,1,2.279,2.279V84.523Z" transform="translate(-10.32 -5.773)" />
          <ellipse fill="#fafbfc" cx="1.547" cy="1.547" rx="1.547" ry="1.547" transform="translate(20.655 46.435)" />
          <path fill="#91b3d1" d="M54.1,50.426H33.179A2.275,2.275,0,0,1,30.9,48.147V31.379A2.275,2.275,0,0,1,33.179,29.1H54.1a2.275,2.275,0,0,1,2.279,2.279V48.066A2.294,2.294,0,0,1,54.1,50.426Z" transform="translate(-10.896 -6.758)" />
          <path fill="#91b3d1" d="M54.1,87.326H33.179A2.275,2.275,0,0,1,30.9,85.047V68.279A2.275,2.275,0,0,1,33.179,66H54.1a2.275,2.275,0,0,1,2.279,2.279V84.966A2.294,2.294,0,0,1,54.1,87.326Z" transform="translate(-10.896 -13.623)" />
          <path fill="#7691c1" d="M52.528,85.247H35.679A2.275,2.275,0,0,1,33.4,82.968V71.979A2.275,2.275,0,0,1,35.679,69.7H52.528a2.275,2.275,0,0,1,2.279,2.279V82.886A2.347,2.347,0,0,1,52.528,85.247Z" transform="translate(-11.362 -14.311)" />
          <path fill="#7691c1" d="M52.528,48.354H35.679A2.275,2.275,0,0,1,33.4,46.075v-11.4A2.275,2.275,0,0,1,35.679,32.4H52.528a2.275,2.275,0,0,1,2.279,2.279V45.993A2.347,2.347,0,0,1,52.528,48.354Z" transform="translate(-11.362 -7.372)" />
          <line fill="none" x2="16.687" transform="translate(26.76 47.982)" />
        </g>
      </g>
      <g transform="translate(-11.531 25.364)">
        <path fill="#20d89b" d="M94.8,81.9v17l13.121-8.563Z" transform="translate(616.079 825.607)" />
        <path fill="none" stroke="#20d89b" strokeWidth="4px" d="M10514.5,744.3h33.78" transform="translate(-9831.001 171.751)" />
      </g>
    </g>
  </svg>
);

export default Svg;

