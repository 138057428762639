/**
# * @file: src/graphql/queries/SendInvite.query.js
# * @description SendInvite Query File
# * @copyright: Verge Technologies 2018
# * @author Fabio Loreggian fabio@verge.co.za
# */
import gql from 'graphql-tag';

const SendInvite = gql`
    query SendInvite($phone: String!){
      sendInvite(phone: $phone)
    }
`;

export default SendInvite;

