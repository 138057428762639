/**
# * @file: src/graphql/mutations/example.mutation.js
# * @description Example Mutation File
# * @copyright: Verge Technologies 2018
# * @author Fabio Loreggian fabio@verge.co.za
# */
import gql from 'graphql-tag';

const LoginDriverProfile = gql`
    mutation LoginDriverProfile($email: String!, $password: String!){
        loginDriverProfile(email: $email, password: $password){
            jwt
            isTrainingComplete
        }
    }
`;
export default LoginDriverProfile;
