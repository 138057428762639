import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import { Switch } from 'react-router';
import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Banner from '../components/Banner/Banner.component';
/* Pages */

import HomePage from '../pages/home/home.page';
import SignupPage from '../pages/signup/signup.page';
import DeliveryPage from '../pages/delivery/delivery.page';

// Website
import HomeWebPage from '../pages/web/home/home.web.page';
import BusinessWebPage from '../pages/web/business/business.web.page';
import ContactWebPage from '../pages/web/contact/contact.web.page';
import HowItWorksWebPage from '../pages/web/howitworks/howitworks.web.page';
import AboutUsWebPage from '../pages/web/aboutus/aboutus.web.page';
import FAQWebPage from '../pages/web/faq/faq.web.page';
import TermsWebPage from '../pages/web/terms/terms.web.page';
import PrivacyWebPage from '../pages/web/privacy/privacy.web.page';

/* Util */
import { clear } from '../common/storage';

const history = createHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});


export default class Routes extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <div style={{ marginTop: 21 }}>
        <Banner />
        <Router  history={history}>
          <Switch>
            {/* <Route exact path="/" component={TestPage} /> */}
            <Route exact path="/" component={HomeWebPage} />
            <Route exact path="/business" component={BusinessWebPage} />
            <Route exact path="/contact" component={ContactWebPage} />
            <Route exact path="/how-it-works" component={HowItWorksWebPage} />
            <Route exact path="/about-us" component={AboutUsWebPage} />
            <Route exact path="/faq" component={FAQWebPage} />
            <Route exact path="/terms" component={TermsWebPage} />
            <Route exact path="/privacy" component={PrivacyWebPage} />
            <Route exact path="/help" component={FAQWebPage} />
            <Route exact path="/home" component={HomePage} />
            <Route exact path="/signup" component={SignupPage} />
            <Route
              exact
              path="/signin"
              component={(props) => <HomePage signin {...props} />}
            />
            <Route
              exact
              path="/delivery/:id"
              component={(props) => <DeliveryPage id={props.match.params.id} />}
            />
            <Route
              exact
              path="/logout"
              component={(props) => {
                clear();
                props.history.push('home');
                return null;
              }}
            />
          </Switch>

        </Router>
        <NotificationContainer />
      </div>
    );
  }
}
