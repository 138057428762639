import * as React from 'react';
import { Map, Polyline, Marker, GoogleApiWrapper } from 'google-maps-react';
import * as PolylineCoder from '@mapbox/polyline';

/* Styling */
import './map.style.css';

/* Google */
const directionsAPI = 'https://maps.googleapis.com/maps/api/directions/json?';
const key = 'AIzaSyBg8poosqDz6xhDaQhsm2CnyUN9aF7AJ-I';

class MapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      route: null,
    };
  }

  componentDidMount() {
    this.getDirections(this.props);
  }

  componentWillReceiveProps(props) {
    this.getDirections(props);
  }

  getDirections = (props) => {
    const { route } = props;
    if (!route) return;
    const origin = `${route.origin.lat}, ${route.origin.lng}`;
    const destination = `${route.destination.lat}, ${route.destination.lng}`;

    const parameters = `origin=${origin}&destination=${destination}&key=${key}`;

    fetch(`${directionsAPI}${parameters}`)
      .then(response => response.json())
      .then((route) => {
        this.setState({ route });
        this.props.onRouteChange(route);
      });
  }

  getRoute = () => {
    const { route: googleRoute } = this.state;
    if (!googleRoute) return null;
    const [route] = googleRoute.routes;
    const { overview_polyline: polyline } = route;
    const path = PolylineCoder.decode(polyline.points).map(point => ({
      lat: point[0],
      lng: point[1],
    }));

    return (
      <Polyline
        path={path}
        strokeColor="#20D89B"
        strokeOpacity={0.8}
        strokeWeight={5}
      />
    );
  }

  getMarkers = () => {
    const { route } = this.props;
    if (!route) return <div />;

    const originIcon = {
      url: 'https://cdn.iconscout.com/icon/free/png-256/car-location-find-navigate-gps-location-29571.png',
      scaledSize: new this.props.google.maps.Size(64, 64),
    };

    /*
    const destinationIcon = {
      url: 'https://loc8tor.co.uk/wp-content/uploads/2015/08/stencil.png', // url
    }; */

    const origin = (
      <Marker
        key="originMarker"
        title="The origin of the package"
        name="ORIGIN"
        position={route.origin}
        icon={originIcon}
      />
    );

    const destintion = (
      <Marker
        key="destinationnMarker"
        title="The destination of the package"
        name="DESTINATION"
        position={route.destination}
      />
    );

    return [origin, destintion];
  }

  getMapBounds = () => {
    const { route: googleRoute } = this.state;
    if (googleRoute) {
      const [route] = googleRoute.routes;

      const bounds = new this.props.google.maps.LatLngBounds(
        new this.props.google.maps.LatLng(route.bounds.southwest),
        new this.props.google.maps.LatLng(route.bounds.northeast),
      );

      return bounds;
    }
  }

  render() {
    return (
      <Map
        google={this.props.google}
        bounds={this.getMapBounds()}
        initialCenter={{
          lat: -25.784227,
          lng: 28.358616,
        }}
        style={{
          borderRadius: '15px',
        }}
      >
        {this.getRoute()}
        {this.getMarkers()}
      </Map>
    );
  }
}

MapComponent.defaultProps = {
  onRouteChange: () => {},
};


export default GoogleApiWrapper({
  apiKey: key,
})(MapComponent);
