import React, { Component } from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import './photoCarousel.css';
import 'pure-react-carousel/dist/react-carousel.es.css';


const photoArray = [{ uri: require('../../assets/images/homeBg.jpg')}]


export default class PhotoCarousel extends Component {

    render() {
        console.log(photoArray)
        return (
            <div className="container">
                 <image style={{width:350, height:350, backgroundColor:'red'}} src={photoArray[0].uri} />
                {/* <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={125}
                    totalSlides={3}
                >
                    <Slider>
                        {photoArray.map((photo, index)=> (
                            <Slide index={index}>
                                <image style={{width:150, heigth:150, backgroundColor:'red'}} src={photo.uri} />
                            </Slide>
                        ))}

                    </Slider>
                    <ButtonBack>Back</ButtonBack>
                    <ButtonNext>Next</ButtonNext>
                    <DotGroup dotNumbers />
                </CarouselProvider> */}
            </div>
        )
    }
}
