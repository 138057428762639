// TODO: FIXME

import * as React from 'react';

const CourierInfoSvg = props => (
  <svg viewBox="0 0 172 172">
    <defs>
      <clipPath id="clip-path">
        <circle fill="none" cx="86" cy="86" r="86" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path)">
      <circle fill="#e3f0fc" cx="86" cy="86" r="86" />
      <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="2.972" height="2.972" transform="translate(147.503 105.602)" />
      <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="3.684" height="3.684" transform="translate(12.61 67.233)" />
      <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="1.486" height="1.486" transform="translate(81.817 13.455)" />
      <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.486" height="1.486" transform="translate(19.734 56.2)" />
      <rect fill="none" strokeMiterlimit="10" stroke="#7791c1" strokeWidth="0.75px" width="1.303" height="1.303" transform="translate(90.509 10.585)" />
      <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.486" height="1.486" transform="translate(47.213 107.088)" />
      <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.071" height="4.071" transform="translate(158.362 107.342) rotate(-90)" />
      <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.763" height="4.763" transform="translate(22.506 110.893) rotate(-7.7)" />
      <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.303" height="1.303" transform="translate(20.284 72.667)" />
      <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.486" height="1.486" transform="translate(148.195 51.875)" />
      <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.071" height="4.071" transform="translate(152.48 65.37) rotate(-90)" />
      <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.303" height="1.303" transform="translate(16.213 97.093)" />
      <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="3.114" height="3.114" transform="translate(155.095 116.655)" />
      <path strokeMiterlimit="10" fill="#fff" stroke="#4b5467" strokeWidth="4px" strokeDasharray="12" d="M134.517,147.107c-4.5-4.977-37-17.3-37-17.3V114.936c12.61-7.236,19.5-26.126,19.5-45.025,0-24.06-11.2-39-31.092-39s-31.1,14.941-31.1,39c0,18.391,6.534,36.761,18.5,44.415v14.788S43.815,142.557,39.805,146.14s-4.885,96.778-4.885,96.778H141.183S139.005,152.114,134.517,147.107Z" transform="translate(0.62 0.549)" />
    </g>
  </svg>


);

export default CourierInfoSvg;
