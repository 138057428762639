import * as React from 'react';
import PropTypes from 'prop-types';
import { Squares } from 'react-activity';
import 'react-activity/dist/react-activity.css';

/* Styling */
import './button.component.css';

/* Images */

class ButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  getButtonClass = (primary, disabled, loading) => {
    let className = 'button text';
    if (primary) {
      className += ' primary';
    } else {
      className += ' secondary';
    }

    if (disabled) {
      className += ' disabled';
    }

    if (loading) {
      className += ' loading';
    }
    return className;
  }

  getActivityColor = (primary) => {
    if (primary) {
      return '#506290';
    }
    return '#fff';
  }

  render() {
    const {
      text, onClick, loading, disabled, primary,
    } = this.props;
    return (
      <button
        className={this.getButtonClass(primary, disabled, loading)}
        onClick={() => {
          if (!disabled && !loading) {
            onClick();
          }
        }}
      >
        {!loading && text}
        {loading && <Squares color={this.getActivityColor(primary)} />}
      </button>
    );
  }
}

ButtonComponent.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
};

ButtonComponent.defaultProps = {
  text: '',
  onClick: () => {},
  loading: false,
  disabled: false,
  primary: false,
};


export default ButtonComponent;
