import * as React from 'react';
import PropTypes from 'prop-types';

const getRotation = (direction) => {
  switch (direction) {
    case 'DOWN': return 0;
    case 'UP': return 180;
    case 'LEFT': return 90;
    case 'RIGHT': return 270;
    default: return 0;
  }
};

const ChevronSvg = props => (
  <svg style={{ height: '20px', width: '12px', transform: `rotate(${getRotation(props.direction)}deg)` }} viewBox="0 0 13.51 7.51">
    <path fill="#46557a" d="M1597.541-4.4l-5.929-5.93a.742.742,0,0,1-.081-.07.747.747,0,0,1-.219-.536.747.747,0,0,1,.219-.535.751.751,0,0,1,.078-.068l5.932-5.932a.751.751,0,0,1,1.061,0,.751.751,0,0,1,0,1.061l-5.476,5.475,5.476,5.475a.751.751,0,0,1,0,1.061.749.749,0,0,1-.531.219A.749.749,0,0,1,1597.541-4.4Z" transform="translate(17.689 1598.821) rotate(-90)" />
  </svg>
);

ChevronSvg.propTypes = {
  direction: PropTypes.string,
};

ChevronSvg.defaultProps = {
  direction: 'DOWN',
};

export default ChevronSvg;

