import * as React from 'react';
import { Link } from 'react-router-dom';

/* Styling */
import './footer.component.css';

/* Images */

class FooterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="footer">
        <div className="footer-copyright">
          <p className="text"> Copyright © 2018. All rights reserved. Boxel Technologies</p>
        </div>
        <div className="footer-actions-container">
          <div className="footer-actions">
            <p className="text">ABOUT</p>
            <Link className="text" to="/about-us">About us</Link>
            <Link className="text" to="/how-it-works">How it works</Link>
            <Link className="text" to="/faq">FAQ</Link>
          </div>

          <div className="footer-actions">
            <p className="text">CONTACT</p>
            <Link className="text" to="/contact">Contact us</Link>
            <a className="text" href="mailto:admin@boxel.co.za">admin@boxel.co.za</a>
          </div>

          <div className="footer-actions">
            <p className="text">LEGAL</p>
            <Link className="text" to="/terms">Terms of Use</Link>
            <Link className="text" to="/privacy">Privacy Policy</Link>
            <Link className="text" to="/help">Help</Link>
          </div>
        </div>
      </div>
    );
  }
}


export default FooterComponent;
