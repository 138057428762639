import * as React from 'react';

const Svg = props => (
  <svg width={52} height={48} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.304 51.932">
    <g transform="translate(0.377 11.376)">
      <path fill="#fdfdfd" stroke="#506290" strokeMiterlimit="10" strokeWidth="2px" d="M82.8,80.738l3.046-2.548a1.723,1.723,0,0,1,1.992-.146,26.471,26.471,0,0,0,4.13,2.138,1.023,1.023,0,0,1,.439,1.611c-.732.893-2.2,1.758-5.038.732-1.216-.439,8.436,2.417,8.436,2.417l-9.169,4.877Z" transform="translate(-70.117 -68.565)" />
      <path stroke="#506290" strokeMiterlimit="10" strokeWidth="2px" fill="#fff" d="M61.3,80.651,64.566,78.4a1.716,1.716,0,0,1,2.007.029,27.1,27.1,0,0,0,3.925,2.5,1.458,1.458,0,0,1,.674,1.963c-.674,1.7-3.618,1.011-6.357-.264-.791-.366,3.544,1.714,6.5,2.841a1.08,1.08,0,0,1,.029,2.007L64.332,90.04Z" transform="translate(-51.766 -68.815)" />
      <path stroke="#506290" strokeMiterlimit="10" fill="#fff" strokeWidth="3px" d="M86.7,111.787l8.07-4.687,8.495,4.1-.952,5.126-7.323-.513Z" transform="translate(-73.446 -93.579)" />
      <path stroke="#506290" strokeMiterlimit="10" strokeWidth="2px" fill="#fff" d="M35.108,3.455,33.219,4.788a3.419,3.419,0,0,0-1.347,2.065l-1.845,9.169a3.355,3.355,0,0,0,.015,1.377c.322,1.465,1.113,4.775,1.743,5.668a4.483,4.483,0,0,0,4.745,1.45,2.436,2.436,0,0,0,.7-.425,3.287,3.287,0,0,0,.762-4.072l-1.626-3.09a3.448,3.448,0,0,1-.381-1.743,41.143,41.143,0,0,1,.63-6.064C37.012,8.2,42.3,3.411,39.986.7c-.893-1.055-3.559,1.421-4.335,2.27A4.258,4.258,0,0,1,35.108,3.455Z" transform="translate(-25.018 -2.545)" />
      <path stroke="#506290" strokeMiterlimit="10" strokeWidth="2px" fill="#fff" d="M20.226,13.453c.015.015,1.831,1.6,1.831,1.611.366.5,4.189,5.566,7.03,6.225,2.929.688,9.608,6.606,9.667,9.623.059,3.09,5.8,7.353,7.865,8.832a.726.726,0,0,1,.073,1.128C44.3,43.1,36.117,50.538,34.462,49.118c-1.948-1.655-3.706-9.667-6.152-9.96-1.479-.176-8.392-2.446-13.519-4.042-2.959-.923-5.492-.688-7.294-3.193-.015-.015-.015-.029-.029-.044L3.822,26.035a.691.691,0,0,1-.088-.22C3.456,24.658,1.244,15.343.7,13.834c-.586-1.611,4.042-1.655,4.921,1.289.527,1.743,1.933,6.825,3.046,8.246.893,1.157,2.153,3.354,3.178,4a.541.541,0,0,0,.161.073l3.413,1.084a.653.653,0,0,0,.5-.015c1.582-.7,2.8-4.936,2.256-6.606-.439-1.362-.41-3.427-1.6-4.716-3.5-3.779-3.427-8.026-.059-8.392.264-.029.747.864,1.245,1.684Z" transform="translate(0 -9.673)" />
      <line stroke="#506290" strokeWidth="2px" fill="none" strokeLinecap="round" y1="2.654" x2="1.99" transform="translate(19.537 -8.386)" />
      <line stroke="#506290" strokeWidth="2px" fill="none" strokeLinecap="round" x1="2.054" y1="2.599" transform="translate(8.922 -8.386)" />
      <line stroke="#506290" strokeWidth="2px" fill="none" strokeLinecap="round" y1="3.98" transform="translate(14.893 -10.376)" />
      <line stroke="#506290" strokeWidth="2px" fill="none" strokeLinecap="round" x2="3.108" y2="0.297" transform="translate(5.151 -3.376)" />
      <line stroke="#506290" strokeWidth="2px" fill="none" strokeLinecap="round" y1="0.297" x2="3.961" transform="translate(22.19 -3.376)" />
    </g>
  </svg>
);

export default Svg;

