import * as React from 'react';

const Svg = props => (
  <svg width={66} height={50} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.053 66.101">
    <g transform="translate(9.369 8.488)">
      <g transform="translate(1.4 -4)">
        <path fill="none" d="M54.1,144.667a.321.321,0,0,0,.142.257l.426-.723C54.313,144.293,54.1,144.457,54.1,144.667Z" transform="translate(-41.623 -106.707)" />
        <path fill="none" d="M55.047,71.173c-.5.07-.876.28-.876.537s.355.467.852.56v.373c-.5.07-.852.28-.876.537,0,.257.355.467.852.56v.327c-.5.07-.852.28-.852.537s.331.467.829.56v.373c-.5.07-.829.28-.852.537,0,.233.331.467.829.56v.42c-.474.07-.829.28-.829.537,0,.233.331.443.8.537V78.5c-.474.093-.829.28-.829.537,0,.233.331.443.8.537v.373c-.4.093-.687.28-.687.513,0,.21.26.42.663.513v.443c-.4.093-.687.28-.687.49s.26.4.663.513v.49c-.4.093-.663.28-.663.49s.26.4.639.513v.443c-.4.093-.663.28-.663.49s.26.4.639.513v.28L65.062,68c-2.983.98-7.316,2.263-10.015,3.08Z" transform="translate(-41.623 -48.287)" />
        <path fill="#fff" stroke="#9b9fa7" strokeMiterlimit="10" strokeWidth="2px" d="M16.137,130.646l1.228-2.1c-.651-1.921-.143-.439-.557-1.727-.03-.087-1.074-3.8-1.1-3.882-.2-.838-2.424-9.248-2.47-9.283.055-.028-4.007,1.18-4.007,1.18l1.309,8.888s1.163,8.954,3.8,12.578C18.711,136.661,15.131,131.933,16.137,130.646Z" transform="matrix(0.574, -0.819, 0.819, 0.574, -107.701, -10.506)" />
        <path fill="#fff" stroke="#9b9fa7" strokeMiterlimit="10" strokeWidth="2px" d="M15.6,132.063l1.724-2.716c-.6-2.053-1.341-4.41-1.724-5.787l-.082-.28c-1.889-6.207-4.927-7.28-4.927-7.28s-2.929,2.38-1.013,8.844c.3,1.027,2.006,7.635,4.442,11.509C14.159,133.584,14.666,133.44,15.6,132.063Z" transform="translate(-7.971 -85.903)" />
        <path fill="none" d="M140.631,37.723A1.757,1.757,0,0,0,139.4,37l1.16.677C140.584,37.7,140.607,37.723,140.631,37.723Z" transform="translate(-106.727 -24.521)" />
        <path fill="#fff" stroke="#9b9fa7" strokeMiterlimit="10" strokeWidth="2px" d="M18.909,51.99c3.52-.817,9.172-2.077,13.063-3.08l2.718-3.57c-1.328-.35-3.119-.863-4.323-1.33a4.54,4.54,0,0,1-1.39-.91c-3.922.7-7.906,1.54-11.117,2.263-1.421.3-2.656.607-3.706.84-1.451.327-2.47.583-2.872.677C5.847,48.117,1.4,53.04,1.4,53.04s3.181,1.517,11.8.117c1.421-.233,3-.537,4.756-.957C18.23,52.154,18.57,52.084,18.909,51.99Z" transform="translate(-1.4 -29.197)" />
        <path fill="#fff" stroke="#9b9fa7" strokeMiterlimit="10" strokeWidth="2px" d="M78.833,18.751c1.356.748,2.936.127,4.431.661l.8-1.425a8.241,8.241,0,0,1,11.4-3.134h0l-1.7-1.033L74.156,2.068A7.265,7.265,0,0,0,72.071,1H70.3c-.382,0-.66,1-.73,1.353a15.982,15.982,0,0,0,1.46,9.046.339.339,0,0,0,.591.036c.7-1.1,1.634-4.325,2.538-4.61,1.147-.392,9.56,4.4,9,5.928-.591,1.531-4.311,2.92-6.5.855-1.669-1.567-2.99.74-1.634,2.486A12.285,12.285,0,0,0,78.833,18.751Z" transform="translate(-54.937 -17.546) rotate(11)" />
        <path fill="#fff" stroke="#9b9fa7" strokeMiterlimit="10" strokeWidth="2px" d="M55.765,37.487c-.024-.023-.047-.023-.071-.047h0a5.732,5.732,0,0,0-7.766,2.053l-.545.933L45.3,44,35,61.591l-.095.163-.426.723-3.551,6.067L28.963,71.9a5.526,5.526,0,0,0-.521,4.387,5.65,5.65,0,0,0,2.6,3.267A5.732,5.732,0,0,0,38.813,77.5L57.8,45.094A5.589,5.589,0,0,0,55.765,37.487Z" transform="translate(-21.861 -24.285)" />
      </g>
    </g>
  </svg>
);

export default Svg;

