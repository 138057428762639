import * as React from 'react';

/* Styling */
import './modal.style.css';

/* Images */

class ModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <div
        className="modal"
      >
        {this.props.children}
      </div>
    );
  }
}


export default ModalComponent;
