/**
# * @file: src/graphql/mutations/uploadFile.mutation.js
# * @description Confirm OTP Mutation File
# * @copyright: Verge Technologies 2018
# * @author Fabio Loreggian fabio@verge.co.za
# */
import gql from 'graphql-tag';

const UploadFile = gql`
    mutation UploadFile($content: String!, $type: UPLOAD_TYPE!){
      uploadFile(content: $content, type: $type)
    }
`;

export default UploadFile;
