import * as React from 'react';

const PersonalDocumentsSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 172">
    <g transform="translate(-907 -270.543)">
      <g transform="translate(995 -295)">
        <circle fill="#e3f0fc" cx="86" cy="86" r="86" transform="translate(-88 565.543)" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="2.972" height="2.972" transform="translate(49.503 611.145)" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="3.684" height="3.684" transform="translate(-75.39 632.775)" />
        <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="1.486" height="1.486" transform="translate(-6.183 578.998)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.486" height="1.486" transform="translate(-68.266 621.743)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#7791c1" strokeWidth="0.75px" width="1.303" height="1.303" transform="translate(2.509 576.127)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.486" height="1.486" transform="translate(-40.786 612.631)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.071" height="4.071" transform="translate(-39.638 602.885) rotate(-90)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.763" height="4.763" transform="translate(-25.494 716.436) rotate(-7.7)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.303" height="1.303" transform="translate(-67.716 638.21)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.486" height="1.486" transform="translate(60.195 617.418)" />
        <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.071" height="4.071" transform="translate(64.48 630.913) rotate(-90)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.303" height="1.303" transform="translate(-11.787 712.636)" />
        <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="3.114" height="3.114" transform="translate(-12.905 722.198)" />
      </g>
      <g transform="translate(938.092 312)">
        <g>
          <path strokeMiterlimit="10" strokeWidth="2px" fill="#fafbfc" stroke="#4b5467" d="M47,46.48c0,.33,7.79-38.87,13.47-39.22l56.39,7.2a140.5,140.5,0,0,0-13,39.61,190.4,190.4,0,0,0-1.65,36.72L45.36,83.63A117.16,117.16,0,0,1,47,46.48Z" />
          <rect strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" width="39.35" height="54.7" rx="2.83" transform="translate(10.78 1)" />
          <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="17.12" y2="0.14" transform="translate(27.27 30.89)" />
          <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="17.12" y2="0.15" transform="translate(27.27 36.65)" />
          <path fill="#fff" d="M46.61,23.18c-.69-.77-5.69-2.73-5.69-2.73V18.18c2-1.07,3.09-3.94,3.14-6.82.06-3.67-1.63-6-4.72-6s-4.86,2.2-4.92,5.87c-.05,2.8.91,5.62,2.75,6.82v2.26a51.851,51.851,0,0,0-5.24,2.51" />
          <path fill="#4b5467" d="M46.36,23.38a5.55,5.55,0,0,0-1.51-.89l.28-.56A6,6,0,0,1,46.85,23m-15.12-.43a3.33,3.33,0,0,1,.65-.4l.3.54a5,5,0,0,0-.52.31m2.21-1.13-.27-.56c.51-.24,1.09-.5,1.73-.78l.25.56c-.63.26-1.21.52-1.71.76Zm8.78-.16c-.68-.3-1.32-.56-1.74-.73l.24-.58,1.75.74Zm-5.65-2h-.63V18.2h0V18H37l.25-.34.12.08.15.1Zm3.76-.5h-.64V18l.16-.08a4.93,4.93,0,0,0,.62-.43l.41.47a4.57,4.57,0,0,1-.57.41Zm-5.72-2.5A8.17,8.17,0,0,1,34.71,15l.59-.19a7.75,7.75,0,0,0,.77,1.61Zm7.56-.3-.55-.31a8.48,8.48,0,0,0,.73-1.64l.6.19a8.84,8.84,0,0,1-.78,1.76ZM34.26,13.1a12,12,0,0,1-.12-1.9h.63a11,11,0,0,0,.12,1.8Zm10-.3-.62-.09a12.081,12.081,0,0,0,.1-1.36V10.9h.63v.47a11.63,11.63,0,0,1-.07,1.43ZM35,9.41l-.62-.12A6.67,6.67,0,0,1,35,7.45l.55.3A6.13,6.13,0,0,0,35,9.41Zm8.59-.29a5.58,5.58,0,0,0-.67-1.63l.54-.32A6.14,6.14,0,0,1,44.17,9ZM36.68,6.43l-.38-.49a4.53,4.53,0,0,1,1.84-.82l.12.61A4,4,0,0,0,36.68,6.43Zm5-.16A4.07,4.07,0,0,0,40,5.69l.09-.62A4.9,4.9,0,0,1,42,5.74Z" />
          <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="17.12" y2="0.14" transform="translate(27.27 41.84)" />
          <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" d="M37.18,60.43,11.93,55a2.85,2.85,0,0,1-2.34-3.26L9.44,3.38c.25-1.54,1.17-1.84,2.71-1.59L37.93,6.43a2.84,2.84,0,0,1,2.35,3.25l.15,48.4a2.85,2.85,0,0,1-3.25,2.35Z" />
          <rect strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#dce2ea" width="35.42" height="54.7" rx="5.67" transform="translate(61.009 62.1) rotate(102.58)" />
          <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="21.28" y2="4.69" transform="translate(11.38 61.14)" />
          <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="21.28" y2="4.7" transform="translate(9.44 66.95)" />
          <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="21.27" y2="4.7" transform="translate(7.7 72.57)" />
          <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="21.27" y2="4.7" transform="translate(6.05 78.53)" />
          <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="15.97" y2="2.67" transform="translate(16.69 11.96)" />
          <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="44.39" y2="5.33" transform="translate(60.33 19.3)" />
          <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="44.39" y2="5.33" transform="translate(58.33 24.63)" />
          <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="44.39" y2="5.34" transform="translate(56.54 30.96)" />
          <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="17.01" y2="2" transform="translate(55.21 36.3)" />
          <path fill="none" stroke="#4b5467" strokeLinecap="round" strokeLinejoin="round" d="M58.53,72.91s3.94-1.67,4-5.11S61.35,69,61.35,69v5.64l3.18-4s-1.57,3.68,0,4.65,1.37,1.36,2,0A20.15,20.15,0,0,1,68,72.5s2.45,3.28,2.25,4.41a3.77,3.77,0,0,0,0,1.7" />
          <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" d="M24.17,20.3c-4.73,0-8.55,4.52-8.55,10.09s3.82,10.1,8.55,10.1,8.55-4.52,8.55-10.1S28.89,20.3,24.17,20.3Zm0,16.42c-3,0-5.37-2.83-5.37-6.33s2.4-6.33,5.37-6.33,5.36,2.84,5.36,6.33S27.13,36.72,24.17,36.72Z" />
        </g>
        <g>
          <path fill="#fff" d="M50.5,85.21c-.59-1.07-5.8-4.61-5.8-4.61l.63-2.6c2.53-.74,4.54-3.78,5.33-7.12,1-4.24-.32-7.35-3.84-8.19S40.71,64,39.7,68.23c-.78,3.25-.4,6.76,1.39,8.62l-.63,2.6A62.16,62.16,0,0,0,33.83,81" />
          <path fill="#4b5467" d="M50.17,85.37A7.31,7.31,0,0,0,48.67,84l.47-.58A6.62,6.62,0,0,1,50.82,85M33.66,80.72a4.29,4.29,0,0,1,.85-.31l.21.71a5,5,0,0,0-.68.23m2.81-.76-.16-.72,2.17-.47.15.73Zm10.07,2c-.7-.51-1.36-1-1.8-1.28l.43-.61c.43.3,1.1.78,1.81,1.3ZM41,78.89l-.72-.17.4-1.72h0v-.18h.13l.37-.33.12.13.14.14Zm4.42.38-.72-.17.3-1.42.2-.06a4.53,4.53,0,0,0,.83-.34l.34.65a5.509,5.509,0,0,1-.75.33Zm-5.89-4.35a9.54,9.54,0,0,1-.5-2.24l.73-.08a9.11,9.11,0,0,0,.46,2.07Zm8.71,1.57-.54-.5a10,10,0,0,0,1.25-1.72l.64.37a10.49,10.49,0,0,1-1.37,1.85ZM39,70.4a14.44,14.44,0,0,1,.36-2.25l.71.17a14.269,14.269,0,0,0-.33,2.13Zm11.55,2.17-.69-.25a14.717,14.717,0,0,0,.47-1.55,3.33,3.33,0,0,1,.11-.53l.72.15a2.268,2.268,0,0,1-.12.55,13.28,13.28,0,0,1-.51,1.63Zm-9.8-6.28-.67-.3a8,8,0,0,1,1.23-2l.56.48a7.61,7.61,0,0,0-1.14,1.82Zm9.89,1.83a6.29,6.29,0,0,0-.34-2.05l.7-.25a7.23,7.23,0,0,1,.38,2.29Zm-7.16-4.85-.31-.67a5.29,5.29,0,0,1,2.31-.48v.74a4.44,4.44,0,0,0-2.02.41Zm5.75,1.07a4.74,4.74,0,0,0-1.72-1.09l.26-.69a5.34,5.34,0,0,1,2,1.26Z" />
        </g>
      </g>
    </g>
  </svg>


);

export default PersonalDocumentsSvg;
