import * as React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/* Styling */
import './header.component.css';

/* SVG's */
import HorizotalLogoSvg from '../../../components/svg/horizontalLogo.svg';
import HamburgerSvg from '../../../components/svg/hamburger.svg';


class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isPhone: false,
    };
  }

  componentDidMount() {
    this.checkForResponsive();
  }

  componentWillReceiveProps(props) {
  }


  toggle = () => {
    this.setState({ show: !this.state.show });
  }

  checkForResponsive = () => {
    const x = window.matchMedia('(max-width: 699px)');
    if (x.matches) {
      this.setState({ isPhone: true });

      if (process.env.NODE_ENV !== 'production') {
        console.log('isPhone');
      }
    } else {
      this.setState({ isPhone: false });
    }
  }

  render() {
    return (
      <div>
        <div style ={this.props.style}className={this.props.clear ? 'header clear' : 'header'}>
          <Link className="header-logo" to="">
            <HorizotalLogoSvg />
          </Link>
          {this.state.isPhone &&
            <div style={{
              backgroundColor: '#20D89B',
              height: '50%',
              width: '45%',
              maxWidth: 149,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderColor: '#20d89b',
              color: '#fff',
              boxShadow: '0px 4px 8px rgba(0,0,0,.16)',
              borderRadius: 4,
              zIndex:20
            }}
            >
              <Link
                to="home"
                className="text"
                style={{
                  color: '#fff', textDecorationLine: 'none', fontSize: '1.8vh',

                }}
              >BECOME A DRIVER
            </Link>
            </div>
          }
          <div className="header-links">
            <Link to="how-it-works" className="text">HOW IT WORKS</Link>
            <Link to="business" className="text">BUSINESS</Link>
            <Link to="home" className="text">BECOME A DRIVER</Link>
          </div>
          <div className="header-burger">
            <button
              className="hamburger-button"
              onClick={this.toggle}
            >
              <HamburgerSvg />
            </button>
          </div>
        </div>
        {this.state.show &&
          <div className={this.props.clear ? 'header-dropdown clear' : 'header-dropdown'}>
            <Link to="how-it-works" className="text">HOW IT WORKS</Link>
            <Link to="business" className="text">BUSINESS</Link>
            <Link to="home" className="text">BECOME A DRIVER</Link>
          </div>}
      </div>
    );
  }
}

HeaderComponent.propTypes = {
  clear: PropTypes.bool,
};

HeaderComponent.defaultProps = {
  clear: true,
};

export default HeaderComponent;
