import * as React from 'react';

const VehicleLicenceSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.4 93.4">
    <g transform="translate(-150.702 -11.31)">
      <circle fill="none" stroke="#ccd1db" strokeMiterlimit="10" strokeWidth="3px" cx="36.631" cy="36.631" r="36.631" transform="matrix(0.966, 0.259, -0.259, 0.966, 171.501, 13.147)" />
      <circle fill="none" stroke="#ccd1db" strokeMiterlimit="10" strokeWidth="3px" cx="31.766" cy="31.766" r="31.766" transform="matrix(0.966, 0.259, -0.259, 0.966, 175.106, 19.593)" />
      <line stroke="#ccd1db" strokeMiterlimit="10" strokeWidth="3px" fill="#fafbfc" strokeLinecap="round" y1="12.019" x2="44.501" transform="matrix(0.966, 0.259, -0.259, 0.966, 176.886, 41.699)" />
      <line stroke="#ccd1db" strokeMiterlimit="10" strokeWidth="3px" fill="#fafbfc" strokeLinecap="round" y1="11.304" x2="41.782" transform="matrix(0.966, 0.259, -0.259, 0.966, 179.168, 52.531)" />
      <line stroke="#ccd1db" strokeMiterlimit="10" strokeWidth="3px" fill="#fafbfc" strokeLinecap="round" y1="11.304" x2="41.782" transform="matrix(0.966, 0.259, -0.259, 0.966, 179.168, 62.547)" />
      <rect fill="none" stroke="#ccd1db" strokeMiterlimit="10" strokeWidth="3px" strokeLinecap="round" strokeLinejoin="round" width="25.756" height="6.439" transform="matrix(1, 0.008, -0.008, 1, 183.613, 38.818)" />
    </g>
  </svg>


);

export default VehicleLicenceSvg;
