import * as React from 'react';

const RightCarSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.042 58.59">
    <g transform="translate(1.039 1)">
      <path fill="none" stroke="#ccd1db" strokeWidth="2px" d="M.324,39.778C1.249,35.987,2.338,29.723,3.1,26.866a24.155,24.155,0,0,0,.762-5.219C4.135,10.219,7.185,5.714,9.036,2.253A4.135,4.135,0,0,1,12.684,0H56.791A18.7,18.7,0,0,1,61.8,1.978L82.82,16.922a4.535,4.535,0,0,0,2.015.769,3.161,3.161,0,0,1,.708.11l5.173,1.209,4.3,1.154a8.081,8.081,0,0,1,4.03,2.417.054.054,0,0,0,.054.055,9.177,9.177,0,0,1,1.688,2.582L104.927,41.7c.436,2.582-1.525,6.7-4.084,6.7H97.032a12.421,12.421,0,0,0,.327-2.857,13.178,13.178,0,1,0-26.355,0A12.421,12.421,0,0,0,71.33,48.4H30.218A12.852,12.852,0,1,0,4.789,45.876a18.948,18.948,0,0,0,.218,2.472H3.917C.324,48.788-.6,43.734.324,39.778ZM92.349,25.383c3.975,5.384,7.514,6.648,7.514,6.648a30.442,30.442,0,0,0-1.416-5.714,9.913,9.913,0,0,0-2.5-3.022c-1.525-1.154-5.881-1.7-5.881-1.7C90.334,23.021,92.349,25.383,92.349,25.383ZM30.981,14.614a2.941,2.941,0,0,0,2.94,2.967H70.459a2.463,2.463,0,0,0,1.416-4.45L58.86,3.791a4.476,4.476,0,0,0-2.614-.824H34.52a3.548,3.548,0,0,0-3.539,3.571ZM10.452,17.142H24.991a2.693,2.693,0,0,0,2.559-2.8V5.879a2.344,2.344,0,0,0-2.233-2.472H13.991a2.948,2.948,0,0,0-2.723,2.088L8.437,13.9A2.345,2.345,0,0,0,10.452,17.142Z" transform="translate(0 0)" />
      <g transform="translate(6.737 34.833)">
        <path fill="none" stroke="#ccd1db" strokeWidth="2px" d="M151.572,63.4a10.89,10.89,0,0,1,1.2,21.7,9.149,9.149,0,0,1-1.2.055,10.89,10.89,0,0,1-1.2-21.7c.381,0,.762-.055,1.2-.055ZM151.3,79.663a5.11,5.11,0,1,0-5.064-5.11,5.113,5.113,0,0,0,5.064,5.11Z" transform="translate(-140.778 -63.4)" />
      </g>
      <g transform="translate(73.387 34.833)">
        <path fill="none" stroke="#ccd1db" strokeWidth="2px" d="M29.172,63.4a10.89,10.89,0,0,1,1.2,21.7,9.149,9.149,0,0,1-1.2.055,10.89,10.89,0,0,1-1.2-21.7c.381,0,.817-.055,1.2-.055ZM28.9,79.663a5.11,5.11,0,1,0-5.064-5.11,5.113,5.113,0,0,0,5.064,5.11Z" transform="translate(-18.378 -63.4)" />
      </g>
    </g>
  </svg>
);

export default RightCarSvg;
