import * as React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

/* Styling */
import './dropzone.component.css';

/* Images */

class DropzoneComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  onDrop = (files) => {
    const [file] = files;
    this.props.onChange(file);
  }

  getFileSize = (bytes) => {
    if (bytes > 1000000) {
      return `${(bytes / 1000000).toFixed(2)}mb`;
    } else if (bytes > 1000) {
      return `${(bytes / 1000).toFixed(2)}kb`;
    }
    return `${bytes}b`;
  }

  render() {
    return (
      <div className="dropzone-container" style={this.props.style}>
        <div className="heading-container">
          <p className="heading text">{this.props.heading}</p>
        </div>
        <Dropzone
          className={this.props.file ? 'dropzone active' : 'dropzone'}
          activeClassName="hover"
          rejectClassName="error"
          onDrop={this.onDrop}
          multiple={false}
          maxSize={2097000}
          accept={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']}
        >
          <div className="svg-icon-container">
            {this.props.icon}
          </div>
          {!this.props.file &&
          <p className="text-bold">Drop your file here or click to select a file to upload</p>}
          {this.props.file &&
          <p className="text-bold active">{this.props.file.name} ({this.getFileSize(this.props.file.size)})</p>}
        </Dropzone>
      </div>
    );
  }
}

DropzoneComponent.propTypes = {
  file: PropTypes.any,
  onChange: PropTypes.func,
  heading: PropTypes.string,
  icon: PropTypes.any,
  style: PropTypes.any,
};

DropzoneComponent.defaultProps = {
  file: null,
  onChange: () => {},
  heading: 'DROPZONE',
  icon: <div />,
  style: {},
};
export default DropzoneComponent;
