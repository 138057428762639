import React from 'react';
import './banner.css';

const Banner = () => (
  <div className="banner_container">
    <p className="banner_text">
      For up to date information about covid-19, please visit the
      <a href="https://sacoronavirus.co.za"> official online and news portal.</a>
    </p>
  </div>
);


export default Banner;
