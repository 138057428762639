/**
# * @file: src/graphql/queries/getTrainingCategories.query.js
# * @description Get Training Categories Query File
# * @copyright: Verge Technologies 2018
# * @author Fabio Loreggian fabio@verge.co.za
# */
import gql from 'graphql-tag';

const GetProfile = gql`
    query GetProfile{
        getProfile{
            name
            surname
            phone
            email
            is_phone_confirmed
            is_email_confirmed
            type
            picture_url
            coordinate {
                lat
                long
            }
            review_rating
            vehicle_description
            vehicle_make
            vehicle_model
            vehicle_year
            number_plate
            is_privacy_policy_accepted
            is_terms_and_conditions_accepted
            boxel_points
            address
            address_coordinate {
                lat
                long
            }
            package_type
            date_of_birth
            id_document_number
            bank_details {
                name
                branch_code
                account_number
                account_type
            }
        }
}
`;

export default GetProfile;

