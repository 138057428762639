import * as React from 'react';

const HorizontalLogoSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.781 35">
    <g>
      <path fill="#20d89b" d="M3690.005-4240.6l-5.406-3.045v5.583l5.406,3.226,15.171-8.78v-5.584Z" transform="translate(-3674.863 4258.815)" />
      <path fill="#20d89b" d="M3690.005-4227.554l-5.406-3.046v5.584l5.406,3.226Z" transform="translate(-3674.863 4245.77)" />
      <path fill="#45547a" d="M3656.867-4257.957l-.03-12.125,10.393-5.793,6.3,3.763,4.749-2.867-11.14-6.421L3652-4272.65v17.5l15.141,8.75,15.171-8.75v-5.583l-15.171,8.72Z" transform="translate(-3652 4281.4)" />
      <path fill="#45547a" d="M3656.867-4257.957l-.03-12.125,10.393-5.793,6.3,3.763,4.749-2.867-11.14-6.421L3652-4272.65v17.5l15.141,8.75v-5.613Z" transform="translate(-3652 4281.4)" />
      <path fill="#45547a" d="M3656.838-4270.082l10.393-5.793,6.3,3.763,4.749-2.867-11.14-6.421L3652-4272.65Z" transform="translate(-3652 4281.4)" />
    </g>
    <path fill="#45547a" d="M3793.9-4240.718a4.194,4.194,0,0,1,.821,2.667,3.878,3.878,0,0,1-1.671,3.371,8.427,8.427,0,0,1-4.894,1.2H3779.8V-4250.3h7.883a7.686,7.686,0,0,1,4.6,1.172,3.728,3.728,0,0,1,1.612,3.194,4.168,4.168,0,0,1-.586,2.2,4.082,4.082,0,0,1-1.582,1.495A4.454,4.454,0,0,1,3793.9-4240.718Zm-10.99-7.149v4.63h4.425a4.634,4.634,0,0,0,2.52-.587,1.934,1.934,0,0,0,.879-1.73,1.934,1.934,0,0,0-.879-1.728,4.653,4.653,0,0,0-2.52-.585Zm8.675,9.494c0-1.612-1.2-2.432-3.634-2.432h-5.04v4.864h5.04C3790.35-4235.941,3791.581-4236.761,3791.581-4238.373Z" transform="translate(-3743.316 4260.387)" />
    <path fill="#45547a" d="M3840.812-4224.963a6.051,6.051,0,0,1-2.432-2.344,6.6,6.6,0,0,1-.879-3.4,6.6,6.6,0,0,1,.879-3.4,6.442,6.442,0,0,1,2.432-2.344,7.337,7.337,0,0,1,3.517-.85,7.458,7.458,0,0,1,3.546.85,6.041,6.041,0,0,1,2.432,2.344,6.529,6.529,0,0,1,.879,3.4,6.532,6.532,0,0,1-.879,3.4,6.244,6.244,0,0,1-2.432,2.344,7.458,7.458,0,0,1-3.546.85A7.787,7.787,0,0,1,3840.812-4224.963Zm6.241-2.842a3.913,3.913,0,0,0,1.085-2.9,3.912,3.912,0,0,0-1.085-2.9,3.71,3.71,0,0,0-2.725-1.113,3.62,3.62,0,0,0-2.725,1.113,4.014,4.014,0,0,0-1.055,2.9,4.016,4.016,0,0,0,1.055,2.9,3.625,3.625,0,0,0,2.725,1.113A3.624,3.624,0,0,0,3847.053-4227.8Z" transform="translate(-3784.107 4251.197)" />
    <path fill="#45547a" d="M3896.5-4223.864l-3.282-4.454-3.311,4.454H3886.6l5.012-6.505-4.777-6.331h3.341l3.135,4.191,3.136-4.191h3.253l-4.806,6.271,5.011,6.564Z" transform="translate(-3818.816 4250.773)" />
    <path fill="#45547a" d="M3947.4-4229.84h-10.081a3.58,3.58,0,0,0,1.377,2.256,4.478,4.478,0,0,0,2.784.821,4.661,4.661,0,0,0,3.487-1.406l1.612,1.846a5.4,5.4,0,0,1-2.2,1.553,8.013,8.013,0,0,1-2.989.527,8.013,8.013,0,0,1-3.721-.85,6.025,6.025,0,0,1-2.491-2.344,6.605,6.605,0,0,1-.879-3.4,6.768,6.768,0,0,1,.85-3.369,5.9,5.9,0,0,1,2.374-2.345,6.894,6.894,0,0,1,3.4-.849,6.553,6.553,0,0,1,3.34.821,5.74,5.74,0,0,1,2.315,2.344,7.009,7.009,0,0,1,.821,3.458A3.316,3.316,0,0,1,3947.4-4229.84Zm-8.88-4.25a3.5,3.5,0,0,0-1.2,2.257h7.268a3.544,3.544,0,0,0-1.172-2.257,3.518,3.518,0,0,0-2.461-.879A3.754,3.754,0,0,0,3938.52-4234.09Z" transform="translate(-3852.538 4251.268)" />
    <path fill="#45547a" d="M3989-4253.8h2.989v17.817H3989Z" transform="translate(-3891.208 4262.861)" />
  </svg>
);

export default HorizontalLogoSvg;
