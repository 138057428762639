import * as React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

/* Styling */
import './input.component.css';

/* SVG's */
import ChevronSvg from '../../svg/chevron.svg';
import LocationSvg from '../../svg/location.svg';

/* Images */

class InputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      open: false,
      error: false,
    };
  }

  componentDidMount() {
  }

  onChange = (value) => {
    const { valid, onChange } = this.props;
    if (valid) {
      const error = !valid(value) && value !== '';
      onChange(value, error);
      this.setState({ error });
    } else {
      onChange(value);
    }
  }

  openDatePicker = () => {
    if (this.date && !this.props.disabled) {
      this.date.setOpen(true);
      this.onFocus();
    }
  }

  openDropdown = () => {
    if (this.props.disabled) return;
    this.setState({ open: true });
    this.onFocus();
  }

  onFocus = () => {
    if (this.props.disabled) return;
    this.setState({ focused: true });

    if (this.props.dropdown && !this.state.open) {
      this.setState({ open: true });
    }

    if (this.props.date && this.date) {
      this.date.setOpen(true);
    }

    if (this.input) {
      this.input.focus();
    }

    this.props.onFocus();
  }

  onBlur = () => {
    this.setState({ focused: false });
    this.props.onBlur();
  }

  getBorderColor = () => {
    if (this.state.error) {
      return 'red';
    }
    if (this.state.focused) {
      return '#20D89B';
    }
    return '#F5F5F5';
  }

  getClassName = (className) => {
    let updatedClassName = className;
    if (className === 'input-container' && this.props.card) {
      updatedClassName += ' card';
    }

    if (this.props.disabled) {
      return `${updatedClassName} disabled`;
    }
    return updatedClassName;
  }

  render() {
    const {
      heading, value, placeholder, dropdown, error, date, readonly, disabled, address,
    } = this.props;
    return (
      <div>
        <div
          className={this.getClassName('input-container')}
          style={{ borderColor: this.getBorderColor() }}
          onClick={this.onFocus}
        >
          {heading &&
            <p className={this.getClassName('heading text')}>{heading}</p>}

          {!this.state.open && !this.props.textarea &&
          <input
            ref={(input) => { this.input = input; }}
            className={this.getClassName('input text-bold')}
            style={{ paddingRight: this.props.address ? '60px' : '0px' }}
            value={value}
            onChange={(event) => {
              this.onChange(event.target.value);
            }}
            placeholder={placeholder}
            readOnly={dropdown || date || readonly || disabled}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            type={this.props.type}
          />}

          {!this.state.open && this.props.textarea &&
          <textarea
            ref={(input) => { this.input = input; }}
            className={this.getClassName('input text-bold')}
            style={{ paddingRight: this.props.address ? '60px' : '0px' }}
            value={value}
            onChange={(event) => {
              this.onChange(event.target.value);
            }}
            placeholder={placeholder}
            readOnly={dropdown || date || readonly || disabled}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            type={this.props.type}
            rows={5}
          />}

          {address &&
          <button
            className="address-icon"
            onClick={this.onFocus}
          >
            <LocationSvg />
          </button>}

          {dropdown &&
          <button
            className="dropdown-icon"
            onClick={this.openDropdown}
          >
            <ChevronSvg direction={this.state.open ? 'UP' : 'DOWN'} />
          </button>}

          {dropdown && this.state.open &&
          <div
            className="dropdown"
          >
            <p className="dropdown-select text">Select an option</p>
            {dropdown.map(item => (
              <button
                key={item.value}
                className="dropdown-item text-bold"
                onClick={() => {
                  setTimeout(() => {
                    this.setState({ open: false, focused: false });
                    this.onChange(item);
                  });
                }}
              >
                {item.name}
              </button>
            ))}
            <p />
          </div>}

          {date &&
          <button
            className="dropdown-icon"
            onClick={this.openDatePicker}
          >
            <ChevronSvg direction={this.state.open ? 'UP' : 'DOWN'} />
          </button>}
        </div>
        {date &&
        <div className="date-container">
          <DatePicker
            ref={(ref) => { this.date = ref; }}
            onChange={(val) => {
              this.onChange(val);
            }}
            openToDate={moment('1990-01-01')}
            className="hidden-date"
            showYearDropdown
            showMonthDropdown
          />
        </div> }
        {this.state.error && error &&
        <div className="input-error text">{error}</div>}
      </div>
    );
  }
}

InputComponent.propTypes = {
  heading: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  dropdown: PropTypes.array,
  valid: PropTypes.func,
  date: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  card: PropTypes.bool,
  address: PropTypes.bool,
  type: PropTypes.string,
  textarea: PropTypes.bool,
};

InputComponent.defaultProps = {
  heading: null,
  placeholder: 'Input',
  dropdown: null,
  valid: null,
  date: false,
  disabled: false,
  readonly: false,
  card: false,
  address: false,
  type: 'text',
  textarea: false,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
};


export default InputComponent;
