import * as React from 'react';

const DriversSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.052 57.79">
    <g transform="matrix(1, -0.017, 0.017, 1, -1103.451, -583.622)">
      <rect fill="none" stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" width="54.156" height="83.635" rx="5.67" transform="translate(1178.201 605.789) rotate(90.58)" />
      <line stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" fill="#4eb588" strokeLinecap="round" x2="32.537" y2="7.171" transform="matrix(0.978, -0.208, 0.208, 0.978, 1103.672, 620.13)" />
      <line stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" fill="#4eb588" strokeLinecap="round" x2="32.537" y2="7.186" transform="matrix(0.978, -0.208, 0.208, 0.978, 1102.618, 629.437)" />
      <line stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" fill="#4eb588" strokeLinecap="round" x2="32.521" y2="7.186" transform="matrix(0.978, -0.208, 0.208, 0.978, 1101.802, 638.396)" />
      <line stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" fill="#4eb588" strokeLinecap="round" x2="32.521" y2="7.186" transform="matrix(0.978, -0.208, 0.208, 0.978, 1101.23, 647.833)" />
      <g transform="matrix(0.978, -0.208, 0.208, 0.978, 1138.184, 621.748)">
        <path fill="none" stroke="#ccd1db" strokeWidth="3px" d="M25.488,34.707c-.9-1.636-8.868-7.049-8.868-7.049l.963-3.975c3.868-1.131,6.942-5.78,8.149-10.886C27.262,6.314,25.243,1.559,19.861.275s-9.342,2-10.886,8.471c-1.193,4.969-.612,10.336,2.125,13.18L10.137,25.9A95.04,95.04,0,0,0,0,28.27" transform="translate(0 1.373) rotate(-3)" />
      </g>
    </g>
  </svg>
);

export default DriversSvg;
