import * as React from 'react';

const LeftCarSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.984 58.59">
    <g transform="translate(1.026 1)">
      <path fill="none" stroke="#ccd1db" strokeWidth="2px" d="M105.592,39.778c-.934-3.791-2.033-10.054-2.8-12.911a23.955,23.955,0,0,1-.769-5.219C101.747,10.219,98.67,5.714,96.8,2.253A4.176,4.176,0,0,0,93.121,0h-44.5a18.963,18.963,0,0,0-5.055,1.978L22.356,16.922a4.6,4.6,0,0,1-2.033.769,3.215,3.215,0,0,0-.714.11L14.389,19.01l-4.34,1.154a8.168,8.168,0,0,0-4.066,2.417.054.054,0,0,1-.055.055,9.17,9.17,0,0,0-1.7,2.582L.05,41.7c-.44,2.582,1.538,6.7,4.121,6.7H8.016a12.316,12.316,0,0,1-.33-2.857,13.3,13.3,0,1,1,26.592,0,12.316,12.316,0,0,1-.33,2.857H75.43a12.717,12.717,0,0,1-.275-2.527,12.966,12.966,0,0,1,25.932,0,18.783,18.783,0,0,1-.22,2.472h1.1C105.592,48.788,106.526,43.734,105.592,39.778ZM12.741,25.383c-4.011,5.384-7.582,6.648-7.582,6.648a30.216,30.216,0,0,1,1.428-5.714A9.928,9.928,0,0,1,9.115,23.3c1.538-1.154,5.934-1.7,5.934-1.7C14.774,23.021,12.741,25.383,12.741,25.383ZM74.66,14.614a2.955,2.955,0,0,1-2.967,2.967H34.828a2.459,2.459,0,0,1-1.428-4.45L46.53,3.791a4.544,4.544,0,0,1,2.637-.824H71.089A3.564,3.564,0,0,1,74.66,6.538Zm20.713,2.527H80.7a2.7,2.7,0,0,1-2.582-2.8V5.879a2.352,2.352,0,0,1,2.253-2.472H91.8a2.97,2.97,0,0,1,2.747,2.088L97.406,13.9A2.346,2.346,0,0,1,95.373,17.142Z" transform="translate(0 0)" />
      <g transform="translate(77.341 34.833)">
        <path fill="none" stroke="#ccd1db" strokeWidth="2px" d="M151.669,63.4a10.884,10.884,0,0,0-1.209,21.7,9.313,9.313,0,0,0,1.209.055,10.884,10.884,0,0,0,1.209-21.7c-.385,0-.769-.055-1.209-.055Zm.275,16.263a5.11,5.11,0,1,1,5.11-5.11,5.135,5.135,0,0,1-5.11,5.11Z" transform="translate(-140.778 -63.4)" />
      </g>
      <g transform="translate(10.092 34.833)">
        <path fill="none" stroke="#ccd1db" strokeWidth="2px" d="M29.269,63.4A10.884,10.884,0,0,0,28.06,85.1a9.313,9.313,0,0,0,1.209.055,10.884,10.884,0,0,0,1.209-21.7c-.385,0-.824-.055-1.209-.055Zm.275,16.263a5.11,5.11,0,1,1,5.11-5.11,5.135,5.135,0,0,1-5.11,5.11Z" transform="translate(-18.378 -63.4)" />
      </g>
    </g>
  </svg>


);

export default LeftCarSvg;
