import * as React from 'react';
import { compose, withApollo } from 'react-apollo';

/* Styling */
import './business.web.page.css';

/* Components */
import Header from '../../../components/web/header/header.component';
import Button from '../../../components/web/button/button.component';
import Footer from '../../../components/web/footer/footer.component';
import Ellipse from '../../../components/web/ellipse/ellipse.component';

/* SVG's */
import BusinessSvg from '../../../components/svg/businessSvg.svg';

/* GraphQL */

/* Asssets */
const legalDocs = require('../../../assets/images/Transfer.png');
const bulkDelivery = require('../../../assets/images/Bulk.png');
const buildingMaterials = require('../../../assets/images/BuildingMaterials.png');
const refuseRemoval = require('../../../assets/images/GardenRefuse.png');
const individualOrder = require('../../../assets/images/Individual.png');

/* Images */
const googlePlay = require('../../../assets/images/googlePlay.png');
const appleStore = require('../../../assets/images/appleStore.png');

class BusinessWebPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header />
        <div className="web-business">
          <div className="header-image-container">
            <Ellipse>
              <div className="header-image">
                <div>
                  <h1 className="text-bold">On-Demand Deliveries for Your Business</h1>
                  <p className="text">Make your business more efficient</p>
                  <br />
                  <div id="heading-actions">
                    <Button
                      text="LEARN MORE"
                      onClick={() => {
                      this.props.history.push('how-it-works');
                    }}
                    />
                    <div className="stores">
                      <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.boxel.client">
                        <img src={googlePlay} alt="Google Play Store" />
                      </a>
                      <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/za/app/boxel-client/id1438710700?mt=8">
                        <img src={appleStore} alt="Apple Store" />
                      </a>
                    </div>
                  </div>

                </div>
              </div>
            </Ellipse>
          </div>
          <div className="content-container">
            <BusinessSvg />
            <h1 className="text-bold">Boxel for Business</h1>
            <p className="text">Transferring legal documents. Delivering construction materials. Shipping your hand-made items to the local flea-market. Make your business more efficient than ever before by planning deliveries that suit your business model.  Whenever you need them, wherever you need them.</p>
          </div>
          <div className="business-ellipse">
            <Ellipse>
              <div className="business-ellipse-content">
                <h1>How Boxel Can Help Your Business</h1>
                <div className="help-images">
                  <div className="help-image" style={{ backgroundImage: `url(${legalDocs})` }}>
                    <div className="hr" />
                    <p className="text">Physical Transfer of</p>
                    <p className="text">Legal Documents</p>
                  </div>
                  <div className="help-image" style={{ backgroundImage: `url(${bulkDelivery})` }}>
                    <div className="hr" />
                    <p className="text">Bulk Product</p>
                    <p className="text">Delivery</p>
                  </div>
                  <div className="help-image" style={{ backgroundImage: `url(${buildingMaterials})` }}>
                    <div className="hr" />
                    <p className="text">Delivery of</p>
                    <p className="text">Building Materials</p>
                  </div>
                  <div className="help-image" style={{ backgroundImage: `url(${refuseRemoval})` }}>
                    <div className="hr" />
                    <p className="text">Removal of</p>
                    <p className="text">Garden Refuse</p>
                  </div>
                  <div className="help-image" style={{ backgroundImage: `url(${individualOrder})` }}>
                    <div className="hr" />
                    <p className="text">Individual Order</p>
                    <p className="text">Delivery</p>
                  </div>
                </div>
              </div>
            </Ellipse>
          </div>
          <div className="business-info-container">
            <div className="business-card">
              <h1 className="text-bold">LEGAL</h1>
              <p className="text">Ensure that sensitive documents end up in the right hands. Be it a single page contract or a detailed casebook for court.</p>
            </div>

            <div className="business-card">
              <h1 className="text-bold">CONSTRUCTION & MAINTENANCE</h1>
              <p className="text">Delivering bricks or removing rubble, arrange vehicles to do the job for you. Saving you time and money, so your business can stay focused on the important things.</p>
            </div>

            <div className="business-card">
              <h1 className="text-bold">RETAIL</h1>
              <p className="text">Your products are made with love, we’ll make sure they’re transported with care. Whether its individual orders or a bulk stock drop-off. Rest assured that your brand is in good hands.</p>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}


export default compose(withApollo)(BusinessWebPage);

