import * as React from 'react';

/* Styling */
import './card.style.css';


class CardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className={`delivery-card ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}

CardComponent.defaultProps = {
  className: '',
};


export default CardComponent;
