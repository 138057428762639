/**
# * @file: src/graphql/mutations/confirmOtp.mutation.js
# * @description Confirm OTP Mutation File
# * @copyright: Verge Technologies 2018
# * @author Fabio Loreggian fabio@verge.co.za
# */
import gql from 'graphql-tag';

const ConfirmOtp = gql`
    mutation ConfirmOtp($otp: String!){
        confirmOtp(otp: $otp)
    }
`;
export default ConfirmOtp;
