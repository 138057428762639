export const setValue = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getValue = key => JSON.parse(localStorage.getItem(key));

export const clear = () => {
  localStorage.clear();
};

