import * as React from 'react';
import { compose, withApollo } from 'react-apollo';
import _ from 'lodash';
import { DefaultPlayer as Video } from 'react-html5video';

/* Styling */
import './home.web.page.css';
import './video.css';
/* Components */
import Header from '../../../components/web/header/header.component';
import Button from '../../../components/web/button/button.component';
import Footer from '../../../components/web/footer/footer.component';
import Ellipse from '../../../components/web/ellipse/ellipse.component';

/* SVG's */
import DoorToDoorSvg from '../../../components/svg/doorToDoorSvg.svg';
import DemandSvg from '../../../components/svg/demandSvg.svg';
import ConvienientSvg from '../../../components/svg/convienientSvg.svg';
import VersatileSvg from '../../../components/svg/versatileSvg.svg';
import AffordableSvg from '../../../components/svg/affordableSvg.svg';
import ReliableSvg from '../../../components/svg/reliableSvg.svg';
import ArrowSvg from '../../../components/svg/arrowSvg.svg';

import ScooterWireSvg from '../../../components/svg/scooterWireSvg.svg';
import LightVehicleWireSvg from '../../../components/svg/lightVehicleWireSvg.svg';
import BakkieWireSvg from '../../../components/svg/bakieWireSvg.svg';
import VanWireSvg from '../../../components/svg/vanWireSvg.svg';
import TruckWireSvg from '../../../components/svg/truckWireSvg.svg';
import { validatePhone } from '../../../common/utils';
import PhotoCarousel from '../../../components/Photocarousel/PhotoCarousel';

/* GraphQL */
import SendInvite from '../../../graphql/queries/sendInvite.query';

/* Asssets */
const clientOne = require('../../../assets/images/clientOne.png');
const scooterOne = require('../../../assets/images/vehicles/scooter_img_1.png');
const scooterTwo = require('../../../assets/images/vehicles/scooter_img_2.png');
const scooterThree = require('../../../assets/images/vehicles/scooter_img_3.png');

const lightOne = require('../../../assets/images/vehicles/light_img_1.png');
const lightTwo = require('../../../assets/images/vehicles/light_img_2.png');
const lightThree = require('../../../assets/images/vehicles/light_img_3.png');

const bakkieOne = require('../../../assets/images/vehicles/bakkie_img_1.png');
const bakkieTwo = require('../../../assets/images/vehicles/bakkie_img_2.png');
const bakkieThree = require('../../../assets/images/vehicles/bakkie_img_3.png');

const vanOne = require('../../../assets/images/vehicles/van_img_1.png');
const vanTwo = require('../../../assets/images/vehicles/van_img_2.png');
const vanThree = require('../../../assets/images/vehicles/van_img_3.png');

const truckOne = require('../../../assets/images/vehicles/truck_img_1.png');
const truckTwo = require('../../../assets/images/vehicles/truck_img_2.png');
const truckThree = require('../../../assets/images/vehicles/truck_img_3.png');


/* Images */
const googlePlay = require('../../../assets/images/googlePlay.png');
const appleStore = require('../../../assets/images/appleStore.png');

/* Feature Images */
const featureOne = require('../../../assets/images/featureImg.png');

/* Video */
const ziziVideo = require('../../../assets/videos/BOXEL ZIZI WEB.mp4');

class HomeWebPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feature: 0,
      client: 0,
      vehicle: 0,
      cellphone: '',
    };


    
    this.clients = [
      {
        name: 'SIPHO TSHABALALA',
        text: '“As a small business owner it can be tough to arrange deliveries for all my clients that suits both of our schedules. Boxel has made it possible for me to run my busines at my own pace and keep within my budget.”',
        img: `url(${clientOne})`,
      },
      {
        name: 'MARUCELLE JACOBS',
        text: '“I use this service everyday. It is much cheaper then all their competition.”',
        img: `url(${clientOne})`,
      },
      {
        name: 'ROBERTO MAURIZZIO',
        text: '“This service has provided me with the ease to expand my own business by not worrying about simple deliveries that I need to do everyday. Boxel has provided me with the means to expand.”',
        img: `url(${clientOne})`,
      },
    ];

    this.vehicleTypes = [
      {
        type: 'SCOOTER',
        heading: 'Light, Compact and Quick',
        text: 'Fast and fuel-efficient, scooters are the best choice for small deliveries at a moment’s notice.\n\nOur scooters’ delivery storage boxes have inner dimensions of 40cm wide and 40cm deep and can carry 10kg worth of items.',
        icon: <ScooterWireSvg />,
        images: [
          {
            text: 'Documents',
            source: `url(${scooterOne})`,
          },
          {
            text: 'Mail',
            source: `url(${scooterTwo})`,
          },
          {
            text: 'Meal orders',
            source: `url(${scooterThree})`,
          },
        ],
      },
      {
        type: 'LIGHT VEHICLE',
        heading: 'Compact and Economic',
        text: 'Able to transport goods through all weather, light vehicles are best for fragile items and larger goods.\n\nWith a capacity of 10kg, our light vehicles can take anything that happens to be just too bulky or too delicate for a scooter\'s box.',
        icon: <LightVehicleWireSvg />,
        images: [
          {
            text: 'Groceries',
            source: `url(${lightOne})`,
          },
          {
            text: 'Gifts',
            source: `url(${lightTwo})`,
          },
          {
            text: 'Fragile Items',
            source: `url(${lightThree})`,
          },
        ],
      },
      {
        type: 'BAKKIE',
        heading: 'Hardy and Versatile',
        text: 'With their powerful engines and high carrying capacity, bakkies are well suited to ride on any terrain and carry heavy loads.\n\nThe roofless trunks allow for transporting weather-resistant materials and items too tall to fit in a van.',
        icon: <BakkieWireSvg />,
        images: [
          {
            text: 'Building Supplies',
            source: `url(${bakkieOne})`,
          },
          {
            text: 'Garden Refuse',
            source: `url(${bakkieTwo})`,
          },
          {
            text: 'Event Equipment',
            source: `url(${bakkieThree})`,
          },
        ],
      },
      {
        type: 'VAN',
        heading: 'Secure and Spacious',
        text: 'Our vans\' enclosed, windowless storage areas make them a secure way to transfer larger items in greater amounts.\n\nThey are perfect for larger deliveries that still require some TLC when getting from point A to B.',
        icon: <VanWireSvg />,
        images: [
          {
            text: 'Home and Industry Products',
            source: `url(${vanOne})`,
          },
          {
            text: 'Luggage',
            source: `url(${vanTwo})`,
          },
          {
            text: 'Electronics',
            source: `url(${vanThree})`,
          },
        ],
      },
      {
        type: 'TRUCK',
        heading: 'Classic and Multipurpose',
        text: 'When it comes to moving things on a large scale, a truck is the go to option.\n\nSturdy and ready to carry almost anything, our trucks are your best choice for bulk deliveries and  transporting heavy items.',
        icon: <TruckWireSvg />,
        images: [
          {
            text: 'Stock and Merchandise',
            source: `url(${truckOne})`,
          },
          {
            text: 'Furniture',
            source: `url(${truckTwo})`,
          },
          {
            text: 'Raw Materials',
            source: `url(${truckThree})`,
          },
        ],
      },
    ];
  }

  componentDidMount() {
    this.clientsTimer = setInterval(() => {
      let client = this.state.client + 1;
      if (client === this.clients.length) {
        client = 0;
      }
      this.setState({ client });
    }, 5000);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearInterval(this.clientsTimer);
  }
  sendCellNumber = () => {
    if (validatePhone(this.state.cellphone)) {
      let phone = this.state.cellphone;
      if (phone[0] === '0') {
        phone = `27${phone.substring(1)}`;
      }

      console.log(phone);

      const options = {
        query: SendInvite,
        variables: {
          phone,
        },
      };

      this.props.client.query(options).then((res) => {
        console.log(res);
        this.setState({ cellphone: '' });
      }).catch((err) => {
        console.log(err);
        this.setState({ cellphone: '' });
      });
    }

    this.setState({ cellphone: '' });
  }

  getVehicleTypeContent = (large) => {
    let className = 'vehicles-content-container';
    if (large) {
      className += ' large';
    } else {
      className += ' small';
    }

    return this.vehicleTypes.map((vehicleType, index) => (
      <div
        key={vehicleType.type}
        className={this.state.vehicle === index ? `selected ${className}` : className}
      >
        <div className="vehicle-content">
          <h2 className="text">{vehicleType.type}</h2>
          <h1 className="text-bold">{vehicleType.heading}</h1>
          <p className="text">{vehicleType.text}</p>
        </div>
        <div className="vehicle-images">
          <div className="v-image image-row-50" style={{ backgroundImage: vehicleType.images[0].source }}>
            <p className="text">{vehicleType.images[0].text}</p>
          </div>
          <div className="smaller-images">
            <div className="v-image image-col-50" style={{ backgroundImage: vehicleType.images[1].source }}>
              <p className="text">{vehicleType.images[1].text}</p>
            </div>
            <div className="v-image image-col-50" style={{ backgroundImage: vehicleType.images[2].source }}>
              <p className="text">{vehicleType.images[2].text}</p>
            </div>
          </div>
        </div>
      </div>
    ));
  }

  getFeatureBackgroundImage = () => `url(${featureOne})`

  getClientImage = () => this.clients[this.state.client].img

  getClientText = () => this.clients[this.state.client].text

  getClientName = () => this.clients[this.state.client].name

  getClientIndictors = () => this.clients.map((client, index) => (
    <div key={client.name} className={this.state.client === index ? 'indicator selected' : 'indicator'} />));

  render() {
    return (
      <div>
        <Header style={{backgroundColor:'#45547A'}} />
        <div className="web-home">
          <div className="header-image-container">
            <Ellipse>
              <div className="header-image">
                <div className="opacity-cover"/>
                  <div className="header-text-container">
                    <h1 className="text-bold text-shadow" >Download our Boxel client app</h1>
                    <p className="text">Your delivery guy in your pocket</p>
                    <div className="footer-contact">
                      <div className="stores">
                        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.boxel.client">
                          <img src={googlePlay} alt="Google Play Store" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/za/app/boxel-client/id1438710700?mt=8">
                          <img src={appleStore} alt="Apple Store" />
                        </a>
                      </div>
                      <p className="text">OR</p>
                      <p className="text">Enter your cellphone number and we'll</p>
                      <p className="text">send you a download link for Boxel.</p>
                      <div className="footer-input">
                        <input
                          value={this.state.cellphone}
                          onChange={event => this.setState({ cellphone: event.target.value })}
                          placeholder="Cellphone number"
                          className="text"
                          maxLength={12}
                        />
                        <button onClick={this.sendCellNumber} disabled={!validatePhone(this.state.cellphone)}>
                          <ArrowSvg />
                        </button>
                      </div>
                    </div>
                    <br />
                    <div id="heading-actions">
                      <Button
                        text="LEARN MORE"
                        onClick={() => {
                          this.props.history.push('how-it-works');
                        }}
                      />
                    </div>
                  </div>
                </div>
            </Ellipse>
          </div>
          <div className="content-container">
            <DoorToDoorSvg />
            <h1 className="text-bold">Door-to-door Done Your Way</h1>
            <p className="text">We fetch and deliver for you, whether it’s a letter from you to a friend or a beer keg from a shop to your office. Need it done ASAP or only need it in a month? Schedule your deliveries in advance or request them on the fly. Our drivers work to your agenda, with the vehicle best suited to your requirements.</p>
            <div style={{ width: '50%', alignSelf: 'center', marginTop: '2.67em' }}>
              <Video
                loop
                controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
              >
                <source src={ziziVideo} type="video/mp4" />
              </Video>
            </div>
          </div>
          <div className="carousel-container">
            <Ellipse>
              <div className="grey-container">
                <div className="vehicles-container">
                  {this.getVehicleTypeContent(true)}
                  <div className="icons-container">

                    {this.vehicleTypes.map((vehicleType, index) => (
                      <div key={`${vehicleType.type}icon`} className="icon-container">
                        <button
                          className={this.state.vehicle === index ? `selected ${vehicleType.type.replace(' ', '')}` : `${vehicleType.type.replace(' ', '')}`}
                          onClick={() => { this.setState({ vehicle: index }); }}
                        >
                          {vehicleType.icon}
                          <p className="text">{_.capitalize(vehicleType.type)}</p>
                        </button>
                        {this.state.vehicle === index && this.getVehicleTypeContent(false)}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="content-container">
                  <DemandSvg />
                  <h1 className="text-bold">Deliveries on Demand</h1>
                  <p className="text">Too busy to fetch or pick-up items? Don’t have the right transport for it? No stress. A few taps is all it takes to get what you need from A to B with Boxel.</p>
                </div>

              </div>
            </Ellipse>
          </div>
          <div className="features-container">
            <div className="feature-content">
              <button onClick={() => { this.setState({ feature: 0 }); }} className={this.state.feature === 0 ? 'feature selected' : 'feature'}>
                <ConvienientSvg />
                <div className="feature-text">
                  <h3 className="text-bold">CONVIENIENT</h3>
                  <p className="text">Ship and track same-city parcels, documents or packages from any device. Get your items delivered straight away, or arranged to suit your schedule.</p>
                </div>
              </button>
              <button onClick={() => { this.setState({ feature: 1 }); }} className={this.state.feature === 1 ? 'feature selected' : 'feature'}>
                <VersatileSvg />
                <div className="feature-text">
                  <h3 className="text-bold">VERSATILE</h3>
                  <p className="text">Whether you have deliveries to do for work or need to run a few personal errands.</p>
                </div>
              </button>
              <button onClick={() => { this.setState({ feature: 2 }); }} className={this.state.feature === 2 ? 'feature selected' : 'feature'}>
                <AffordableSvg />
                <div className="feature-text">
                  <h3 className="text-bold">AFFORDABLE</h3>
                  <p className="text">Priced to give you quality service without breaking the bank.</p>
                </div>
              </button>
              <button onClick={() => { this.setState({ feature: 3 }); }} className={this.state.feature === 3 ? 'feature selected' : 'feature'}>
                <ReliableSvg />
                <div className="feature-text">
                  <h3 className="text-bold">RELIABLE</h3>
                  <p className="text">Realtime tracking ensures you and your recipient know exactly where your delivery is on its journey.</p>
                </div>
              </button>
            </div>
            <div className="feature-img" style={{ backgroundImage: this.getFeatureBackgroundImage() }} />
          </div>
          {/* <PhotoCarousel /> */}
          <Footer />
        </div>
      </div>
    );
  }
}


export default compose(withApollo)(HomeWebPage);
