import * as React from 'react';

const Svg = props => (
  <svg width={40} height={75} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.43 40.348">
    <g transform="translate(0.771 0.75)">
      <path fill="none" stroke="#20d89b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px" d="M72.484,27.307c-.641-2.6-1.4-6.9-1.924-8.863a16.445,16.445,0,0,1-.528-3.583c-.189-7.845-2.3-10.938-3.583-13.314A2.867,2.867,0,0,0,63.922,0H33.372A13.018,13.018,0,0,0,29.9,1.358L15.343,11.617a3.158,3.158,0,0,1-1.4.528,2.207,2.207,0,0,0-.49.075l-3.583.83-2.98.792A5.607,5.607,0,0,0,4.1,15.5a.037.037,0,0,1-.038.038A6.3,6.3,0,0,0,2.9,17.312L.03,28.627c-.3,1.773,1.056,4.6,2.829,4.6H4.922v-2.14c0-5.054,3.038-9.451,9.4-9.485,5.621-.305,9.737,4.779,9.84,9.212a12.777,12.777,0,0,1-.252,2.414H50.922a8.731,8.731,0,0,1-.189-1.735,9.5,9.5,0,0,1,9.757-9.756c6.363-.108,9.757,4.853,9.757,9.756a12.9,12.9,0,0,1-.151,1.7h.754C73.339,33.492,73.125,30.022,72.484,27.307ZM8.743,17.425c-2.753,3.7-5.2,4.564-5.2,4.564a20.743,20.743,0,0,1,.981-3.923,6.816,6.816,0,0,1,1.735-2.074c1.056-.792,4.073-1.169,4.073-1.169A8.473,8.473,0,0,1,8.743,17.425ZM51.25,10.033a2.028,2.028,0,0,1-2.037,2.037H23.905a1.688,1.688,0,0,1-.981-3.055L31.939,2.6a3.119,3.119,0,0,1,1.81-.566H48.8A2.447,2.447,0,0,1,51.25,4.488Zm14.219,1.735H55.4a1.856,1.856,0,0,1-1.773-1.924V4.036a1.615,1.615,0,0,1,1.546-1.7h7.845A2.039,2.039,0,0,1,64.9,3.772l1.961,5.771A1.611,1.611,0,0,1,65.469,11.768Z" transform="translate(0)" />
      <g transform="translate(53.09 23.912)">
        <path fill="none" stroke="#20d89b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px" d="M148.254,63.4a7.472,7.472,0,0,0-.83,14.9,6.393,6.393,0,0,0,.83.038,7.472,7.472,0,0,0,.83-14.9c-.264,0-.528-.038-.83-.038Zm.189,11.164a3.508,3.508,0,1,1,3.508-3.508,3.525,3.525,0,0,1-3.508,3.508Z" transform="translate(-140.778 -63.4)" />
      </g>
      <g transform="translate(6.924 23.912)">
        <path fill="none" stroke="#20d89b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px" d="M25.854,63.4a7.472,7.472,0,0,0-.83,14.9,6.393,6.393,0,0,0,.83.038,7.472,7.472,0,0,0,.83-14.9c-.264,0-.566-.038-.83-.038Zm.189,11.164a3.508,3.508,0,1,1,3.508-3.508,3.525,3.525,0,0,1-3.508,3.508Z" transform="translate(-18.378 -63.4)" />
      </g>
    </g>
  </svg>
);

export default Svg;

