import * as React from 'react';

const PictureUploadSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.242 85.242">
    <defs>
      <clipPath id="clip-path">
        <circle fill="#ccd1db" cx="42.621" cy="42.621" r="42.621" />
      </clipPath>
    </defs>
    <g transform="translate(-962 -690)">
      <g fill="none" stroke="#ccd1db" strokeWidth="3px" transform="translate(962 690)">
        <circle stroke="none" cx="42.621" cy="42.621" r="42.621" />
        <circle fill="none" cx="42.621" cy="42.621" r="41.121" />
      </g>
      <g clipPath="url(#clip-path)" transform="translate(962 690)">
        <path fill="none" stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" d="M85.969,90.467C83.663,87.916,68,81.244,68,81.244V75.13c5.15-3.994,9.217-14.544,9.217-24.231-.038-12.514-5.134-19.867-15.283-19.99S45.124,38.568,45.124,50.9c0,9.426,3.349,20.308,9.484,24.231v6.114s-15.128,6.891-17.183,8.727-2.5,49.6-2.5,49.6H89.386S88.269,93.033,85.969,90.467Z" transform="translate(-19.487 -13.313)" />
      </g>
    </g>
  </svg>


);

export default PictureUploadSvg;
