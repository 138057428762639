import gql from 'graphql-tag';

const DeliveryUpdate = gql`
    subscription DeliveryUpdate($token: String!) {
      deliveryUpdate(token: $token) {
        delivery {
          id
          driver_start_location {
            lat
            long
          }
          driver_start_timestamp
          pickup_location {
            lat
            long
          }
          pickup_timestamp
          delivery_location {
            lat
            long
          }
          delivery_timestamp
          point_system_type
          package_type
          name
          surname
          phone
          email
          pickup_street
          delivery_street
          state
          amount
          load_assistance_required
          waiver_insurance
          schedule_timestamp
          insurance
          recipient {
            name
            email
            phone
            business_name
          }
          pickup_stationary_time_seconds
          driver_package_count
          special_instructions
        }
        driver {
          coordinate {
            lat
            long
          }
          email
          name
          surname
          picture_url
          vehicle_description
          number_plate
          rating
          phone
        }
      }
    }
`;


export default DeliveryUpdate;
