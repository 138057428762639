import * as React from 'react';

const VehicleDocumentsSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169 169">
    <circle fill="#e3f0fc" cx="84.5" cy="84.5" r="84.5" />
    <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="2.9" height="2.9" transform="translate(56.9 141.8)" />
    <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="3.6" height="3.6" transform="translate(12.4 66.1)" />
    <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="1.5" height="1.5" transform="translate(80.4 13.2)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.5" height="1.5" transform="translate(19.4 55.2)" />
    <rect fill="none" strokeMiterlimit="10" stroke="#7791c1" strokeWidth="0.75px" width="1.3" height="1.3" transform="translate(88.9 10.4)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.5" height="1.5" transform="translate(106.4 145.2)" />
    <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4" height="4" transform="translate(155.616 105.474) rotate(-90)" />
    <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.7" height="4.7" transform="translate(42.098 138.938) rotate(-7.695)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.3" height="1.3" transform="translate(17.9 77.4)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.5" height="1.5" transform="translate(145.6 51)" />
    <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4" height="4" transform="translate(149.787 64.247) rotate(-90)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.3" height="1.3" transform="translate(65.9 155.4)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="3.1" height="3.1" transform="translate(152.4 114.6)" />
    <circle strokeMiterlimit="10" strokeWidth="2px" fill="#dce2ea" stroke="#4b5467" cx="25.6" cy="25.6" r="25.6" transform="translate(33.1 33.9)" />
    <circle strokeMiterlimit="10" strokeWidth="2px" fill="#dce2ea" stroke="#4b5467" cx="22.2" cy="22.2" r="22.2" transform="translate(36.7 37.6)" />
    <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#97ebcf" d="M81.861,59.3c-.1.3,7.593-36.9,13.134-37.2l54.9,6.8a133.562,133.562,0,0,0-12.724,37.6,179.489,179.489,0,0,0-1.642,34.9L80.116,94.6A114.807,114.807,0,0,1,81.861,59.3Z" transform="translate(-1.793)" />
    <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#97ebcf" d="M81.637,56.079c-.093.274,6.88-33.705,11.9-33.979l49.744,6.211a122.636,122.636,0,0,0-11.529,34.345,165.259,165.259,0,0,0-1.488,31.878L80.056,88.323A105.689,105.689,0,0,1,81.637,56.079Z" transform="translate(1 3)" />
    <g>
      <path fill="#e3f0fc" d="M87.7,68.6l-27.4-.4-19,13.2,1.6,5.8H91.5V68.7Z" />
      <path fill="#e3f0fc" d="M109.3,69.3l-10.4-.4-5.8.9.4,16.8,22.9-.1-3.1-13.8Z" />
      <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#f8e5bf" d="M24.5,88.8l-6.7,1.3-2.6,2.8-2.5,7v2.4h4.1L21,99l3.5-3.9,2.3-3Z" />
      <path fill="#fafbfc" d="M68.6,68.4,55.8,88.1l5.9-.8L74.6,67.5Z" />
      <path fill="#fafbfc" d="M62.3,68.8,51.4,86.4l2.5.6L64.8,69.4Z" />
      <path fill="#fafbfc" d="M107.9,69.7,95.1,89.5l5.8-.9,12.9-19.8Z" />
      <path fill="#fafbfc" d="M101.6,70.1,90.7,87.7l2.4.6L104,70.7Z" />
      <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#7791c1" d="M123.5,110.1c-1-4.2-2.2-11-3.1-14.1a35.642,35.642,0,0,1-.9-5.7c-.3-12.5-3.7-17.4-5.7-21.2a4.6,4.6,0,0,0-4.1-2.5H61.1a19.79,19.79,0,0,0-5.5,2.2L32.5,85.1a4.53,4.53,0,0,1-2.2.8,2.2,2.2,0,0,0-.8.1l-5.7,1.3L19,88.5a9.409,9.409,0,0,0-4.4,2.6h0A8.545,8.545,0,0,0,12.7,94L8.1,112c-.5,2.8,1.7,7.3,4.5,7.3h4.2a16.279,16.279,0,0,1-.3-3.1,14.6,14.6,0,0,1,29.2,0,15.526,15.526,0,0,1-.3,3.1H90.7a12.651,12.651,0,0,1-.3-2.8,14.2,14.2,0,0,1,28.4,0,12.412,12.412,0,0,1-.3,2.7h1.2C123.5,119.9,124.5,114.4,123.5,110.1ZM22,94.3c-4.4,5.9-8.3,7.3-8.3,7.3a31.467,31.467,0,0,1,1.5-6.2A12.57,12.57,0,0,1,18,92.1c1.7-1.2,6.5-1.9,6.5-1.9A16.64,16.64,0,0,1,22,94.3ZM89.7,82.6a3.159,3.159,0,0,1-3.2,3.2H46.1A2.655,2.655,0,0,1,44.5,81L58.8,70.7a4.764,4.764,0,0,1,2.9-.9h24a3.9,3.9,0,0,1,3.9,3.9v8.9Zm22.6,2.7h-16a2.934,2.934,0,0,1-2.8-3.1V73A2.588,2.588,0,0,1,96,70.3h12.5a3.276,3.276,0,0,1,3,2.3l3.1,9.2A2.612,2.612,0,0,1,112.3,85.3Z" />
      <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#777" d="M105.9,104.7a11.927,11.927,0,1,0,10.5,13.2A12.048,12.048,0,0,0,105.9,104.7Zm-1.1,17.7a5.6,5.6,0,1,1,5.6-5.6A5.591,5.591,0,0,1,104.8,122.4Z" />
      <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#777" d="M32.3,104.7a11.927,11.927,0,1,0,10.5,13.2A12.048,12.048,0,0,0,32.3,104.7Zm-1,17.7a5.6,5.6,0,1,1,5.6-5.6A5.591,5.591,0,0,1,31.3,122.4Z" />
      <circle strokeMiterlimit="10" strokeWidth="2px" fill="#a2a3a4" stroke="#65676d" cx="5.7" cy="5.7" r="5.7" transform="translate(25.3 111.2)" />
      <circle strokeMiterlimit="10" strokeWidth="2px" fill="#a2a3a4" stroke="#65676d" cx="5.7" cy="5.7" r="5.7" transform="translate(99 110.9)" />
      <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#7791c1" d="M16.4,120.8h2.4S15.9,104.7,31.4,104c0,0,13.8.8,11.6,16.7h0l2.6.1c2.5.1,2.4-1.6,2.4-4.1.1-6-4.2-16.7-16.6-16.7-15.3,0-16.9,14.4-16.6,19.3A1.445,1.445,0,0,0,16.4,120.8Z" />
      <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#7791c1" d="M89.9,120.8h2.4s-2.9-16.1,12.6-16.8c0,0,13.8.8,11.6,16.7h0l2.6.1c2.5.1,2.4-1.6,2.4-4.1.1-6-4.2-16.7-16.6-16.7-15.3,0-16.9,14.4-16.6,19.3A1.5,1.5,0,0,0,89.9,120.8Z" />
    </g>
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="8.59" y2="0.954" transform="translate(91.628 39.067)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="8.719" y2="0.521" transform="translate(85.5 64.5)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x1="9.652" y1="1.326" transform="translate(123.018 43.467)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="15.176" y2="1.814" transform="translate(89.815 43.935)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x1="12.743" y2="2.768" transform="translate(120.241 44.59) rotate(20)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="16.798" y2="1.814" transform="translate(88.192 49.661)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x1="8.681" y2="1.814" transform="translate(122.988 51.684) rotate(20)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="15.462" y2="1.814" transform="translate(86.952 54.529)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x2="18.548" y2="1.971" transform="translate(85.952 59.529)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x1="9.015" y2="1.814" transform="translate(121.689 56.392) rotate(20)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#4eb588" strokeLinecap="round" x1="13.716" y1="2.273" transform="translate(114.535 61.974)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#fafbfc" strokeLinecap="round" y1="8.4" x2="31.1" transform="translate(41.9 52.2)" />
    <line strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#fafbfc" strokeLinecap="round" y1="7.9" x2="29.2" transform="translate(45.4 59.1)" />
    <rect strokeMiterlimit="10" strokeWidth="2px" strokeLinecap="round" fill="#4b5467" stroke="#474747" strokeLinejoin="round" width="18" height="4.5" transform="matrix(0.968, -0.251, 0.251, 0.968, 45.92, 49.04)" />
    <g fill="none" strokeWidth="2px" stroke="#4b5467" strokeLinejoin="round" transform="translate(93.411 29.983) rotate(7)">
      <rect stroke="none" width="14.226" height="4.772" />
      <rect fill="none" x="1" y="1" width="12.226" height="2.772" />
    </g>
    <g fill="none" strokeWidth="2px" stroke="#4b5467" strokeLinejoin="round" transform="translate(123.114 34.166) rotate(7)">
      <rect stroke="none" width="13.226" height="4.772" />
      <rect fill="none" x="1" y="1" width="11.226" height="2.772" />
    </g>
  </svg>
);

export default VehicleDocumentsSvg;
