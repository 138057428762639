import * as React from 'react';

const ScooterSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.492 60">
    <g transform="translate(0.064 0.025)">
      <path fill="#20d89b" d="M67.228,104.1H87.8a.434.434,0,0,0,.338-.135l11.335-8.933c1.015,5.752,10.219-1.929,10.151-2.132L102.384,84a.531.531,0,0,0-.474-.3H81.168a.513.513,0,0,0-.474.711c.88,2,3.485,7.715,3.485,11.335,0,9.17-13.162,5.718-17.053,4.23C66.551,99.738,66.619,104.1,67.228,104.1Z" transform="translate(-44.194 -55.396)" />
      <path fill="#20d89b" d="M136.5,73.439c.2,2.2.778,1.624,5.211,1.455,4.4-.135,7.444.338,7.376-1.861s-3.147-3.925-7.545-3.756C137.109,69.413,136.026,68.4,136.5,73.439Z" transform="translate(-90.288 -45.846)" />
      <path fill="#20d89b" d="M105.891,74.076c.068,2.064,5.82,1.353,10.185,1.387,1.286,0,.88-.406.88-.88.034-.947.068-2.572.034-3.993-.034-1.286-1.692-.812-6.09-.677S105.824,71.843,105.891,74.076Z" transform="translate(-70.102 -46.144)" />
      <path fill="#20d89b" d="M152.247,108.506H124.975a1.286,1.286,0,0,1-1.218-.812c-1.489-3.417-2.91-16.749,5.245-17.29,13.94-.914,21.418,11.132,24.4,16.072A1.339,1.339,0,0,1,152.247,108.506Z" transform="translate(-81.166 -59.799)" />
      <path fill="#20d89b" d="M1.311,111.988l14.042-.338a5.239,5.239,0,0,1,2.3,1.827l2.2,2.707a1.307,1.307,0,0,0,1.218.778h5.684a17.618,17.618,0,0,0-4.263-9.948,13.6,13.6,0,0,0-3.857-2.978c-3.451-1.861-14.448-1.15-18.576,7.038C-.347,111.988.3,112.021,1.311,111.988Z" transform="translate(0 -68.288)" />
      <path fill="#20d89b" d="M47.789,28.325A4.19,4.19,0,0,1,46.3,25.754l.44-4.433.034-.068c.237-.677,1.15-1.015,1.827-1.117a1.7,1.7,0,0,1,1.624.44c.744.778.3,1.184.3,1.353l-1.015,6.158-.44.744h-.169A1.97,1.97,0,0,1,47.789,28.325Zm1.117-.88Zm.3-5.989h0a2.954,2.954,0,0,0-1.523.237l-.406,4.06A5.928,5.928,0,0,0,48.4,27.412l1.083-5.651C49.413,21.693,49.311,21.558,49.21,21.456Z" transform="translate(-30.676 -13.319)" />
      <path fill="#20d89b" d="M54.9,22h2.538c1.793.135,4.027,4.6.372,6.4h0s-4.365,1.353-4.636.88c-.3-.508.575-7.275.575-7.275Z" transform="translate(-35.183 -14.573)" />
      <path fill="#20d89b" d="M181.164,89.694l3.654,4.5c.406.711.135,2.2-.677,2.2,0,0-.914.135-1.117-.1l-4.906-6.023C177.475,89.559,180.352,89.017,181.164,89.694Z" transform="translate(-117.831 -59.157)" />
      <rect fill="#20d89b" width="7.275" height="1.015" transform="translate(23.04 10.126) rotate(-76.945)" />
      <path fill="#20d89b" d="M74.339,2.69V5.228a11.447,11.447,0,0,0,.711,3.011c.44,1.049-1.59-.711-2.064-2.808S73.7.186,74.136-.017C74.542-.186,74.372,2.217,74.339,2.69Z" transform="translate(-48.26 0)" />
      <ellipse fill="#20d89b" cx="1.658" cy="1.658" rx="1.658" ry="1.658" transform="translate(23.507 9.187)" />
      <path fill="#20d89b" d="M27.975,60.674l3.485.474,8.459,1.117c.947-.169-1.15-3.282-1.523-4.162-.778-1.962-2.369-11.031-2.03-12.655.135-.711,3.417-9.643,3.451-10.489.068-1.929.44-6.158-.271-6.564-.372-.2-8.73,10.726-15.734,24.362A5.469,5.469,0,0,0,27.975,60.674Zm.947-9.982,2.47-5.989,5.278-8.391Z" transform="translate(-15.397 -18.802)" />
      <g transform="translate(44.588 38.455)">
        <path fill="#20d89b" d="M142.154,113.7c-.372,0-.778.034-1.15.068a10.251,10.251,0,0,0,1.15,20.437c.372,0,.778-.034,1.15-.068a10.251,10.251,0,0,0-1.15-20.437Zm-.237,15.294a4.8,4.8,0,1,1,4.8-4.8,4.823,4.823,0,0,1-4.8,4.8Z" transform="translate(-131.902 -113.7)" />
      </g>
      <g transform="translate(2.496 39.47)">
        <path fill="#20d89b" d="M17.754,116.7c-.372,0-.778.034-1.15.068a10.251,10.251,0,0,0,1.15,20.437c.372,0,.778-.034,1.15-.068a10.251,10.251,0,0,0-1.15-20.437Zm-.271,15.328a4.8,4.8,0,1,1,4.8-4.8,4.823,4.823,0,0,1-4.8,4.8Z" transform="translate(-7.502 -116.7)" />
      </g>
    </g>
  </svg>


);

export default ScooterSvg;
