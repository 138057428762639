import * as React from 'react';

const VanSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.989 46.247">
    <g transform="translate(0 0)">
      <g transform="translate(69.012 28.662)">
        <path fill="#ccd1db" d="M213.556,89.237a8.788,8.788,0,1,0-7.747,9.719A8.764,8.764,0,0,0,213.556,89.237Zm-8.733,4.895a4.12,4.12,0,1,1,4.12-4.12A4.1,4.1,0,0,1,204.823,94.132Z" transform="translate(-196.03 -81.43)" />
      </g>
      <g transform="translate(11.015 28.451)">
        <path fill="#ccd1db" d="M48.856,88.637a8.788,8.788,0,1,0-7.747,9.719A8.765,8.765,0,0,0,48.856,88.637Zm-8.733,4.895a4.12,4.12,0,1,1,4.12-4.12A4.1,4.1,0,0,1,40.123,93.532Z" transform="translate(-31.33 -80.83)" />
      </g>
      <path fill="#ccd1db" d="M99.44,36.574a3.444,3.444,0,0,0,.317-1.444l.282-7.148c0-4.859-.387-5.212-.739-10.635L97.08,7.594c-.6-2.43-1.655-5.529-4.014-6.022C87.432.375,76.34.163,73.487.058c-2.218-.106-12.4.176-22.009.387-4.155.106-7.853.211-9.825.246a11.69,11.69,0,0,0-4.3,1.3L20.526,13.474S7.5,17.207,6.053,18.3C4.574,19.355.454,23.686.1,29-.18,33.335.56,40.448,4.715,40.518l5.986.035a10.056,10.056,0,0,1-.458-4.613,9.837,9.837,0,0,1,19.579,1.373,9.252,9.252,0,0,1-.634,3.451l15.74.211,24.051.246a9.643,9.643,0,0,1-.669-4.613,9.535,9.535,0,0,1,19.015,1.021,9.829,9.829,0,0,1-.669,3.557l2.676-.07h4.014a3.373,3.373,0,0,0,1.725-.493c.916-.563,2.395-1.514,2.852-1.866A5.988,5.988,0,0,0,99.44,36.574ZM10.208,20.834c-1.232,1.373-6.62,2.289-6.831,1.373a28.5,28.5,0,0,1,2.042-2.676,2.865,2.865,0,0,1,1.373-.951L7,18.51c.845-.282,3.521-.493,3.7.387A1.95,1.95,0,0,1,10.208,20.834ZM49.929,13.4c0,1.2-.634,2.148-1.725,2.148H26.548c-1.162,0-2.676-1.409-1.725-2.183L37.675,3.861A6.32,6.32,0,0,1,40.14,2.417h6.409c1.268,0,3.381-.035,3.381,1.338V13.4Z" transform="translate(-0.049 -0.035)" />
    </g>
  </svg>


);

export default VanSvg;
