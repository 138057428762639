import * as React from 'react';
import { compose, withApollo } from 'react-apollo';

/* Styling */
import './privacy.page.css';

/* Components */
import Header from '../../../components/web/header/header.component';
import Footer from '../../../components/web/footer/footer.component';
import Ellipse from '../../../components/web/ellipse/ellipse.component';

/* SVG's */

/* GraphQL */


class PerfectWebPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header clear={false} />
        <div className="web-body">
          <div className="header-image-container">
            <Ellipse>
              <div className="header-image" >
                <h1>Privacy Policy</h1>
              </div>
            </Ellipse>
          </div>
          <div className="privacy-container">
            <h1 className="text-bold">Privacy Policy</h1>
            <p className="text">Boxel (PTY) Ltd ("we", "our" or "Boxel") is committed to protecting the privacy of all visitors to our website Boxel.co.za (and all pages attaching to and including this domain name) and all visitors who access our website or services through any mobile application (together, "Website"). Please read the following privacy policy which explains how we use and protect your information.</p>
            <p className="text">By visiting and/or ordering services on this Website, you agree and where required you consent to the collection, use and transfer of your information as set out in this policy.</p>
            <h2 className="text-bold">1. INFORMATION THAT WE COLLECT FROM YOU</h2>
            <p className="text">When you visit the Website or make a Boxel order through the Website, you may be asked to provide information about yourself including your name, contact details and payment information such as credit or debit card information. We may also collect information about your usage of the Website and information about you from the messages you post to the Website and the e-mails or letters you send to us.</p>
            <p className="text">By accessing Boxel information and/or services using mobile digital routes such as (but not limited to) mobile, tablet or other devices/technology including mobile applications, then you should expect that Boxel's data collection and usage as set out in this privacy policy will apply in that context too. We may collect technical information from your mobile device or your use of our services through a mobile device, for example, location data and certain characteristics of, and performance data about your device, carrier/operating system including device and connection type, IP address, mobile payment methods, interaction with other retail technology such as use of NFC Tags, QR Codes or use of mobile vouchers. Unless you have elected to remain anonymous through your device and/or platform settings, this information may be collected and use by us automatically if you use the service through your mobile device(s) via any Boxel mobile application, through your mobile's browser or otherwise.</p>
            <p className="text">We may use technology to provide certain Services that rely upon device-based location information using GPS, Wi-Fi hotspot and telecommunications network locations. If you have enabled your device which you use to access the Boxel Website we may collect, use, transmit, process and maintain your location data, including but not limited to the geographic location of your device and information for the purposes of supplying services that you have requested to us, and to improve the operation of our Website and the type of services that we deliver using it generally. We will only use location data from which you can be identified in connection with the provision of services to you. We will delete or render anonymous any location data that we receive from you after we have provided the services that you request on our Website from us unless that data is needed to comply with applicable laws or to deal with a dispute between us. At any time you can withdraw consent to our using and processing location information about you by not using the location-based features on the Website and/or turning off location settings (as applicable) on your device. We do not guarantee the availability, accuracy, completeness, reliability, or timeliness of location data or any other data delivered by your device or through your use of the Website and it is your responsibility to verify any location information that we may provide to you as part of your order or use of the Website.</p>
            <h2 className="text-bold">2. USE OF YOUR INFORMATION</h2>
            <p className="text">Your information will enable us to provide you with access to the relevant parts of the Website and to supply the services you have requested. It will also enable us to bill you and to contact you where necessary concerning our services. We will also use and analyse the information we collect so that we can administer, support, improve and develop our business, for any other purpose whether statistical or analytical and to help us prevent fraud. Where appropriate, now and in the future you may have the ability to express your preferences around the use of your data as set out in this privacy policy and this may be exercised though your chosen method of using our services, for example mobile, mobile applications or any representation of our Website.</p>
            <p className="text">We may use your information to contact you for your views on our services and to notify you occasionally about important changes or developments to the Website or our services.</p>
            <p className="text">Where you have indicated accordingly, you agree that we may use your information to let you know about our other products and services that may be of interest to you including services that may be the subject of direct marketing and we may contact you to do so by post, telephone, mobile messaging (e.g. SMS, MMS etc.) as well as by e-mail.</p>
            <p className="text">Where you have indicated accordingly, you agree that we may also share information with third parties (including those in the food, drink, leisure, marketing and advertising sectors) to use your information in order to let you know about goods and services which may be of interest to you (by post, telephone, mobile messaging (e.g. SMS, MMS etc.) and/or e-mail) and to help us analyse the information we collect so that we can administer, support, improve and develop our business and services to you.</p>
            <p className="text">If you do not want us to use your data in this way or change your mind about being contacted in the future, please let us know by using the contact details set out below and/or amending your profile accordingly.</p>
            <p className="text">Please note that by submitting comments and feedback regarding the Website and the services, you consent to us to use such comments and feedback on the Website and in any marketing or advertising materials. We will only identify you for this purpose by your first name and the city in which you reside.</p>
            <h2 className="text-bold">3. DISCLOSURE OF YOUR INFORMATION</h2>
            <p className="text">The information you provide to us will be transferred to and stored on our servers.</p>
            <p className="text">Third parties process information such as credit card payments and provide support services related to payments for us. In addition, we may need to provide your information to any Drivers. By submitting your personal data, you agree to this transfer, storing or processing. Boxel will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.</p>
            <p className="text">If you have consented we may allow carefully selected third parties, including marketing and advertising companies, our affiliates and associates, to contact you occasionally about services that may be of interest to you. They may contact you by telephone, SMS as well as by e-mail. If you change your mind about being contacted by these companies in the future, please let us know by using the contact details set out below and/or by amending your profile accordingly.</p>
            <p className="text">If our business enters into a joint venture with, purchases or is sold to or merged with another business entity, your information may be disclosed or transferred to the target company, our new business partners or owners or their advisors.</p>
            <p className="text">We may use the information that you provide to us if we are under a duty to disclose or share your information in order to comply with (and/or where we believe we are under a duty to comply with) any legal obligation; or in order to enforce our Website Terms and any other agreement; or to protect the rights of Boxel. This includes exchanging information with other companies and other organisations for the purposes of fraud protection and prevention.</p>
            <h2 className="text-bold">4. SECURITY AND DATA RETENTION</h2>
            <p className="text">We take steps to protect your information from unauthorised access and against unlawful processing, accidental loss, destruction and damage. We will keep your information for a reasonable period or as long as the law requires.</p>
            <p className="text">Where you have chosen a password which allows you to access certain parts of the Website, you are responsible for keeping this password confidential. We advise you not to share your password with anyone.</p>
            <p className="text">Unfortunately, the transmission of information via the internet is not completely secure. Although we will take steps to protect your information, we cannot guarantee the security of your data transmitted to the Website; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
            <h2 className="text-bold">5. ACCESSING AND UPDATING</h2>
            <p className="text">You have the right to see the information we hold about you ("Access Request") and to ask us to make any changes to ensure that it is accurate and up to date. If you wish to do this, please contact us using the contact details set out below. In the event that you make an Access Request, we reserve the right to charge a fee of R150.00 to meet our costs in providing you with details of the information we hold about you.</p>
            <h2 className="text-bold">6. CHANGES TO OUR PRIVACY POLICY</h2>
            <p className="text">Any changes to our Privacy Policy will be posted to the Website and, where appropriate, through e-mail notification.</p>
            <h2 className="text-bold">7. CONTACT</h2>
            <p className="text">All comments, queries and requests relating to our use of your information are welcomed and should be addressed to henri@nk.co.za.</p>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}


export default compose(withApollo)(PerfectWebPage);

