import * as React from 'react';
import { compose, withApollo } from 'react-apollo';

/* Styling */
import './delivery.page.css';

/* Components */
import Map from '../../components/delivery/map/map.component';
import Header from '../../components/delivery/header/header.component';
import Footer from '../../components/delivery/footer/footer.component';
import Modal from '../../components/delivery/modal/modal.component';
import Card from '../../components/delivery/card/card.component';
import Rating from '../../components/delivery/rating/rating.component';

/* SVG's */
import BoxelSvg from '../../components/svg/boxel.svg';

/* GraphQl */
import DriverUpdate from '../../graphql/subscriptions/driverUpdate.subscription';
import MovingDots from './MovingDots';

/* Images */
const avatar = require('../../assets/images/profile-user.jpg');

class DeliveryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: null,
      delivery: null,
      driver: null,
      timeRemaining: '0 min',
    };
  }

  componentDidMount() {
    console.log('ID:', this.props.id);
    this.subscribeToDelivery(this.props.id);
  }

  componentWillUnmount() {
    try {
      this.deliverySubscription.unsubscribe();
    } catch (err) {
      console.log(err);
    }
  }

  subscribeToDelivery = (token) => {
    console.log('driver scanning')
    const options = {
      query: DriverUpdate,
      variables: {
        token,
      },
    };

    this.deliverySubscription = this.props.client.subscribe(options).subscribe((response) => {
      console.log('Test',response)
      if (!response.errors && response.data.deliveryUpdate) {
        console.log('Found')
        console.log(response.data);
        const { delivery, driver } = response.data.deliveryUpdate;
        this.setState({ delivery, driver });
        if (delivery.state === 'DELIVERED') {
          this.setState({ modal: this.getPackageDeliveredModal() });
        }
      }
    }, err => console.log(err));
  }

  /* Modals */
  closeModal = () => {
    this.setState({ modal: null });
  }

  getPackageDeliveredModal = () => {
    const submit = () => {
      this.closeModal();
    };

    const user = this.getDriverDetails();
    // !TODO: Find out what the hell should happen here?
    return (
      <Card className="modal-card">
        <img src={user.image} alt="Profile" className="picture" />
        <p className="text-bold profileNameText">{user.name}</p>
        <p className="text profileLicenseText">{user.numberPlate}</p>
        <p className="text-bold packageDeliveredText">Package Delivered</p>
        <hr />
        <p className="text boxelOpaqueText">Rate your delivery</p>
        <Rating
          onChange={(rating) => {
            this.setState({ rating });
          }}
        />
        <button
          className="button-rating text-bold"
          onClick={submit}
        >
          SUBMIT RATING
        </button>
      </Card>
    );
  }

  getAdvertiseModal = () => {
    const submit = () => {
      this.closeModal();
    };

    return (
      <Card className="modal-card">
        <BoxelSvg className="boxelSvg" />
        <p className="text heading">Boxel</p>
        <div className="smallHr" />
        <p className="text boxelText">Deliveries made simple</p>
        <hr />
        <p className="text boxelOpaqueText">Sign-up now and get 50% off</p>
        <p className="text boxelOpaqueText">your first delivery</p>

        <div className="actions">
          <button
            className="button text-bold"
            onClick={this.closeModal}
          >
            NOT NOW
          </button>
          <button
            className="button text-bold"
            style={{ backgroundColor: '#20D89B' }}
            onClick={submit}
          >
            GET BOXEL
          </button>
        </div>
      </Card>
    );
  }

  getDriverDetails() {
    const { driver } = this.state;
    return {
      name: driver ? `${driver.name} ${driver.surname}` : 'Waiting ...',
      rating: driver ? driver.rating : '0',
      cellphone: driver ? driver.phone : '27345678901',
      email: driver ? driver.email : 'help@boxel.co.za',
      image: driver ? driver.picture_url || avatar : avatar,
      numberPlate: driver ? driver.number_plate : 'ABC 123 GP',
    };
  }

  render() {
    const { driver, delivery } = this.state;
    let deliveryRoute = null;

    const driverDetails = this.getDriverDetails();

    if (driver && delivery) {
      deliveryRoute = {
        origin: {
          lat: driver.coordinate.lat,
          lng: driver.coordinate.long,
        },
        destination: {
          lat: delivery.delivery_location.lat,
          lng: delivery.delivery_location.long,
        },
      };
    }

    return (
      <div className="deliveryContainer">
        <div className="delivery">
          { (!driver || !delivery) && <div className="delivery-loader">Waiting for driver <MovingDots /></div> }
          <div style={{ position: 'relative', height: '58vh', top: '19vh' }}>
            <Map
              route={deliveryRoute}
              onRouteChange={(googleRoute) => {
                const [route] = googleRoute.routes;
                const [leg] = route.legs;
                this.setState({ timeRemaining: leg.duration.text });
              }}
            />
          </div>
          <Header />
          <Footer
            time={this.state.timeRemaining}
            onPhonePress={() => {
              // eslint-disable-next-line no-undef
              const link = document.createElement('a');
              link.href = `tel:+${driverDetails.cellphone}`;
              link.click();
            }}
            onMessagePress={() => {
              // eslint-disable-next-line no-undef
              const link = document.createElement('a');
              link.href = `mailto:${driverDetails.email}`;
              link.click();
            }}
            name={driverDetails.name}
            rating={driverDetails.rating}
            cellphone={driverDetails.cellphone}
            email={driverDetails.email}
            image={driverDetails.image}
          />
          { this.state.modal &&
          <Modal
            closeModal={this.closeModal}
          >
              {this.state.modal}
          </Modal> }
        </div>
      </div>
    );
  }
}

DeliveryPage.defaultProps = {
  id: '',
};

export default compose(withApollo)(DeliveryPage);
