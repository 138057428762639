import * as React from 'react';

const VehicleRegistrationSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.768 90.942">
    <g transform="translate(-0.279 -1.382)">
      <path fill="none" stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" d="M67.5,92.943H10.264A5.158,5.158,0,0,1,5,87.929V10.013A5.158,5.158,0,0,1,10.264,5H67.5a5.158,5.158,0,0,1,5.263,5.013V87.929A5.158,5.158,0,0,1,67.5,92.943Z" transform="translate(-3.221 -2.119)" />
      <path fill="none" stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" d="M60.2,83.9H9.648A4.589,4.589,0,0,1,5,79.405V9.5A4.589,4.589,0,0,1,9.648,5H60.2a4.589,4.589,0,0,1,4.648,4.5V79.405A4.589,4.589,0,0,1,60.2,83.9Z" transform="translate(0.754 2.512)" />
      <ellipse fill="none" stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" cx="5.057" cy="5.4" rx="5.057" ry="5.4" transform="translate(30.97 12.242)" />
      <rect fill="none" stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" strokeLinejoin="round" width="14.448" height="3.686" transform="translate(11.391 15.486)" />
      <rect fill="none" stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" strokeLinejoin="round" width="14.448" height="3.686" transform="translate(47.023 14.965)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="16.856" transform="translate(10.462 25.68)" />
      <rect stroke="#ccd1db" strokeWidth="3px" strokeMiterlimit="10" strokeLinejoin="round" fill="#ccd1db" width="18.06" height="3.686" transform="translate(10.502 62.413)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="16.856" transform="translate(43.773 32.537)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="16.856" transform="translate(10.462 32.537)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="16.856" transform="translate(43.773 39.394)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="10.435" transform="translate(10.462 39.394)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="8.428" transform="translate(11.351 72.455)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="12.441" transform="translate(11.351 78.732)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="10.435" transform="translate(50.194 46.252)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="16.856" transform="translate(43.773 25.68)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="12.441" transform="translate(10.061 46.252)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="12.441" transform="translate(48.187 59.967)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="12.441" transform="translate(10.061 53.109)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="12.441" transform="translate(48.187 66.824)" />
      <line fill="none" stroke="#ccd1db" strokeWidth="3px" strokeLinecap="round" x1="12.441" transform="translate(48.187 53.109)" />
    </g>
  </svg>
);

export default VehicleRegistrationSvg;
