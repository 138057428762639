import * as React from 'react';

const BankingSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 172">
    <circle fill="#e3f0fc" cx="86" cy="86" r="86" />
    <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="2.951" height="2.951" transform="translate(57.91 144.317)" />
    <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="3.664" height="3.664" transform="translate(12.62 67.273)" />
    <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="1.527" height="1.527" transform="translate(81.827 13.434)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.527" height="1.527" transform="translate(19.744 56.18)" />
    <rect fill="none" strokeMiterlimit="10" stroke="#7791c1" strokeWidth="0.75px" width="1.323" height="1.323" transform="translate(90.478 10.585)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.527" height="1.527" transform="translate(108.289 147.778)" />
    <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.071" height="4.071" transform="translate(158.378 107.346) rotate(-90)" />
    <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.783" height="4.783" transform="translate(42.845 141.405) rotate(-7.695)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.323" height="1.323" transform="translate(20.253 72.667)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="1.527" height="1.527" transform="translate(148.185 51.905)" />
    <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="4.071" height="4.071" transform="translate(152.446 65.387) rotate(-90)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="1.323" height="1.323" transform="translate(67.07 158.159)" />
    <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="3.155" height="3.155" transform="translate(155.105 116.634)" />
    <path strokeMiterlimit="10" strokeWidth="2px" fill="#5e6a82" stroke="#4c5467" d="M65.1,137.242,53.8,97.957a5.719,5.719,0,0,1,3.969-7.124L123.418,72a5.719,5.719,0,0,1,7.124,3.969l11.3,39.285a5.719,5.719,0,0,1-3.969,7.124L72.225,141.211A5.8,5.8,0,0,1,65.1,137.242Z" transform="translate(0.951 1.274)" />
    <path fill="#353c47" d="M131.437,81.6,134.8,93.508l-76.84,20.253L54.8,101.243Z" transform="translate(0.973 1.449)" />
    <path fill="#353c47" opacity="0.61" d="M131.479,101.842l.509,1.832a3.342,3.342,0,0,1-2.341,4.173l-9.567,2.341a3.337,3.337,0,0,1-3.969-2.341l-.509-1.934a3.255,3.255,0,0,1,2.443-4.071L127.51,99.5A3.337,3.337,0,0,1,131.479,101.842Z" transform="translate(2.05 1.765)" />
    <path fill="#353c47" opacity="0.61" d="M112.8,106.844l.509,1.832a3.255,3.255,0,0,1-2.443,4.071l-16.589,4.173a3.246,3.246,0,0,1-3.867-2.443l-.509-1.934a3.329,3.329,0,0,1,2.443-4.071l16.488-4.071A3.383,3.383,0,0,1,112.8,106.844Z" transform="translate(1.594 1.852)" />
    <path strokeMiterlimit="10" strokeWidth="2px" fill="#4eb588" stroke="#4b5467" d="M76.029,116.868l-33.586-23.2a5.736,5.736,0,0,1-1.425-8.04L79.9,29.443a5.736,5.736,0,0,1,8.04-1.425l33.586,23.2a5.736,5.736,0,0,1,1.425,8.04l-38.878,56.18A5.941,5.941,0,0,1,76.029,116.868Z" transform="translate(0.709 0.479)" />
    <path fill="#353c47" d="M92.1,31.9l10.178,7.022L56.881,103.346,46.5,95.509Z" transform="translate(0.825 0.566)" />
    <path fill="#353c47" opacity="0.56" d="M105.193,46.609l1.628,1.12a3.3,3.3,0,0,1,.814,4.682l-5.9,7.837a3.356,3.356,0,0,1-4.58.712l-1.628-1.12a3.294,3.294,0,0,1-.712-4.682l5.9-7.837A3.439,3.439,0,0,1,105.193,46.609Z" transform="translate(1.671 0.818)" />
    <path fill="#353c47" opacity="0.56" d="M93.867,62.511l1.628,1.12a3.307,3.307,0,0,1,.712,4.682L86.03,81.95a3.352,3.352,0,0,1-4.58.611l-1.527-1.12a3.206,3.206,0,0,1-.611-4.682L89.491,63.122A3.093,3.093,0,0,1,93.867,62.511Z" transform="translate(1.395 1.098)" />
  </svg>


);

export default BankingSvg;
