import * as React from 'react';

/* Styling */
import './wizard.component.css';

/* Images */

class WizardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
  }

  getSteps = () => this.props.headings.map((heading, index) => {
    const items = this.props.steps.filter(step => step.heading === heading.id);
    return (
      <div key={`step-heading${heading.id}`} className="step-container" style={{ opacity: heading.expanded || heading.complete ? 1.0 : 0.4 }}>
        <span className="indicator text" style={{ backgroundColor: heading.complete ? '#20D89B' : '#506290' }}>{index + 1}</span>
        <span className="heading text-bold" style={{ color: heading.complete ? '#20D89B' : '#506290' }}>{heading.value}</span>
        {heading.expanded &&
          <div className="items">
            {items.map(item => (
              <div key={`step-heading${heading.id}-item-${item.value}`} className="item text" style={{ opacity: item.active || item.complete ? 1.0 : 0.4, color: item.complete ? '#95A7D3' : '#506290' }}>
                {item.value}
              </div>
          ))}
          </div>}
      </div>
    );
  })

  getContent = () => {
    const index = this.props.steps.findIndex(step => step.active);
    if (index < this.props.children.length) {
      return this.props.children[index];
    }
    return <div />;
  }

  render() {
    return (
      <div className="wizard">
        <div className="steps">
          {this.getSteps()}
        </div>
        <div className="content">{this.getContent()}</div>
      </div>
    );
  }
}

WizardComponent.defaultProps = {
  headings: [],
  steps: [],
  children: [],
};

export default WizardComponent;
