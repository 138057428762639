import * as React from 'react';

const ConvenientSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111 99.708">
    <g transform="translate(0 0.015)">
      <circle fill="#e3f0fc" cx="49.232" cy="49.232" r="49.232" transform="translate(0 1.23)" />
      <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="1.69" height="1.69" transform="translate(30.413 81.516)" />
      <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="2.097" height="2.097" transform="translate(55.757 10.61)" />
      <rect fill="none" stroke="#55b790" strokeMiterlimit="10" strokeWidth="2px" width="0.874" height="0.874" transform="translate(77.897 23.545)" />
      <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="0.874" height="0.874" transform="translate(5.069 46.85)" />
      <path strokeMiterlimit="10" strokeWidth="2px" fill="#5e6a82" stroke="#4c5467" d="M36.382,50.81,33,39.1a3.274,3.274,0,0,1,2.272-4.078L56.483,28.9a3.274,3.274,0,0,1,4.078,2.272L63.94,42.886a3.274,3.274,0,0,1-2.272,4.078L40.461,53.082A3.274,3.274,0,0,1,36.382,50.81Z" transform="translate(-13.718 -11.593)" />
      <rect fill="none" strokeMiterlimit="10" stroke="#7791c1" strokeWidth="0.75px" width="0.757" height="0.757" transform="translate(52.553 6.707)" />
      <rect fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#7791c1" width="0.874" height="0.874" transform="translate(40.026 81.225)" />
      <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="2.331" height="2.331" transform="translate(2.823 53.938) rotate(-90)" />
      <path fill="#353c47" d="M65.891,35l.99,3.379L45.616,44.73,44.8,41.118Z" transform="translate(-18.698 -14.194)" />
      <path fill="#353c47" opacity="0.61" d="M76.981,46.5l.175.7a1.135,1.135,0,0,1-.874,1.457l-3.437.816a1.149,1.149,0,0,1-1.4-.816l-.175-.7a1.174,1.174,0,0,1,.874-1.457l3.438-.816A1.231,1.231,0,0,1,76.981,46.5Z" transform="translate(-29.73 -18.644)" />
      <path fill="#353c47" opacity="0.61" d="M63.386,49.66l.175.7a1.174,1.174,0,0,1-.874,1.457l-5.943,1.457a1.186,1.186,0,0,1-1.4-.874l-.175-.7a1.2,1.2,0,0,1,.874-1.457l5.943-1.457A1.2,1.2,0,0,1,63.386,49.66Z" transform="translate(-23.01 -19.939)" />
      <rect fill="none" strokeMiterlimit="10" stroke="#fafbfc" strokeWidth="3px" width="1.165" height="1.165" transform="translate(42.441 78.408) rotate(-90)" />
      <path strokeMiterlimit="10" strokeWidth="2px" fill="#4eb588" stroke="#4b5467" d="M36.105,32.109,26.084,25.176a3.284,3.284,0,0,1-.816-4.6L37.795,2.4a3.346,3.346,0,0,1,4.6-.816L52.419,8.513a3.284,3.284,0,0,1,.816,4.6L40.708,31.294A3.336,3.336,0,0,1,36.105,32.109Z" transform="translate(-10.295 0)" />
      <path fill="#353c47" d="M47.968,3.2l2.913,2.039L38.413,23.592,35.5,21.32Z" transform="translate(-14.817 -0.921)" />
      <path fill="#353c47" opacity="0.56" d="M61.844,12.424l.583.408a1.17,1.17,0,0,1,.291,1.69l-2.156,2.8a1.14,1.14,0,0,1-1.631.233l-.583-.408a1.277,1.277,0,0,1-.291-1.69l2.1-2.8A1.208,1.208,0,0,1,61.844,12.424Z" transform="translate(-24.148 -4.669)" />
      <path fill="#353c47" opacity="0.56" d="M53.526,22.324l.583.408a1.208,1.208,0,0,1,.233,1.69l-3.671,4.894a1.192,1.192,0,0,1-1.631.233l-.583-.408a1.208,1.208,0,0,1-.233-1.69l3.671-4.894A1.146,1.146,0,0,1,53.526,22.324Z" transform="translate(-20.025 -8.801)" />
      <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#7791c1" d="M36.11,70.932H20.321A3.357,3.357,0,0,1,17,67.611V31.721A3.357,3.357,0,0,1,20.321,28.4H36.11a3.357,3.357,0,0,1,3.321,3.321V67.669A3.346,3.346,0,0,1,36.11,70.932Z" transform="translate(-7.095 -11.439)" />
      <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#828282" d="M123.828,93.194h-23.6A1.629,1.629,0,0,1,98.6,91.563V49.031a1.629,1.629,0,0,1,1.631-1.631h23.6a1.629,1.629,0,0,1,1.631,1.631V91.563A1.629,1.629,0,0,1,123.828,93.194Z" transform="translate(-41.153 -19.369)" />
      <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#dce2ea" d="M123.183,94.864H104.131a1.629,1.629,0,0,1-1.631-1.631v-40.2a1.628,1.628,0,0,1,1.631-1.631h19.052a1.629,1.629,0,0,1,1.631,1.631V93.174A1.607,1.607,0,0,1,123.183,94.864Z" transform="translate(-42.781 -21.039)" />
      <path strokeMiterlimit="10" stroke="#4c5467" fill="#b7e1f5" d="M36.078,69.015H21.221A3.357,3.357,0,0,1,17.9,65.694V36.621A3.357,3.357,0,0,1,21.221,33.3H36.136a3.357,3.357,0,0,1,3.321,3.321V65.752A3.4,3.4,0,0,1,36.078,69.015Z" transform="translate(-7.471 -13.484)" />
      <g transform="translate(36.006 45.335)">
        <g>
          <line fill="none" strokeWidth="3px" stroke="#4b5467" strokeLinecap="round" strokeLinejoin="round" />
          <line fill="none" strokeWidth="3px" stroke="#4b5467" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0 5.153" x2="1.515" transform="translate(2.971)" />
          <line fill="none" strokeWidth="3px" stroke="#4b5467" strokeLinecap="round" strokeLinejoin="round" transform="translate(6.001)" />
          <line fill="none" strokeWidth="3px" stroke="#4b5467" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0 4.333" y2="3.787" transform="translate(6.001 2.505)" />
          <line fill="none" strokeWidth="3px" stroke="#4b5467" strokeLinecap="round" strokeLinejoin="round" transform="translate(6.001 7.574)" />
          <line fill="none" strokeWidth="3px" stroke="#4b5467" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0 5.472" x2="1.573" transform="translate(9.206 7.574)" />
          <line fill="none" strokeWidth="3px" stroke="#4b5467" strokeLinecap="round" strokeLinejoin="round" transform="translate(12.352 7.574)" />
        </g>
      </g>
      <path strokeMiterlimit="10" strokeWidth="2px" fill="#4eb588" stroke="#4b5467" d="M82,83.3v7.865l7.166-3.9Z" transform="translate(-34.225 -34.353)" />
      <circle fill="#4b5467" cx="1.107" cy="1.107" r="1.107" transform="translate(20.042 56.23)" />
      <line fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#fafbfc" strokeLinecap="round" y2="29.19" transform="translate(13.284 23.02)" />
      <circle strokeMiterlimit="10" stroke="#4b5467" fill="#e39caa" cx="1.107" cy="1.107" r="1.107" transform="translate(61.992 50.753)" />
      <path strokeMiterlimit="10" stroke="#4b5467" fill="#fafbfc" d="M129.509,72.007h-4.778a1.629,1.629,0,0,1-1.631-1.631V58.431a1.629,1.629,0,0,1,1.631-1.631h4.778a1.628,1.628,0,0,1,1.631,1.631V70.375A1.592,1.592,0,0,1,129.509,72.007Z" transform="translate(-51.379 -23.292)" />
      <path strokeMiterlimit="10" stroke="#4b5467" fill="#fafbfc" d="M112.009,72.007h-4.778a1.629,1.629,0,0,1-1.631-1.631V58.431a1.629,1.629,0,0,1,1.631-1.631h4.778a1.629,1.629,0,0,1,1.631,1.631V70.375A1.667,1.667,0,0,1,112.009,72.007Z" transform="translate(-44.075 -23.292)" />
      <path strokeMiterlimit="10" stroke="#4b5467" fill="#fafbfc" d="M112.009,108.907h-4.778a1.629,1.629,0,0,1-1.631-1.631V95.331a1.629,1.629,0,0,1,1.631-1.631h4.778a1.629,1.629,0,0,1,1.631,1.631v11.944A1.667,1.667,0,0,1,112.009,108.907Z" transform="translate(-44.075 -38.694)" />
      <path strokeMiterlimit="10" stroke="#4b5467" fill="#fafbfc" d="M129.509,108.907h-4.778a1.629,1.629,0,0,1-1.631-1.631V95.331a1.629,1.629,0,0,1,1.631-1.631h4.778a1.628,1.628,0,0,1,1.631,1.631v11.944A1.592,1.592,0,0,1,129.509,108.907Z" transform="translate(-51.379 -38.694)" />
      <path strokeMiterlimit="10" strokeWidth="2px" fill="#4eb588" stroke="#4b5467" d="M148.984,121.445a5.6,5.6,0,0,1-5.593-5.593,5.813,5.813,0,0,1,1.165-3.437,5.467,5.467,0,0,1-2.156-4.428,5.6,5.6,0,0,1,5.593-5.593,2.39,2.39,0,0,1,.641.058v-.058a5.593,5.593,0,0,1,11.186,0,3.847,3.847,0,0,1-.058.816,5.746,5.746,0,0,1,1.4-.175,5.576,5.576,0,0,1,5.127,7.807,5.582,5.582,0,0,1-2.447,10.6Z" transform="translate(-59.434 -39.987)" />
      <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#dce2ea" d="M124.981,124.9H97.131a1.629,1.629,0,0,0-1.631,1.631v2.1a1.628,1.628,0,0,0,1.631,1.631h27.85a1.628,1.628,0,0,0,1.631-1.631v-2.1A1.592,1.592,0,0,0,124.981,124.9Z" transform="translate(-39.859 -51.716)" />
      <path strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#dce2ea" d="M126.316,134.2H92.931a1.629,1.629,0,0,0-1.631,1.631v1.69a1.629,1.629,0,0,0,1.631,1.631h33.385a1.629,1.629,0,0,0,1.631-1.631v-1.69A1.667,1.667,0,0,0,126.316,134.2Z" transform="translate(-38.106 -55.597)" />
      <line fill="none" strokeMiterlimit="10" stroke="#4b5467" strokeLinecap="round" x2="12.993" transform="translate(66.361 51.802)" />
      <rect fill="none" strokeMiterlimit="10" strokeWidth="0.75px" stroke="#fff" width="0.757" height="0.757" transform="translate(74.693 19.524)" />
      <rect strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" fill="#828282" width="12.177" height="12.177" transform="translate(65.546 61.066)" />
      <path strokeMiterlimit="10" stroke="#4b5467" fill="#b2dee9" opacity="0.55" d="M120.6,103.3l.117,4.544.583-1.049.932,1.224.816-1.282.466.816V103.3" transform="translate(-50.335 -42.7)" />
      <path strokeMiterlimit="10" fill="#4eb588" stroke="#4b5467" d="M118.371,114.775l.757,1.748-1.107,1.515-1.864-.175-.757-1.748,1.107-1.515Z" transform="translate(-48.165 -47.417)" />
      <path strokeMiterlimit="10" stroke="#4b5467" fill="#e5e4e4" d="M128.746,119.587l-3.146-.058V115.8h3.146Z" transform="translate(-52.422 -47.918)" />
      <g transform="translate(74.168 68.582)">
        <line fill="#e5e4e4" x2="1.107" transform="translate(0 0.35)" />
        <path fill="#4b5467" d="M127.3,117.291c.117-.175.175-.233.291-.233.058.058.117.117.117.175.058,0,.117,0,.117-.058.117-.117.175-.175.291-.175s.175.117.291.291v.058a.687.687,0,0,1-.291.291c-.117,0-.175-.058-.291-.175a.18.18,0,0,0-.117-.058c-.058,0-.117.117-.117.175-.117.058-.175,0-.291-.291Z" transform="translate(-127.3 -117)" />
      </g>
      <path fill="none" strokeMiterlimit="10" strokeWidth="2px" stroke="#4b5467" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0 4" d="M33.985,71v5.885H28.1v5.3" transform="translate(-11.728 -29.219)" />
      <path strokeMiterlimit="10" stroke="#4b5467" fill="#7791c1" d="M39.274,59.212a3.791,3.791,0,0,0-7.574,0,3.559,3.559,0,0,0,.466,1.748v.058l.233.35,2.272,3.671a1.049,1.049,0,0,0,1.282,0l2.564-3.845h0A2.919,2.919,0,0,0,39.274,59.212Zm-3.787,1.806a1.733,1.733,0,0,1-1.748-1.631,1.658,1.658,0,0,1,1.748-1.631,1.733,1.733,0,0,1,1.748,1.631A1.694,1.694,0,0,1,35.487,61.018Z" transform="translate(-13.231 -22.792)" />
    </g>
  </svg>


);

export default ConvenientSvg;
