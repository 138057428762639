/**
 * @file: src/components/otp/otp.component.js
 * @description OTP Component
 * @copyright: Verge Technologies 2018
 * @author Fabio Loreggian fabio@verge.co.za
 */

/* Packages */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Components */
import OtpInput from './otpInput.component';

/* Styling */
import './otp.style.css';


class OTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: new Array(this.props.number),
    };

    this.inputs = [];
  }

  componentDidMount() {
    // console.log(this.props);
  }

  clearOtp = () => {
    const otp = ['', '', '', ''];
    this.setState({ otp });
  }

  updateText = (text, index) => {
    if (text) {
      const otpCode = this.state.otp;

      otpCode[index] = text;

      // this.props.handleChange && this.props.handleChange(otpCode.join(''));
      this.setState({ otp: otpCode });
      this.props.onChange(otpCode.join(''));

      if (index >= 0 && index < this.props.number - 1) {
        this.focusInput(index + 1);
      }
    }
  }

  handleBackspace = (event, index) => {
    if (event.keyCode === 8) {
      const otpCode = this.state.otp;
      otpCode[index] = '';
      this.setState({ otp: otpCode });
      this.props.onChange(otpCode.join(''));
      if (index > 0 && index < this.props.number) {
        this.focusInput(index - 1);
      }
    }
  }

  focusInput = (index) => {
    this.inputs[index].input.focus();
  }

  renderOTP = () => {
    const inputArray = [];
    for (let index = 0; index < this.props.number; index += 1) {
      inputArray.push(<OtpInput
        handleBackspace={event => this.handleBackspace(event, index)}
        key={index}
        ref={(input) => { this.inputs[index] = input; }}
        updateText={text => this.updateText(text, index)}
        value={this.state.otp[index]}
      />);
    }
    return inputArray;
  }

  render() {
    return (
      <div
        className="otpContainer"
      >
        {this.renderOTP()}
      </div>
    );
  }
}

/* Prop Types */
OTP.propTypes = {
  number: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

/* Default prop type values */
OTP.defaultProps = {
  number: 4,
};

export default OTP;
