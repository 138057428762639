import * as React from 'react';
import { compose, withApollo } from 'react-apollo';

/* Styling */
import './terms.page.css';

/* Components */
import Header from '../../../components/web/header/header.component';
import Footer from '../../../components/web/footer/footer.component';
import Ellipse from '../../../components/web/ellipse/ellipse.component';

/* SVG's */

/* GraphQL */

/* Asssets */


class TermsWebPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header clear={false} />
        <div className="web-body">
          <div className="header-image-container">
            <Ellipse>
              <div className="header-image" >
                <h1>Terms of Service</h1>
              </div>
            </Ellipse>
          </div>
          <div className="terms-container">
            <h1 className="text-bold">Terms and Conditions</h1>
            <p className="text">All persons who register as a driver on the Boxel platform (website, application, computer and software) agree to the following terms and conditions:</p>
            <h2 className="text-bold">1. THIRD PARTY SERVICE PROVIDERS</h2>
            <p className="text">1.1 Every person registered as a driver on the Boxel platform, accepts and agree that he/she are an independent third party service provider (herein after referred to as &ldquo;driver&rdquo;) and no driver shall become an employee, contractor, sub contractor or agent of Boxel. </p>
            <p className="text">1.2 A driver shall contract with a client directly through the use of the Boxel Platform.</p>
            <p className="text">1.3 The Boxel platform only arranges and schedule deliveries and administer price and payments between the drivers and clients. </p>

            <h2 className="text-bold">2. PAYMENTS AND PRICING</h2>
            <p className="text">2.1 The driver shall receive payment for his deliveries on a, weekly basis.</p>
            <p className="text">2.2 Boxel has the exclusive right and may at its own discretion and without prior notice to a driver change pricing for any delivery as it seems fit.</p>
            <p className="text">2.3 Boxel has the right to deduct any percentage, which it deems fit in the circumstances, at its own discretion, from any amount due to a driver.</p>

            <h2 className="text-bold">3. CANCELLATION</h2>
            <p className="text">Boxel may at any time summarily cancel this agreement or this agreement may be cancelled by agreement between Boxel and the driver. </p>

            <h2 className="text-bold">4. INDEMNITY AND WARRANTIES</h2>
            <p className="text">4.1 The driver exempt, indemnifies and hold Boxel harmless:</p>
            <p className="text" style={{ marginLeft: '15px' }}>4.1.1 against any claim whatsoever for any loss or destruction of any goods transported by the driver;</p>
            <p className="text" style={{ marginLeft: '15px' }}>4.1.2 against any other claim which may arise out of the use of the Boxel platform.</p>
            <p className="text">4.2.1 Boxel may at its own discretion refund any client any amount which it deems fit in the circumstances for any cancellation, loss, damage or any delivery by a driver.</p>
            <p className="text">4.2.1 Boxel may at its own discretion deduct such an amount equal to the amount refunded to the client from the amount due to the driver.</p>

            <h2 className="text-bold">5. INSURANCE </h2>
            <p className="text">5.1 Each driver is responsible for his own insurance including but not limited to personal, vehicle and goods in transit insurance where applicable.</p>
            <p className="text">5.2 The driver shall familiarize him/herself with all the terms and conditions of the Boxel's insurance platform and agree and undertake to fully adhere to all the stipulated terms and conditions.</p>

            <h2 className="text-bold">6. MAINTENANCE</h2>
            <p className="text">6.1 The driver shall keep the vehicle (bicycle, scooter, motor vehicle) in good working order and maintain it to respectable standard.</p>
            <p className="text">6.2 The driver shall be responsible for the maintenance and operating of the vehicle including but not limited to:</p>
            <p className="text"> a{')'} fuel</p>
            <p className="text"> b{')'} oils</p>
            <p className="text"> c{')'} lubricants</p>
            <p className="text"> d{')'} repairs</p>
            <p className="text"> e{')'} tires exc. </p>
            <p className="text">6.3 Boxel may at any time request an inspection of the vehicle. </p>

            <h2 className="text-bold">7. GENERAL</h2>
            <p className="text">7.1 The driver shall at all times comply with all laws applicable to road and safety and regulations.</p>
            <p className="text">7.2 Only the allocated driver may do a delivery and the driver shall not allow any other person to do deliveries on his/her behalf.</p>
            <p className="text">7.3 The driver shall exercise all reasonable care and keep safe the goods/parcel in its possession to be delivered.</p>
            <p className="text">7.4 The driver shall ensure that he is always neatly clothed and where applicable wear the necessary safety clothing and gear as required by law. </p>
            <p className="text">7.5 The driver shall report any loss and/or damage of a parcel immediately to Boxel and the client. </p>

            <h2 className="text-bold">11. NON DISCLOSURE </h2>
            <p className="text">The driver shall not use or disclose to anyone any confidential information regarding Boxel. Such confidential information shall include all non-public information the driver acquired as a result of his or her positions with Boxel which might be of any value to a competitor of Boxel, or which might cause any economic loss or substantial embarrassment to Boxel or its customers, if used or disclosed. Examples of such confidential information include, without limitation, non-public information about Boxel customers, suppliers, distributors and potential acquisition targets; its business operations and structure; its product lines, formulas and pricing; its processes; its research and know-how; its financial data; and its plans and strategies.</p>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}


export default compose(withApollo)(TermsWebPage);

