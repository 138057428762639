import * as React from 'react';
import { compose, withApollo } from 'react-apollo';

/* Styling */
import './howitworks.web.page.css';

/* Components */
import Header from '../../../components/web/header/header.component';
import Footer from '../../../components/web/footer/footer.component';
import Ellipse from '../../../components/web/ellipse/ellipse.component';

/* SVG's */

/* GraphQL */

/* Asssets */
const boxelWorksOne = require('../../../assets/images/boxel-works-1.png');
const boxelWorksTwo = require('../../../assets/images/boxel-works-2.png');
const boxelWorksThree = require('../../../assets/images/boxel-works-3.png');
const boxelWorksFour = require('../../../assets/images/boxel-works-4.png');
const boxelWorksFive = require('../../../assets/images/boxel-works-5.png');

class HowItWorksWebPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header clear={false} />
        <div className="web-body">
          <div className="header-image-container">
            <Ellipse>
              <div className="header-image" >
                <h1>How Boxel Works</h1>
              </div>
            </Ellipse>
          </div>
          <div className="content-background">
            <Ellipse>
              <div className="phone-background" />
            </Ellipse>
          </div>
          <div className="content-container">
            <div className="phone-container">
              <img src={boxelWorksOne} alt="" />
              <div>
                <p className="text-bold phone-step">Step 1</p>
                <p className="text-bold phone-step-heading">The client opens the app and specifies route locations</p>
                <p className="text phone-step-text">A delivery location is entered; followed by the location where the driver will pick up the item to be delivered.</p>
              </div>
            </div>

            <div className="phone-container">
              <img src={boxelWorksTwo} alt="" />
              <div>
                <p className="text-bold phone-step">Step 2</p>
                <p className="text-bold phone-step-heading">The client gives the details of the delivery</p>
                <p className="text phone-step-text">The client provides the details of the recipient, the size of the items, the delivery pick-up time and  may optionally add insurance and special instructions for the delivery.</p>
              </div>
            </div>

            <div className="phone-container">
              <img src={boxelWorksThree} alt="" />
              <div>
                <p className="text-bold phone-step">Step 3</p>
                <p className="text-bold phone-step-heading">A driver is allocated</p>
                <p className="text phone-step-text">An opportunity will be sent out to all the drivers in the area who match the requirements. The  first driver to accept will be given the delivery opportunity.</p>
              </div>
            </div>

            <div className="phone-container">
              <img src={boxelWorksFour} alt="" />
              <div>
                <p className="text-bold phone-step">Step 4</p>
                <p className="text-bold phone-step-heading">The driver fetches and delivers</p>
                <p className="text phone-step-text">The driver fetches the delivery item from the pickup location. The item is then taken to its delivery location. During the process the client and recipient can track the delivery’s progress in real time.</p>
              </div>
            </div>

            <div className="phone-container">
              <img src={boxelWorksFive} alt="" />
              <div>
                <p className="text-bold phone-step">Step 5</p>
                <p className="text-bold phone-step-heading">The client rates the delivery</p>
                <p className="text phone-step-text">Once the delivery is completed both the client who ordered the delivery and the recipient of the delivery will have an opportunity to rate their experience of the delivery.</p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}


export default compose(withApollo)(HowItWorksWebPage);

